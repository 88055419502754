import { useMemo, useRef } from 'react';
import { Show, ShowFirstMatching } from '@components/Show';
import { WysiwygEditor } from '@ContractBuilder/components/Wysiwyg';
import { blockContentClasses } from '@ContractBuilder/modules/block-content/constants/classes';
import { brandingDatapoints } from '@features/branding-edit/branding-datapoints';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import { getBrandingDatapointControls } from '@WysiwygEditor/components/Controls/utils/datapoint-controls';
import { editorBaseClasses } from '@WysiwygEditor/components/Editor/hooks/use-editor';
import clsx from 'clsx';

import { BrandingEditorKey, useBrandingStore } from '../../store/branding.store';
import { getBrandingDatapointInserterRenderer } from '../BrandingDatapointInserter';
import { ContractContentPlaceholder } from '../ContractContentPlaceholder';

import { brandingDimClasses, editorClasses, previewClasses } from './classes';
import { EditorBadge } from './EditorBadge';
import { InteractiveBorder } from './InteractiveBorder';

export const BrandingDetailsEditor = () => {
  const { branding, form, handleChange, setFormEditorKey, hasOperationsDisabled } = useBrandingStore(
    ({ branding, form, handleChange, setFormEditorKey, hasOperationsDisabled }) => ({
      branding,
      form,
      handleChange,
      setFormEditorKey,
      hasOperationsDisabled,
    }),
  );

  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const setCurrentEditor = (editorKey: BrandingEditorKey) => () => {
    if (hasOperationsDisabled) return;
    setFormEditorKey(editorKey);
  };

  const { isEditingHeader, isEditingFooter } = useMemo(() => {
    return {
      isEditingHeader: form.editorKey === BrandingEditorKey.Header,
      isEditingFooter: form.editorKey === BrandingEditorKey.Footer,
    };
  }, [form.editorKey]);

  return (
    <div className="group relative h-fit">
      <EditorBadge
        editable={!hasOperationsDisabled}
        isEditing={isEditingHeader}
        onClick={setCurrentEditor(BrandingEditorKey.Header)}
        variant="header"
      />
      <EditorBadge
        editable={!hasOperationsDisabled}
        isEditing={isEditingFooter}
        onClick={setCurrentEditor(BrandingEditorKey.Footer)}
        variant="footer"
      />
      <div
        id="page"
        className={'branding page-A4-width relative flex flex-col justify-between border bg-white shadow-md'}
        style={{
          height: '297mm',
          overflow: 'hidden',
        }}
      >
        <div
          id="header"
          ref={headerRef}
          className={editorClasses({
            dim: isEditingFooter,
            isEditing: isEditingHeader,
            type: 'header',
          })}
          onClick={setCurrentEditor(BrandingEditorKey.Header)}
          aria-hidden
        >
          <InteractiveBorder isEditing={isEditingHeader} variant="header" editable={!hasOperationsDisabled} />
          <ShowFirstMatching>
            <Show when={!isEditingHeader}>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(branding?.header_content ?? '') }}
                className={blockContentClasses({
                  type: 'generic',
                  pdf: true,
                  disableMentions: true,
                  className: [previewClasses],
                })}
              />
            </Show>
            <Show when={isEditingHeader}>
              <WysiwygEditor
                shouldShowControls={false}
                blockType={'generic'}
                bodyClassName="!py-0 !m-0"
                className={'h-full'}
                name={BrandingEditorKey.Header}
                content={branding?.header_content ?? ''}
                currentContentId={BrandingEditorKey.Header}
                onUpdate={handleChange('header_content')}
                enableImageInsert
                getDatapointInserterRenderer={getBrandingDatapointInserterRenderer}
                datapointInserterItems={brandingDatapoints}
                getDatapointControls={getBrandingDatapointControls}
              />
            </Show>
          </ShowFirstMatching>
        </div>
        <div
          className={clsx(
            'branding-content z-0 grow select-none overflow-hidden p-2',
            editorBaseClasses,
            brandingDimClasses,
          )}
        >
          <ContractContentPlaceholder />
        </div>
        <div
          ref={footerRef}
          id="footer"
          className={editorClasses({
            dim: isEditingHeader,
            isEditing: isEditingFooter,
            type: 'footer',
          })}
          onClick={setCurrentEditor(BrandingEditorKey.Footer)}
          aria-hidden
        >
          <InteractiveBorder isEditing={isEditingFooter} variant="footer" editable={!hasOperationsDisabled} />

          <ShowFirstMatching>
            <Show when={!isEditingFooter}>
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(branding?.footer_content ?? '') }}
                className={blockContentClasses({
                  type: 'generic',
                  pdf: true,
                  disableMentions: true,
                  className: [previewClasses],
                })}
              />
            </Show>
            <Show when={isEditingFooter}>
              <WysiwygEditor
                shouldShowControls={false}
                bodyClassName="!py-0 !m-0"
                blockType={'generic'}
                className={'h-full'}
                name={BrandingEditorKey.Footer}
                content={branding?.footer_content ?? ''}
                currentContentId={BrandingEditorKey.Footer}
                onUpdate={handleChange('footer_content')}
                enableImageInsert
                getDatapointInserterRenderer={getBrandingDatapointInserterRenderer}
                datapointInserterItems={brandingDatapoints}
                getDatapointControls={getBrandingDatapointControls}
              />
            </Show>
          </ShowFirstMatching>
        </div>
      </div>
    </div>
  );
};
