import type { FC } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { groupByParent } from '@helpers/groupBlocksByParent';
import { useBlocksLibrary } from '@hooks/useBlocksLibrary';
import { getBlocksTableColumns } from '@pages/User/helpers/columns/getBlocksTableColumns';
import { fuzzyFilter } from '@pages/User/helpers/filters/fuzzyFilter';
import type { DynamoDBLibraryBlock } from '@root/@types/database';
import { useDeepCompareMemo } from '@src/hooks';
import type { ColumnFiltersState, ExpandedState, SortingState } from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useFilteredBlocks } from '../hooks/use-filtered-blocks';
import { getPaginationRowModel } from '../utils/getPaginationRowModel';
import { BlockLibraryListSuccess, BlocksLibraryLoading } from '../views';

interface BlockLibraryCreatorListControllerProps {
  onInsertBlock: (blockLibraryId: string, block: DynamoDBLibraryBlock | null) => Promise<void>;
}

const initialColumnFilters = [{ id: 'deleted_at', value: false }];
const initialPagination = {
  pageIndex: 0,
  pageSize: 20,
};

export const BlockLibraryCreatorListController: FC<BlockLibraryCreatorListControllerProps> = ({ onInsertBlock }) => {
  const [isGroupByParent, setIsGroupByParent] = useState(false);
  const { data: blocks = [], isFetching } = useBlocksLibrary();
  const blocksGroupedByParents = useMemo(() => groupByParent(blocks), [blocks]);
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const [pagination, setPagination] = useState(initialPagination);
  const [sorting, setSorting] = useState<SortingState>([{ id: 'created_at', desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFilters);
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = getBlocksTableColumns();
  const data = useDeepCompareMemo(
    () => (isGroupByParent ? blocksGroupedByParents : blocks),
    [blocks, blocksGroupedByParents, isGroupByParent],
  );

  const infiniteLoaderRef = useRef<HTMLDivElement>(null);

  const table = useReactTable({
    columns,
    data,
    enableColumnFilters: true,
    enableExpanding: isGroupByParent,
    enableMultiSort: false,
    enableSortingRemoval: false,
    filterFromLeafRows: true,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getColumnCanGlobalFilter: (column) => !['created_at', 'updated_at'].includes(column.id),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    globalFilterFn: 'fuzzy',
    initialState: {
      columnVisibility: {
        id: false,
      },
    },
    maxLeafRowFilterDepth: 1,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    paginateExpandedRows: false,
    sortDescFirst: true,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      expanded,
    },
  });

  const filteredBlocks = useFilteredBlocks(
    table.getRowModel().rows.map(({ original }) => original),
    globalFilter,
  );

  const resetPagination = () => {
    setPagination(initialPagination);
  };

  const onSearchApply = (searchInput: string) => {
    resetPagination();
    setGlobalFilter(searchInput);
  };

  const onSearchReset = () => {
    resetPagination();
    setGlobalFilter('');
  };

  const handleLoadMoreItems = () => {
    setPagination((current) => ({ ...current, pageIndex: current.pageIndex + 1 }));
  };

  useEffect(() => {
    resetPagination();
  }, [columnFilters]);

  useEffect(() => {
    if (infiniteLoaderRef.current) {
      infiniteLoaderRef.current.scrollTop = 0;
    }

    resetPagination();
  }, [isGroupByParent]);

  if (isFetching) {
    return <BlocksLibraryLoading />;
  }

  const loadedBlocksCount = filteredBlocks.length ?? 0;

  return (
    <BlockLibraryListSuccess
      blocks={filteredBlocks}
      count={loadedBlocksCount}
      filters={columnFilters}
      listRef={infiniteLoaderRef}
      onInsert={onInsertBlock}
      onSearch={onSearchApply}
      onSearchReset={onSearchReset}
      searchText={globalFilter}
      total={table.getRowCount()}
      onLoadMoreItems={handleLoadMoreItems}
      isFetching={isFetching}
      table={table}
      isGroupByParent={isGroupByParent}
      setIsGroupByParent={setIsGroupByParent}
    />
  );
};
