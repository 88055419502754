import IconMdi from '@components/IconMdi';
import { mdiInformationSlabCircleOutline } from '@mdi/js';

import { Banner } from '../components';

export const NoChangesBanner = () => {
  return (
    <Banner>
      <IconMdi path={mdiInformationSlabCircleOutline} className="text-gray-700" size={0.8} />
      <p>Any changes you make to the contract will be listed here</p>
    </Banner>
  );
};
