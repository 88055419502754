import React from 'react';
import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import { CloseIconButton } from '@components/atoms/ClearInputButton';
import { useSchemaStore } from '@ContractBuilder/store';
import { currencyFormatter } from '@helpers/formatters';
import { isNonNullish } from '@root/helpers';
import { get, isString } from 'lodash-es';

import type { UIOnChangeFn } from '../../../@types/types';
import type { InputFieldProps } from '../InputField';
import { InputField } from '../InputField';
import InputReadOnly from '../InputReadOnly';
import InputSelect from '../InputSelect';
import { inputClasses } from '../shared-classes';

type CurrencyValue = { amount?: number; currency?: string };

export interface InputCurrencyProps extends InputFieldProps {
  defaultValue?: CurrencyValue;
  isReadOnly?: boolean;
  onChange: UIOnChangeFn<CurrencyValue>;
  value?: Partial<CurrencyValue>;
  maximum?: number;
  minimum?: number;
  currencies?: string[];
  /** By default `react-number-format` will call the change handler when props.value gets changed programmatically **/
  shouldTriggerChangeHandlerOnPropsChange?: boolean;
}

const hasLeadingZeros = (value?: string) => {
  if (!value || !isString(value)) {
    return false;
  }

  return Boolean(value.charAt(0) === '0' && value.charAt(1) && value.charAt(1) !== '.');
};

const InputCurrency: React.FC<InputCurrencyProps> = ({
  cdrId,
  cdrName,
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isDisabled,
  isReadOnly,
  isRequired,
  labelIcon,
  labelText,
  mrcId,
  name = 'currency',
  hideErrors,
  onChange,
  value,
  maximum = Number.MAX_SAFE_INTEGER,
  minimum,
  currencies,
  isIncomplete,
  shouldTriggerChangeHandlerOnPropsChange = false,
}) => {
  const { schema } = useSchemaStore(({ schema }) => ({ schema }));
  const defaultCurrenciesList = get(schema, 'definitions.currency.enum', []);

  const amount = get(value, 'amount');
  const currency = get(value, 'currency');

  const handleAmountChange = ({ floatValue: amount }: NumberFormatValues, sourceInfo: SourceInfo) => {
    if (!shouldTriggerChangeHandlerOnPropsChange && sourceInfo.source === 'prop') {
      return;
    }

    if (onChange) {
      if (currency === undefined) {
        onChange({ amount }, name);
      } else {
        onChange({ amount, currency }, name);
      }
    }
  };

  const handleCurrencyChange = (currency: string | undefined) => {
    if (onChange) {
      if (amount === undefined) {
        onChange({ currency }, name);
      } else {
        onChange({ amount: amount, currency }, name);
      }
    }
  };

  const handleClearCurrencyInput = () => {
    return onChange(undefined, name);
  };

  const currenciesList = (currencies ?? defaultCurrenciesList).map((currency) => ({
    value: currency as string,
    name: currency as string,
  }));

  return (
    <InputField
      cdrId={cdrId}
      cdrName={cdrName}
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelIcon={labelIcon}
      labelText={labelText}
      mrcId={mrcId}
      name={name}
      hideErrors={hideErrors}
      isIncomplete={isIncomplete}
    >
      {!isReadOnly && (
        <div
          className={inputClasses({
            className: [className, 'flex', 'items-center'],
            isReadOnly,
            isDisabled,
            size: 'custom',
            isIncomplete,
          })}
        >
          <InputSelect
            unstyled
            id={name}
            options={currenciesList}
            onChange={handleCurrencyChange}
            value={currency}
            placeholder="--"
            className="w-20"
            placeholderClassName="min-w-[1rem]"
            isClearable={false}
            menuOuterClassName="min-w-fit"
          />
          <NumericFormat
            allowLeadingZeros={false}
            allowNegative={false}
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="w-full outline-none"
            decimalScale={2}
            defaultValue={defaultValue && defaultValue.amount}
            fixedDecimalScale={!Number.isInteger(Number(amount))}
            id={name}
            maxLength={22}
            name={name}
            onValueChange={handleAmountChange}
            placeholder="0.00"
            thousandSeparator
            value={amount ?? ''}
            valueIsNumericString
            isAllowed={({ floatValue, value }: NumberFormatValues) => {
              if (value === '' || (!maximum && !minimum)) {
                return true;
              }

              if (hasLeadingZeros(value)) {
                return false;
              }

              if (maximum) {
                return isNonNullish(floatValue) ? floatValue <= maximum : false;
              }

              if (minimum) {
                return isNonNullish(floatValue) ? floatValue >= minimum : false;
              }

              return false;
            }}
          />
          {<CloseIconButton onClick={handleClearCurrencyInput} />}
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly
          key={JSON.stringify(value)}
          id={id || name}
          name={name}
          errors={errors}
          value={currencyFormatter(value) || undefined}
        />
      )}
    </InputField>
  );
};

export default InputCurrency;
