import type { FC, HTMLAttributes } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiPlusCircleOutline } from '@mdi/js';

import { indentationClasses } from '../DatapointInserter/classes';

import { itemClasses } from './style/classes';

interface RenderItemInnerProps extends HTMLAttributes<HTMLButtonElement> {
  highlightedId?: string;
  isConditional: boolean;
  label: string;
  level?: number;
  value: string;
  disableConditionals?: boolean;
  schema?: any;
  showPlusSign?: boolean;
  replacementClasses?: string;
}

export const RenderItemInner: FC<RenderItemInnerProps> = ({
  value,
  label,
  level,
  highlightedId,
  isConditional,
  disableConditionals = false,
  showPlusSign = false,
  schema: _schema,
  replacementClasses,
  ...props
}) => (
  <button
    title={value}
    {...props}
    className={itemClasses({
      isConditional: !disableConditionals && isConditional,
      isHighlighted: highlightedId === value,
      className: replacementClasses ?? [indentationClasses({ level: level as any }), props.className],
    })}
  >
    <p className={'text-nowrap overflow-hidden truncate'}>{label}</p>
    {showPlusSign && (
      <div className="ml-auto flex gap-1">
        <IconMdi
          data-testid="insert-section-repeater-button"
          path={mdiPlusCircleOutline}
          className={'text-nowrap text-info-500 hover:text-primary-500'}
        />
      </div>
    )}
  </button>
);
