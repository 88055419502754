import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { BrandingType } from '@root/@types/types';
import axios from 'axios';

import { normaliseBranding } from '../utils';

export const fetchBrandings = async (): Promise<BrandingType[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: '/brandings',
  });

  const { data } = await axios(networkPayload);

  return data.map(normaliseBranding<BrandingType>);
};
