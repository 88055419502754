import type { UISelectionType } from '@root/@types/types';
import type { ColumnHelper } from '@tanstack/react-table';
import { isString } from 'lodash-es';

export const brandingAccessor = <T extends { branding?: string; branding_id?: string }>(
  columnHelper: ColumnHelper<T>,
  brandingFieldName: 'branding' | 'branding_id',
  brandingOptions: UISelectionType[] | string[],
) =>
  columnHelper.accessor((entity) => entity[brandingFieldName], {
    id: brandingFieldName,
    header: 'Branding',
    filterFn: (row, _, filteredOptions) => {
      const selectedBranding = brandingOptions
        .map((item) => (isString(item) ? item : item.value))
        .find((item) => {
          const value = row.original?.[brandingFieldName];
          return item === value;
        });

      return Boolean(selectedBranding && filteredOptions.includes(selectedBranding));
    },
    cell: (cellContext) => {
      const value = cellContext.getValue();
      const found = brandingOptions.find((item) => (isString(item) ? item === value : item.value === value));
      return isString(found) ? found : found?.name;
    },
    meta: {
      filter: {
        placeholder: 'All brands',
        optionsMapping: brandingOptions.map((item) =>
          isString(item) ? { label: item, value: item } : { label: item.name, value: item.value as string },
        ),
      },
    },
  });
