import type { FC } from 'react';
import { DrawerSection } from '@ContractBuilder/modules/block-configuration-panel/components/DrawerSection';

import { BrandingImagesListing } from './BrandingImagesListing';

interface BrandingDetailsDrawerProps {}

export const BrandingDetailsDrawer: FC<BrandingDetailsDrawerProps> = () => {
  return (
    <div className="flex h-full w-1/3 max-w-drawer flex-col border-l bg-white px-3 py-6">
      <DrawerSection title={'Image library'} className="mt-20">
        <BrandingImagesListing />
      </DrawerSection>
    </div>
  );
};
