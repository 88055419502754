import { Fragment, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useUserStore } from '@Auth/store';
import Icon from '@components/Icon';
import { Show } from '@components/Show';
import type { SvgIconName } from '@components/Svg';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useDeepCompareMemo } from '@hooks/use-deep-compare-memo';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import { ModalContextProvider, ModalSwitch } from '@hooks/useModalProvider';
import { MenuSectionUser } from '@User/Menu/MenuSection.User';
import SupportButton from '@User/Menu/SupportButton';
import clsx from 'clsx';

import type { TenantName, UIComponentBase } from '../../@types/types';
import { tenantConfig } from '../../config/tenants';
import Nav from '../routes';

import { layoutChildrenClasses } from './classes';
import { LogoCollaboration } from './LogoCollaboration';
import { createMenuForUser } from './menu';

const client: TenantName = import.meta.env?.VITE_CLIENT ?? process?.env?.CLIENT;

const MenuLayout = ({ children }: UIComponentBase) => {
  const getFeatureFlagState = useFeatureFlags();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useUserStore(({ user }) => ({ user }));
  const location = useLocation();
  const currentHref = location.pathname;

  const headerLogoProps = {
    height: tenantConfig.headerLogoHeight,
    width: tenantConfig.headerLogoWidth,
  };

  const navigation = useDeepCompareMemo(
    () => createMenuForUser(user, getFeatureFlagState),
    [user, getFeatureFlagState],
  );

  const isActive = (path: Nav | string) => currentHref.startsWith(path);

  const isBrandingDetails = useMemo(() => {
    if (getFeatureFlagState('Branding')) {
      return isActive(Nav.BrandingDetails.replace(':id', ''));
    }
    return false;
    // eslint-disable-next-line -- observing the currentHref is enough
  }, [currentHref]);

  return (
    <ModalContextProvider>
      <div className="admin h-full">
        <ModalSwitch />
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-info-600/75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-info-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                    {client === 'ct-ppl' ? (
                      <div className="mx-auto my-3 px-4">
                        <LogoCollaboration logoColor="white" size="sm" />
                      </div>
                    ) : (
                      <div className="flex shrink-0 items-center px-4">
                        <img
                          className={`w-auto ${tenantConfig.headerLogoClasses}`}
                          src={tenantConfig.headerLogo}
                          alt="Logo"
                        />
                      </div>
                    )}
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.path}
                          to={item.path}
                          className={clsx(
                            isActive(item.path)
                              ? 'bg-info-900 text-white'
                              : 'text-info-300 hover:bg-info-700 hover:text-white',
                            'group flex items-center rounded-md px-2 py-2 text-base font-medium capitalize',
                          )}
                        >
                          <>
                            <div
                              className={clsx(
                                isActive(item.path) ? 'text-info-300' : 'text-info-400 group-hover:text-info-300',
                                'mr-4 h-6 w-6 shrink-0',
                              )}
                            >
                              <Icon name={item.icon as SvgIconName} />
                            </div>
                            {item.name}
                          </>
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <Show when={!!user}>
                    <MenuSectionUser user={user!} displayAvatar isCollapsible />
                  </Show>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex min-h-0 flex-1 flex-col bg-info-800">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              {client === 'ct-ppl' ? (
                <div className="mx-auto my-3 px-4">
                  <LogoCollaboration logoColor="white" size="sm" />
                </div>
              ) : (
                <div className="mx-auto flex shrink-0 items-center">
                  <img
                    className={`w-auto ${tenantConfig.headerLogoClasses}`}
                    src={tenantConfig.headerLogo}
                    alt="Logo"
                    {...headerLogoProps}
                  />
                </div>
              )}
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className={clsx(
                      isActive(item.path)
                        ? 'bg-info-900 text-white'
                        : 'text-info-300 hover:bg-info-700 hover:text-white',
                      'group flex items-center rounded-md px-2 py-2 text-sm font-medium',
                    )}
                  >
                    <>
                      <div
                        className={clsx(
                          isActive(item.path) ? 'text-info-300' : 'text-info-400 group-hover:text-info-300',
                          'mr-3 h-6 w-6 shrink-0 pt-0.5',
                        )}
                      >
                        <Icon name={item.icon as SvgIconName} />
                      </div>
                      {item.name}
                    </>
                  </Link>
                ))}
                <SupportButton />
              </nav>
            </div>
            <Show when={!!user}>
              <MenuSectionUser user={user!} displayAvatar isCollapsible />
            </Show>
          </div>
        </div>
        <div className="flex h-full flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-info-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-info-500 hover:text-info-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="h-full flex-1">
            <div className={layoutChildrenClasses({ padding: !isBrandingDetails, overflow: isBrandingDetails })}>
              {children}
            </div>
          </main>
        </div>
      </div>
    </ModalContextProvider>
  );
};

export default MenuLayout;
