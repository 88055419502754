import CognitoLogin from './CognitoLogin';
import EntraLogin from './EntraLogin';

const getLogin = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return EntraLogin;
  }

  return CognitoLogin;
};

const Login = getLogin();
export default Login;
