import { EndorsementSummaryInsertMenuController } from '@features/endorsement-summary-insert-menu';
import { ContractInsertMenu } from '@root/src/containers/WysiwygEditor/components/ContractInsertMenu';
import { BrandingInsertMenu } from '@root/src/features/branding-edit/components/BrandingInsertMenu';

import type { CustomOptionProps } from '../types';

export const getContractDatapointControls = (editor: any): CustomOptionProps => ({
  custom: () => <ContractInsertMenu editor={editor} key="contract-insert-menu" />,
  key: 'contract-insert-menu',
});

export const getBrandingDatapointControls = (editor: any): CustomOptionProps => ({
  custom: () => <BrandingInsertMenu editor={editor} key="branding-insert-menu" />,
  key: 'branding-insert-menu',
});

export const getEndorsementSummaryBlockDatapointControls = (editor: any): CustomOptionProps => ({
  custom: () => <EndorsementSummaryInsertMenuController editor={editor} key="endorsement-summary-block-insert-menu" />,
  key: 'endorsement-summary-block-insert-menu',
});
