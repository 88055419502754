import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { ReactRenderer } from '@tiptap/react';
import type { DatapointInserterRenderFactory } from '@WysiwygEditor/components/Editor/types';

export const getBrandingDatapointInserterRenderer: DatapointInserterRenderFactory = (
  props,
  items,
  handleInsertDatapoint,
  handleCancel,
) =>
  new ReactRenderer(SelectMenu, {
    props: {
      className: 'max-h-[364px]',
      items,
      range: props.range,
      filterText: props.query,
      expandFirstLevel: false,
      isSearchable: true,
      onClose: handleCancel,
      onItemClick: handleInsertDatapoint,
      disableMotion: true,
    },
    editor: props.editor,
  });
