import type { FC, MouseEventHandler } from 'react';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { mdiPencil, mdiTrashCanOutline } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';

import { iconClasses } from '../classes';
import { getId } from '../utils';

import { Container } from './Container';

interface SummaryBlockActionsProps {
  block: ResourceBlock;
  handleDelete: MouseEventHandler;
  handleEdit: MouseEventHandler<HTMLDivElement>;
}

export const SummaryBlockActions: FC<SummaryBlockActionsProps> = ({ block, handleDelete, handleEdit }) => {
  const { name } = block;

  return (
    <Container>
      <div className="flex h-fit flex-col gap-3">
        <TooltipedIcon
          size={0.9}
          className={iconClasses()}
          data-testid={getId('edit', name)}
          path={mdiPencil}
          onClick={handleEdit}
          content="Edit block"
        />
        <TooltipedIcon
          size={0.9}
          className={iconClasses()}
          data-testid={getId('delete', name)}
          path={mdiTrashCanOutline}
          onClick={handleDelete}
          content="Remove block"
        />
      </div>
    </Container>
  );
};
