import dayjs from 'dayjs';

export interface BaseItem {
  created_at: string;
  deleted_at?: string;
  updated_at?: string;
}

type Order = 'desc' | 'asc';

export const sortByDate = <T extends BaseItem>(
  data: T[] = [],
  order: Order = 'desc',
  dateFieldName: keyof BaseItem = 'created_at',
) => {
  return data.sort((a, b) => {
    const aDate = dayjs(a[dateFieldName]);
    const bDate = dayjs(b[dateFieldName]);
    const isDescSort = order === 'desc';

    if (aDate.isAfter(bDate)) {
      return isDescSort ? -1 : 1;
    }

    return isDescSort ? 1 : -1;
  });
};
