import type { FC } from 'react';
import React from 'react';

interface ColumnsSkeletonProps {
  length?: number;
}

export const ColumnsSkeleton: FC<ColumnsSkeletonProps> = ({ length = 3 }) => {
  return (
    <>
      {new Array(length).fill(0).map((_, idx) => {
        return (
          <td
            className="w-1/4 p-4 py-[1.297rem] text-sm font-normal leading-5 text-info-900"
            key={`skeleton-column-${idx}`}
          >
            <p className="h-3 w-full animate-pulse rounded-xl bg-slate-200" />
          </td>
        );
      })}
    </>
  );
};
