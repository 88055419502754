import Icon from '@components/Icon';
import { findFieldFromSchema } from '@root/helpers/schema';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import { isTemplatePath } from '@utils/app-paths';
import clsx from 'clsx';
import { get } from 'lodash-es';

interface RepeaterInnerProps {
  borderClassName?: string;
  defaultLabel: string;
  editor: any;
  headerClassName?: string;
  isDeletable?: boolean;
  name: string;
  shouldHideSectionLabel?: boolean;
}

export const RepeaterInner = ({
  borderClassName = 'border-purple-200',
  defaultLabel,
  headerClassName = 'bg-purple-200 text-purple-800',
  name,
  shouldHideSectionLabel = false,
  ...props
}: RepeaterInnerProps) => {
  const isTemplate = isTemplatePath();

  const parentId = get(props, 'node.attrs.parentId', '');
  const order = Number(get(props, 'node.attrs.order'));
  const shouldDisplaySectionSuffix = !shouldHideSectionLabel && !isTemplate && order !== undefined;
  const isFirst = order === 0;

  const field = findFieldFromSchema(parentId);

  const labelText = field?.title ?? defaultLabel;
  const parsedLabelText = `${labelText}${shouldDisplaySectionSuffix ? ` - ${order + 1}` : ''}`;

  const handleDelete = () => {
    return props.editor.commands.deleteNode(name);
  };

  return (
    <NodeViewWrapper
      className={clsx('flex flex-col border-2 border-purple-200', isFirst && 'rounded-t-md', borderClassName)}
    >
      <span
        className={clsx(
          'flex items-center gap-x-2.5 bg-purple-200 p-1 text-sm font-bold text-purple-800',
          headerClassName,
        )}
      >
        <Icon name="duplicate" />
        {parsedLabelText}
        {isFirst && (
          <Icon
            className="ml-auto cursor-pointer"
            name="trashcan"
            onClick={handleDelete}
            title="Remove section template"
          />
        )}
      </span>
      <NodeViewContent className="content p-5" data-testid="repeater-inner" />
    </NodeViewWrapper>
  );
};
