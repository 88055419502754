import type {
  SummaryBlockCreate,
  SummaryBlockDelete,
  SummaryBlockHideFromPreview,
  SummaryBlockUpdate,
  SummarySectionDelete,
} from '@domain/schemas/endorsement-summary.schema';
import type {
  AddBlockFromLibraryPayload,
  AddNewCommentPayload,
  ChangeBaseResourcePayload,
  CreateRenewalPayload,
  CreateResourceBlockPayload,
  CreateResourcePayload,
  CreateResourceSectionPayload,
  CreateRevisionPayload,
  CreateSubmissionPayload,
  CreateTemplatePayload,
  DeleteResourceBlockPayload,
  DeleteResourceSectionPayload,
  RecreatePdfWithAttachmentPayload,
  RemoveResourceAttachmentPayload,
  RenameResourceAttachmentPayload,
  ReorderResourceAttachmentPayload,
  ReorderResourceBlockPayload,
  ReorderResourceSectionPayload,
  ResolveCommentsThreadPayload,
  RestoreRevisionPayload,
  RootResourceStatus,
  SubmissionStatus,
  TemplateStatus,
  UpdateBlockInLibraryPayload,
  UpdateEndorsementDataPayload,
  UpdateResourceAttachmentStatusPayload,
  UpdateResourceBlockPayload,
  UpdateResourceDataPayload,
  UpdateResourceSectionPayload,
} from '@root/functions/validation';

export type {
  AddBlockFromLibraryPayload,
  AddNewCommentPayload,
  ChangeBaseResourcePayload,
  CreateRenewalPayload,
  CreateResourceBlockPayload,
  CreateResourcePayload,
  CreateResourceSectionPayload,
  CreateRevisionPayload,
  CreateSubmissionPayload,
  CreateTemplatePayload,
  DeleteResourceBlockPayload,
  DeleteResourceSectionPayload,
  RecreatePdfWithAttachmentPayload,
  RemoveResourceAttachmentPayload,
  RenameResourceAttachmentPayload,
  ReorderResourceAttachmentPayload,
  ReorderResourceBlockPayload,
  ReorderResourceSectionPayload,
  ResolveCommentsThreadPayload,
  RestoreRevisionPayload,
  RootResourceStatus,
  SubmissionStatus,
  TemplateStatus,
  UpdateBlockInLibraryPayload,
  UpdateEndorsementDataPayload,
  UpdateResourceAttachmentStatusPayload,
  UpdateResourceBlockPayload,
  UpdateResourceDataPayload,
  UpdateResourceSectionPayload,
};

export enum ApiPatchOperation {
  AddNewBlockFromLibrary = 'ADD_BLOCK_FROM_LIBRARY',
  AddNewComment = 'ADD_NEW_COMMENT',
  ApplyEndorsement = 'APPLY_ENDORSEMENT',
  ChangeBaseSubmission = 'CHANGE_BASE_SUBMISSION',
  ChangeBaseTemplate = 'CHANGE_BASE_TEMPLATE',
  CreateResourceBlock = 'CREATE_SUBMISSION_BLOCK',
  CreateResourceSection = 'CREATE_SUBMISSION_SECTION',
  CreateRevision = 'CREATE_REVISION',
  DeleteResourceBlock = 'DELETE_SUBMISSION_BLOCK',
  DeleteResourceSection = 'DELETE_SUBMISSION_SECTION',
  RecreatePdfWithAttachment = 'RECREATE_PDF_WITH_ATTACHMENT',
  RemoveResourceAttachment = 'REMOVE_ATTACHMENT',
  RenameResourceAttachment = 'RENAME_ATTACHMENT',
  ReorderResourceAttachment = 'REORDER_SUBMISSION_ATTACHMENT',
  ReorderResourceBlock = 'REORDER_SUBMISSION_BLOCK',
  ReorderResourceSection = 'REORDER_SUBMISSION_SECTION',
  ResolveCommentsThread = 'RESOLVE_COMMENTS_THREAD',
  RestoreRevision = 'RESTORE_REVISION',
  UpdateResourceAttachmentStatus = 'UPDATE_ATTACHMENT_STATUS',
  UpdateResourceBlock = 'UPDATE_SUBMISSION_BLOCK',
  UpdateResourceData = 'UPDATE_SUBMISSION_DATA',
  UpdateResourceSection = 'UPDATE_SUBMISSION_SECTION',
  SoftDelete = 'SOFT_DELETE',
  UpdateStatus = 'UPDATE_STATUS',
  UpdateName = 'UPDATE_NAME',
  UpdateContent = 'UPDATE_CONTENT',
  Restore = 'RESTORE',
  GeneralUpdate = 'GENERAL_UPDATE',
  CreateSummaryBlock = 'CREATE_SUMMARY_BLOCK',
  UpdateSummaryBlock = 'UPDATE_SUMMARY_BLOCK',
  DeleteSummaryBlock = 'DELETE_SUMMARY_BLOCK',
  DeleteSummarySection = 'DELETE_SUMMARY_SECTION',
  HideBlockFromPreview = 'HIDE_BLOCK_FROM_PREVIEW',
  RestoreBlocksHiddenFromPreview = 'RESTORE_ALL_BLOCKS_HIDDEN_FROM_PREVIEW',
  RestoreEndorsementSummaryTemplate = 'RESTORE_ENDORSEMENT_SUMMARY_TEMPLATE',
}

type WithApiOperation<T extends ApiPatchOperation, Base = {}> = Base & {
  operation: T;
};

export type AddNewCommentApiPayload = WithApiOperation<ApiPatchOperation.AddNewComment, AddNewCommentPayload>;
export type AddBlockFromLibraryApiPayload = WithApiOperation<
  ApiPatchOperation.AddNewBlockFromLibrary,
  AddBlockFromLibraryPayload
>;
export type ChangeBaseSubmissionApiPayload = WithApiOperation<
  ApiPatchOperation.ChangeBaseSubmission,
  ChangeBaseResourcePayload
>;

export type ChangeBaseTemplateApiPayload = WithApiOperation<
  ApiPatchOperation.ChangeBaseTemplate,
  ChangeBaseResourcePayload
>;
export type CreateResourceBlockApiPayload = WithApiOperation<
  ApiPatchOperation.CreateResourceBlock,
  CreateResourceBlockPayload
>;
export type CreateResourceSectionApiPayload = WithApiOperation<
  ApiPatchOperation.CreateResourceSection,
  CreateResourceSectionPayload
>;
export type CreateRevisionApiPayload = WithApiOperation<ApiPatchOperation.CreateRevision, CreateRevisionPayload>;
export type DeleteResourceBlockApiPayload = WithApiOperation<
  ApiPatchOperation.DeleteResourceBlock,
  DeleteResourceBlockPayload
>;
export type DeleteResourceSectionApiPayload = WithApiOperation<
  ApiPatchOperation.DeleteResourceSection,
  DeleteResourceSectionPayload
>;
export type RecreatePdfWithAttachmentApiPayload = WithApiOperation<
  ApiPatchOperation.RecreatePdfWithAttachment,
  RecreatePdfWithAttachmentPayload
>;
export type RemoveResourceAttachmentApiPayload = WithApiOperation<
  ApiPatchOperation.RemoveResourceAttachment,
  RemoveResourceAttachmentPayload
>;
export type RenameResourceAttachmentApiPayload = WithApiOperation<
  ApiPatchOperation.RenameResourceAttachment,
  RenameResourceAttachmentPayload
>;
export type ReorderResourceAttachmentApiPayload = WithApiOperation<
  ApiPatchOperation.ReorderResourceAttachment,
  ReorderResourceAttachmentPayload
>;
export type ReorderResourceBlockApiPayload = WithApiOperation<
  ApiPatchOperation.ReorderResourceBlock,
  ReorderResourceBlockPayload
>;
export type ReorderResourceSectionApiPayload = WithApiOperation<
  ApiPatchOperation.ReorderResourceSection,
  ReorderResourceSectionPayload
>;
export type ResolveCommentsThreadApiPayload = WithApiOperation<
  ApiPatchOperation.ResolveCommentsThread,
  ResolveCommentsThreadPayload
>;

export type RestoreRevisionApiPayload = WithApiOperation<ApiPatchOperation.RestoreRevision, RestoreRevisionPayload>;

export type UpdateResourceAttachmentStatusApiPayload = WithApiOperation<
  ApiPatchOperation.UpdateResourceAttachmentStatus,
  UpdateResourceAttachmentStatusPayload
>;
export type UpdateResourceBlockApiPayload = WithApiOperation<
  ApiPatchOperation.UpdateResourceBlock,
  UpdateResourceBlockPayload
>;
export type UpdateResourceDataApiPayload = WithApiOperation<
  ApiPatchOperation.UpdateResourceData,
  UpdateResourceDataPayload
>;
export type UpdateResourceSectionApiPayload = WithApiOperation<
  ApiPatchOperation.UpdateResourceSection,
  UpdateResourceSectionPayload
>;

export interface ApplyEndorsementDataInput {
  operation: ApiPatchOperation.ApplyEndorsement;
  status?: string;
}

export interface RestoreEndorsementSummaryTemplateApiPayload {
  operation: ApiPatchOperation.RestoreEndorsementSummaryTemplate;
}

export type SubmissionEventPayload =
  | AddBlockFromLibraryApiPayload
  | AddNewCommentApiPayload
  | ChangeBaseSubmissionApiPayload
  | ChangeBaseTemplateApiPayload
  | CreateResourceBlockApiPayload
  | CreateResourceSectionApiPayload
  | CreateRevisionApiPayload
  | DeleteResourceBlockApiPayload
  | DeleteResourceSectionApiPayload
  | RecreatePdfWithAttachmentApiPayload
  | RemoveResourceAttachmentApiPayload
  | RenameResourceAttachmentApiPayload
  | ReorderResourceAttachmentApiPayload
  | ReorderResourceBlockApiPayload
  | ReorderResourceSectionApiPayload
  | ResolveCommentsThreadApiPayload
  | RestoreEndorsementSummaryTemplateApiPayload
  | RestoreRevisionApiPayload
  | UpdateResourceAttachmentStatusApiPayload
  | UpdateResourceBlockApiPayload
  | UpdateResourceDataApiPayload
  | UpdateResourceSectionApiPayload;

export type TemplateEventPayload = Exclude<
  SubmissionEventPayload,
  ChangeBaseTemplateApiPayload | ChangeBaseSubmissionApiPayload
>;

export type CreateSummaryBlockApiPayload = WithApiOperation<ApiPatchOperation.CreateSummaryBlock, SummaryBlockCreate>;
export type DeleteSummaryBlockApiPayload = WithApiOperation<ApiPatchOperation.DeleteSummaryBlock, SummaryBlockDelete>;
export type DeleteSummarySectionApiPayload = WithApiOperation<
  ApiPatchOperation.DeleteSummarySection,
  SummarySectionDelete
>;
export type HideBlockFromPreviewApiPayload = WithApiOperation<
  ApiPatchOperation.HideBlockFromPreview,
  SummaryBlockHideFromPreview
>;
export type RestoreBlocksHiddenFromPreviewApiPayload =
  WithApiOperation<ApiPatchOperation.RestoreBlocksHiddenFromPreview>;
export type UpdateSummaryBlockApiPayload = WithApiOperation<ApiPatchOperation.UpdateSummaryBlock, SummaryBlockUpdate>;

export type EndorsementSummaryEventPayload =
  | CreateSummaryBlockApiPayload
  | DeleteSummaryBlockApiPayload
  | DeleteSummarySectionApiPayload
  | HideBlockFromPreviewApiPayload
  | RestoreBlocksHiddenFromPreviewApiPayload
  | UpdateSummaryBlockApiPayload;
