import type { FC, PropsWithChildren } from 'react';
import { BlockEditProvider } from '@ContractBuilder/modules/block-edit';
import { ContractEndorsementBannerController } from '@ContractBuilder/modules/contract-endorsement-banner';
import { DrawerLayoutController } from '@ContractBuilder/modules/drawer-layout';
import { EditorsMapProvider } from '@ContractBuilder/modules/editors-map';
import { RenewalsBannerController } from '@features/renewals-banner';
import { ModalContextProvider, ModalSwitch } from '@src/hooks/useModalProvider';

import TempContractBanner from './contract-builder-header/components/TempContractBanner';
import { VariationsTabsProvider } from './modules/variations-tabs';
import { ContractBuilderHeader } from './contract-builder-header';
import { ScrollToAnchor } from './ScrollToAnchor';

const ContractBuilderLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ModalContextProvider>
      <EditorsMapProvider>
        <BlockEditProvider>
          <ModalSwitch />
          <ScrollToAnchor />
          <ContractBuilderHeader />
          <TempContractBanner />
          <VariationsTabsProvider>
            <ContractEndorsementBannerController />
            <RenewalsBannerController />
            <DrawerLayoutController className="overflow-y-hidden bg-info-50">{children}</DrawerLayoutController>
          </VariationsTabsProvider>
        </BlockEditProvider>
      </EditorsMapProvider>
    </ModalContextProvider>
  );
};

export default ContractBuilderLayout;
