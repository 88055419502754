import type { DynamoDBResourceBlock } from '@root/@types/database';
import { isNonNullish } from '@root/helpers';

import { getExistingVariationsVisibilityConfig } from '../variations/get-exisiting-variations';

/** Back-fill for potential `null` present on some blocks in block.visibility.variants array */
export const sanitizeVisibilityPayload = <
  T extends Omit<DynamoDBResourceBlock, 'sk' | 'pk' | 'created_at' | 'created_by'>,
>(
  rootBlock: T,
) => {
  const { visibility } = rootBlock;

  if (!visibility) {
    return rootBlock;
  }

  const hasVariations = !!rootBlock?.variations?.length;

  if (!hasVariations) {
    return {
      ...rootBlock,
      visibility: {
        block: visibility.block,
      },
    };
  }

  const existingVariationsVisibility = getExistingVariationsVisibilityConfig(rootBlock)?.filter(isNonNullish) ?? [];

  return {
    ...rootBlock,
    visibility: {
      variations: existingVariationsVisibility,
    },
  };
};
