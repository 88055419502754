import type { FC } from 'react';
import { useUserStore } from '@Auth/store';
import type { ButtonProps } from '@components/Button';
import Form from '@components/Form';
import Modal from '@components/Modal';
import { getAllTeams, getUserTeams } from '@root/helpers/permissions/utils';
import clsx from 'clsx';

import { formContainerClasses } from '../classes';
import { FormFooter, Stepper } from '../components';
import { useCreateEntity, useCreateEntityForm, useStepper } from '../hooks';
import type { EntityModalType } from '../types';
import { DataType, Step } from '../types';
import { FormContent } from '../views';

interface CreateEntityModalProps {
  onClose: () => void;
  type: EntityModalType;
}

export const CreateEntityModal: FC<CreateEntityModalProps> = ({ onClose, type }) => {
  const { user } = useUserStore(({ user }) => ({ user }));
  const isTemplateModal = type === 'template';

  const userTeams = isTemplateModal ? getAllTeams() : getUserTeams(user, true);

  const { currentStep, getStepperItemState, onNext, onPrevious } = useStepper({ onCancel: onClose });
  const { isLoading, onSubmit } = useCreateEntity({ onClose });
  const { control, formValues, handleSubmit, isValid, setValue } = useCreateEntityForm(userTeams, currentStep);

  const commonButtonProps: ButtonProps = { isDisabled: !isValid, className: 'h-[2.375rem]', kind: 'primary' };

  const isMRCUpload = formValues?.dataType === DataType.Pdf;
  const isLastStep = isMRCUpload ? currentStep === Step.MrcFileUpload : currentStep === Step.BrandingInfo;

  return (
    <Modal
      className="w-[32rem]"
      onClose={onClose}
      open
      shouldShowCloseIcon={false}
      title={`Create a new ${type}`}
      titleClassName="text-lg leading-6 font-medium !justify-center"
    >
      <div className={clsx(formContainerClasses)}>
        <p className="text-sm font-normal leading-5 text-gray-500">
          Please fill in the following information to setup a {type}
        </p>
        <div className="py-4">
          <Stepper getStepperItemState={getStepperItemState} />
        </div>
        <Form
          className="flex w-full flex-col gap-6"
          onSubmit={handleSubmit(onSubmit)}
          aria-label={`Create ${type} form`}
        >
          <FormContent
            control={control}
            currentStep={currentStep}
            formValues={formValues}
            isLoading={isLoading}
            setValue={setValue}
            type={type}
            userTeams={userTeams}
          />

          <FormFooter
            {...commonButtonProps}
            currentStep={currentStep}
            formValues={formValues}
            isLastStep={isLastStep}
            isLoading={isLoading}
            isMRCUpload={isMRCUpload}
            onNext={onNext}
            onPrevious={onPrevious}
          />
        </Form>
      </div>
    </Modal>
  );
};
