import { useQuery } from 'react-query';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import type { UseQueryRefetchFn, UseQueryStatus } from '@root/@types/types';
import qs from 'qs';

import { fetchBlocks } from '../queries';

interface BlocksLibraryHook {
  data?: LibraryBlockTypeForListPage[];
  names: string[];
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useBlocksLibrary = (params = {}, enabled = true): BlocksLibraryHook => {
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, status, isFetching, refetch } = useQuery(
    ['blocks', JSON.stringify(params)],
    () => fetchBlocks(queryParams.authToken as string, params),
    {
      refetchOnWindowFocus: false,
      enabled,
    },
  );

  return {
    data,
    names: [],
    status,
    isFetching,
    refetch,
  };
};
