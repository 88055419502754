import EntraSupportButton from './EntraSupportButton';

const getSupportButton = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return EntraSupportButton;
  }

  return () => {
    return null;
  };
};

const SupportButton = getSupportButton();
export default SupportButton;
