import { useCallback } from 'react';
import { Link, useBeforeUnload, useLocation } from 'react-router-dom';
import { Show } from '@components/Show';
import { Spinner } from '@components/Spinner';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { RevisionBannerController } from '@ContractBuilder/contract-builder-header/modules/revision-banner';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { useDatapointsStore } from '@ContractBuilder/store/datapoints.store';
import { canChange } from '@helpers/canChange';
import { useIsRevisionHistory } from '@helpers/useIsRevisionHistory';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useHistoryBackTrap } from '@hooks/use-history-back-trap';
import { mdiHistory } from '@mdi/js';
import { DocumentMode } from '@root/@types/types';
import sharedClasses from '@root/src/utils/shared-classes';
import { getDocumentMode } from '@src/helpers/getDocumentMode';
import { isEndorsementPath } from '@utils/app-paths';
import clsx from 'clsx';
import dayjs from 'dayjs';

import Nav from '../../../routes';
import { useEntityStore } from '../store';

import { ContractToolsController } from './modules/contract-tools';
import { EditableNameField } from './modules/entity-details/views/EditableNameField';
import { EntityDetails } from './modules/entity-details/views/EntityDetails';
import { ViewSelectionTabs } from './components';
import { endorsementPublishingOptions, submissionPublishingOptions, templatePublishingOptions } from './constants';

const getConfig = (mode: DocumentMode, ids: string[]) =>
  ({
    [DocumentMode.TEMPLATES]: {
      arrowBack: { to: Nav.Templates, title: 'templates' },
      publishingOptions: templatePublishingOptions,
    },
    [DocumentMode.SUBMISSIONS]: {
      arrowBack: { to: Nav.Submissions, title: 'submissions' },
      publishingOptions: submissionPublishingOptions,
    },
    [DocumentMode.ENDORSEMENTS]: {
      arrowBack: { to: Nav.SubmissionContract.replace(':id', ids[0]), title: 'original contract' },
      publishingOptions: endorsementPublishingOptions,
    },
    [DocumentMode.OTHER]: {
      publishingOptions: [],
    },
    [DocumentMode.BRANDING]: {
      publishingOptions: [],
    },
  })[mode];

export const ContractBuilderHeader = () => {
  const { pathname } = useLocation();
  const isViewingRevisionHistory = useIsRevisionHistory();
  const isEditing = useBlockEditFormStore((state) => state.formValues?.id !== undefined);
  const { isDirty } = useDatapointsStore(({ isDirty }) => ({ isDirty }));
  const { submission, isLoading, updateResourceData } = useEntityStore(
    ({ submission, updateResourceData, isLoading }) => ({
      submission,
      isLoading,
      updateResourceData,
    }),
  );

  const { mode, ids } = getDocumentMode(pathname);
  const config = getConfig(mode, ids);
  const canNavigate = !(isDirty || isEditing) && !isLoading;
  const isEndorsement = isEndorsementPath();

  const hasOperationsDisabled = !canChange(submission?.status) || isViewingRevisionHistory || isEndorsement;

  const handleSave = async (name: string, value?: string | number[]) => {
    await updateResourceData({ [name]: value });
  };

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!canNavigate) {
        event.preventDefault();
        return (event.returnValue = 'Are you sure you want to exit?');
      }
    },
    [canNavigate],
  );

  const handleTrap = useCallback(
    async (resume: () => void) => {
      if (!canNavigate) {
        return false;
      } else {
        resume();
        return true;
      }
    },

    [canNavigate],
  );

  useHistoryBackTrap(handleTrap);
  useBeforeUnload(handleUnload);

  if (mode === DocumentMode.OTHER) {
    return null;
  }

  if (!submission) {
    return <Spinner />;
  }

  const dateInfo = `Last updated ${dayjs(submission.updated_at ?? submission.created_at).fromNow()}${
    !!submission.last_updated_by && ` by ${submission.last_updated_by.name}`
  }`;

  return (
    <div className={'flex flex-col gap-y-1 border-b bg-white'}>
      <div className="flex flex-col px-5 py-3">
        <div className="flex w-full flex-nowrap items-center justify-between">
          {config.arrowBack && (
            <div className={clsx(!canNavigate && sharedClasses.disabledContainer)}>
              <Link to={config.arrowBack.to} className={clsx('group', !canNavigate && sharedClasses.disabled)}>
                <div
                  title={`Back to ${config.arrowBack.title}`}
                  className={'mt-0.25 mr-2 rounded-full border-2 border-info-600 p-1 group-hover:bg-gray-100'}
                >
                  <ArrowLeftIcon className="h-4 w-4 text-info-600" strokeWidth="3" />
                </div>
              </Link>
            </div>
          )}
          <div className="mr-2 flex min-w-0 items-center gap-x-4 2xl:flex-nowrap">
            <EditableNameField entity={submission} hasOperationsDisabled={hasOperationsDisabled} onSave={handleSave} />
            <TooltipedIcon
              content={dateInfo}
              maxWidth={200}
              path={mdiHistory}
              className="cursor-default text-info-400 2xl:hidden"
              size={0.7}
            />
            <p className="hidden cursor-default whitespace-nowrap text-sm font-light text-info-400 2xl:block">
              {dateInfo}
            </p>
          </div>
          <div className="mx-auto">
            <ViewSelectionTabs />
          </div>
          <div id="tools" className="flex flex-row place-items-center gap-5">
            <ContractToolsController publishingOptions={config.publishingOptions} />
          </div>
        </div>
        <EntityDetails entity={submission} hasOperationsDisabled={hasOperationsDisabled} onSave={handleSave} />
      </div>
      <Show when={isViewingRevisionHistory}>
        <RevisionBannerController />
      </Show>
    </div>
  );
};
