import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import type { ResourceBlock, ResourceSection } from '@root/@types/base';
import { get } from 'lodash-es';

import { getSortedEntries } from '../layout';

/** Get all blocks ordered exactly how they appear in the document, from top to bottom **/
const getEntitySortedBlocks = <T extends EntityData | EndorsementData>(entity: T, isEndorsementSummary = false) => {
  const accessorPrefix = isEndorsementSummary ? 'summary.' : '';

  const sections = get(entity, `${accessorPrefix}sections`, []) as ResourceSection[];
  const layout = get(entity, `${accessorPrefix}layout`, []) as string[];

  const sortedSections = getSortedEntries(layout, sections);
  return sortedSections.flatMap((section) => getSortedEntries(section.layout, section.blocks));
};

export const getAllBlocks = <T extends EntityData | EndorsementData>(
  entity?: T,
  isEndorsementView: boolean = false,
): ResourceBlock[] => {
  if (!entity) {
    return [];
  }

  return getEntitySortedBlocks(entity, isEndorsementView);
};
