import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import type { DynamoDBResourceBlock } from '@root/@types/database';
import { get } from 'lodash-es';

const isEndorsement = (entity?: EntityData | EndorsementData): entity is EndorsementData => {
  if (!entity) {
    return false;
  }

  return entity.pk.startsWith('submissions#') && entity.sk.startsWith('endorsements#');
};

export const isExcludedFromEndorsementPreview = (
  block: DynamoDBResourceBlock,
  entity?: EntityData | EndorsementData,
): boolean => {
  if (!isEndorsement(entity)) {
    return false;
  }

  const blocksExcludedFromPreview = get(entity, 'summary.blocksExcludedFromPreview', [] as string[]);
  return blocksExcludedFromPreview.includes(block.id);
};
