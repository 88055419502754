import type { SubmissionPatchResponse } from '@ContractBuilder/types';
import { getCurrentDocumentApiUrl } from '@helpers/getCurrentDocumentApiUrl';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type {
  AddBlockFromLibraryApiPayload,
  AddNewCommentApiPayload,
  ApplyEndorsementDataInput,
  ChangeBaseSubmissionApiPayload,
  ChangeBaseTemplateApiPayload,
  CreateResourceBlockApiPayload,
  CreateResourceSectionApiPayload,
  CreateRevisionApiPayload,
  DeleteResourceBlockApiPayload,
  DeleteResourceSectionApiPayload,
  RecreatePdfWithAttachmentApiPayload,
  RemoveResourceAttachmentApiPayload,
  RenameResourceAttachmentApiPayload,
  ReorderResourceAttachmentApiPayload,
  ReorderResourceBlockApiPayload,
  ReorderResourceSectionApiPayload,
  ResolveCommentsThreadApiPayload,
  RestoreEndorsementSummaryTemplateApiPayload,
  RestoreRevisionApiPayload,
  UpdateResourceAttachmentStatusApiPayload,
  UpdateResourceBlockApiPayload,
  UpdateResourceDataApiPayload,
  UpdateResourceSectionApiPayload,
} from '@root/@types/payloads';
import axios from 'axios';

type PatchEntityData =
  | AddBlockFromLibraryApiPayload
  | AddNewCommentApiPayload
  | ApplyEndorsementDataInput
  | ChangeBaseSubmissionApiPayload
  | ChangeBaseTemplateApiPayload
  | CreateResourceBlockApiPayload
  | CreateResourceSectionApiPayload
  | CreateRevisionApiPayload
  | DeleteResourceBlockApiPayload
  | DeleteResourceSectionApiPayload
  | RecreatePdfWithAttachmentApiPayload
  | RemoveResourceAttachmentApiPayload
  | RenameResourceAttachmentApiPayload
  | ReorderResourceAttachmentApiPayload
  | ReorderResourceBlockApiPayload
  | ReorderResourceSectionApiPayload
  | ResolveCommentsThreadApiPayload
  | RestoreEndorsementSummaryTemplateApiPayload
  | RestoreRevisionApiPayload
  | UpdateResourceAttachmentStatusApiPayload
  | UpdateResourceBlockApiPayload
  | UpdateResourceDataApiPayload
  | UpdateResourceSectionApiPayload;

type PatchEntity = (data: PatchEntityData, entityId?: string) => Promise<SubmissionPatchResponse>;

export const patchEntity: PatchEntity = async (data, entityId) => {
  const url = getCurrentDocumentApiUrl(location.pathname);
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: entityId ? `${url}/${entityId}` : url,
    data,
  });

  try {
    const response = await axios(networkPayload);
    return response.data;
  } catch {
    return { message: 'Server Error' };
  }
};
