import axios from 'axios';

import type { CreateTemplatePayload, Template } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const createTemplate =
  (isTemplate: boolean) =>
  async (data: CreateTemplatePayload): Promise<Pick<Template, 'id'>> => {
    const networkPayload = await getRequestAuthPayload({
      method: 'post',
      url: `${isTemplate ? 'templates-spa-al' : 'submissions-spa-al'}`,
      data,
    });

    const response = await axios(networkPayload);
    return response.data;
  };
