import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewPath } from '@ContractBuilder/contract-builder-header/constants';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { DocumentMode } from '@root/@types/types';
import { isEndorsementViewPath } from '@utils/app-paths';

export const useDocumentLocation = () => {
  const { pathname } = useLocation();
  const { mode } = getDocumentMode(pathname);
  const currentPath = pathname.split('/').pop() as ViewPath;

  const isEndorsement = mode === DocumentMode.ENDORSEMENTS;
  const isEndorsementViewAppPath = isEndorsementViewPath();

  return useMemo(
    () => ({
      isContractView: currentPath === ViewPath.Contract,
      isEndorsement: mode === DocumentMode.ENDORSEMENTS,
      isFormView: currentPath === ViewPath.Form,
      isSubmission: mode === DocumentMode.SUBMISSIONS,
      isTemplate: mode === DocumentMode.TEMPLATES,
      isEndorsementView: isEndorsement && isEndorsementViewAppPath,
    }),
    [currentPath, isEndorsement, isEndorsementViewAppPath, mode],
  );
};
