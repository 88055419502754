import type { FC } from 'react';
import { useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import Divider from '@components/Divider';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { Attachments } from '@ContractBuilder/modules/attachment/views/Attachments';
import { NavSectionController } from '@ContractBuilder/modules/navigation/modules/nav-section';
import { SectionModalController } from '@ContractBuilder/modules/navigation/modules/section-modal';
import { NavigationLoading } from '@ContractBuilder/modules/navigation/views/NavigationLoading';
import { useEntityStore } from '@ContractBuilder/store';
import { useFeatureFlags } from '@hooks/useFeatureFlags';
import type { ResourceSection } from '@root/@types/base';
import StrictModeDroppable from '@root/src/components/StrictModeDroppable';
import { isEndorsementPath } from '@root/src/utils/app-paths';
import clsx from 'clsx';

interface NavigationProps {
  activeSectionId?: string;
  getShouldDisableOperations?: (section: ResourceSection) => boolean;
  isDragDisabled: boolean;
  sections: ResourceSection[];
}

export const Navigation: FC<NavigationProps> = ({
  activeSectionId,
  getShouldDisableOperations = () => false,
  isDragDisabled,
  sections,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { reorderBlock, reorderSection } = useEntityStore(({ reorderBlock, reorderSection }) => ({
    reorderBlock,
    reorderSection,
  }));
  const isEndorsementAppPath = isEndorsementPath();
  const hasAttachmentsFeature = useFeatureFlags()('Attachments');

  const handleDragEnd = async (result: DropResult) => {
    const { destination, type } = result;

    if (!destination) {
      return;
    }

    try {
      setIsLoading(true);
      if (type === 'COLUMN') {
        await reorderSection(result);
        return;
      }

      await reorderBlock(result);
    } catch (_error) {
      // do nothing
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionModalController />
      <div className="overflow-y-auto py-3">
        <LoadingOverlay active={isLoading} className="!fixed" />
        <DragDropContext onDragEnd={handleDragEnd}>
          <StrictModeDroppable
            droppableId="droppable"
            direction="vertical"
            fallback={<NavigationLoading />}
            type="COLUMN"
          >
            {(provided, snapshot) => (
              <ol
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={clsx(snapshot.isDraggingOver && 'bg-info-100')}
              >
                {sections.map((section, index) => {
                  const shouldDisableOperations = getShouldDisableOperations(section);
                  return (
                    <Draggable key={section.id} draggableId={section.id} index={index} isDragDisabled={isDragDisabled}>
                      {(providedInner) => (
                        <li
                          id={`${section.id}-draggable`}
                          key={section.id}
                          ref={providedInner.innerRef}
                          {...providedInner.draggableProps}
                          {...providedInner.dragHandleProps}
                        >
                          <NavSectionController
                            activeSection={activeSectionId}
                            dragHandleProps={providedInner.dragHandleProps}
                            isDragDisabled={isDragDisabled}
                            section={section}
                            shouldDisableOperations={shouldDisableOperations}
                          />
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ol>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
      <Show when={!isEndorsementAppPath && hasAttachmentsFeature}>
        <>
          <Divider />
          <Attachments />
        </>
      </Show>
    </>
  );
};
