import TableCellExtension from '@tiptap/extension-table-cell';

import type { CellBorder, TableBorderStyle } from './types';

const ALL_BORDERS_IDS = ['hidetop', 'hideright', 'hidebottom', 'hideleft'];

export const TableCell = () =>
  TableCellExtension.extend({
    addAttributes() {
      return {
        colspan: {
          default: 1,
        },
        rowspan: {
          default: 1,
        },
        colwidth: {
          default: null,
          parseHTML: (element: any) => {
            const colwidth = element.getAttribute('colwidth') ?? element.style.width.replace('px', '');
            return colwidth ? [parseInt(colwidth, 10)] : null;
          },
          renderHTML: ({ colwidth }: { colwidth: [number] }) => {
            if (!colwidth || !colwidth[0]) {
              return { style: `max-width: calc(640px - 220px)` };
            }
            return { style: `width: ${colwidth[0]}px` };
          },
        },
        rightalign: {
          default: null,
          parseHTML: (element: any) => element.getAttribute('rightalign'),
        },
        backgroundColor: {
          default: null,
          parseHTML: (element: any) => element.getAttribute('data-background-color'),
          renderHTML: (attributes: any) => {
            return {
              'data-background-color': attributes.backgroundColor,
              style: attributes.backgroundColor ? `background-color: ${attributes.backgroundColor}` : '',
            };
          },
        },
        hidetop: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hidetop') === 'true'),
        },
        hideright: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hideright') === 'true'),
        },
        hidebottom: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hidebottom') === 'true'),
        },
        hideleft: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hideleft') === 'true'),
        },
        borderlinestyle: {
          default: 'normal',
          parseHTML: (element: any) => element.getAttribute('borderlinestyle'),
        },
        parentId: {
          default: null,
          parseHTML: (element: HTMLElement) => element.getAttribute('data-parentid'),
          renderHTML: (attributes: Record<string, any>) => {
            return {
              'data-parentid': attributes.parentId,
            };
          },
        },
        dataType: {
          default: null,
          parseHTML: (element: HTMLElement) => element.getAttribute('data-type'),
          renderHTML: (attributes: Record<string, any>) => {
            return {
              'data-type': attributes.dataType,
            };
          },
        },
        verticalAlign: {
          default: 'top',
          parseHTML: (element: HTMLElement) => element.getAttribute('data-vertical-align'),
          renderHTML: (attributes: Record<string, any>) => ({ 'data-vertical-align': attributes.verticalAlign }),
        },
      };
    },
    addCommands() {
      return {
        setBorderStyle:
          (border: TableBorderStyle) =>
          ({ commands }: { commands: any }) => {
            commands.setCellAttribute('borderlinestyle', border);
          },
        setVerticalAlign:
          (alignment: 'top' | 'middle' | 'bottom') =>
          ({ commands }: { commands: any }) => {
            commands.setCellAttribute('verticalAlign', alignment);
          },
        toggleBorder:
          (border: CellBorder) =>
          ({ commands }: { commands: any }) => {
            const attr = this.editor.getAttributes('tableCell');
            const current = attr[`hide${border}`];
            commands.setCellAttribute(`hide${border}`, !current);
          },
        setAllBorders:
          () =>
          ({ commands }: { commands: any }) => {
            ALL_BORDERS_IDS.forEach((border) => {
              commands.setCellAttribute(border, false);
            });
            ALL_BORDERS_IDS.forEach((border) => {
              commands.setCellAttribute(border, true);
            });
          },
        unsetAllBorders:
          () =>
          ({ commands }: { commands: any }) => {
            ALL_BORDERS_IDS.forEach((border) => {
              commands.setCellAttribute(border, true);
            });

            ALL_BORDERS_IDS.forEach((border) => {
              commands.setCellAttribute(border, false);
            });
          },
      };
    },
  });
