import axios from 'axios';

import type { PdfDocumentType, ServerResponse } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const getPdfDocument = async (
  id: string,
  endorsementId: string,
  _authToken: string,
  resourceName: 'templates' | 'submissions' | 'endorsements' | 'brandings',
  pdfDocumentType: PdfDocumentType,
  expectedVersion?: number,
): Promise<ServerResponse<{ url: string; documentVersion: number }>> => {
  let url = `${resourceName}-spa-al/${id}/pdf/download`;

  if (resourceName === 'endorsements') {
    url = `submissions-spa-al/${id}/${resourceName}-spa-al/${endorsementId}/pdf/download`;
  }

  if (resourceName === 'brandings') {
    url = `brandings/${id}/preview`;
  }

  url = `${url}?type=${pdfDocumentType}`;

  try {
    const networkPayload = await getRequestAuthPayload({
      method: 'get',
      url,
    });
    const response = await axios(networkPayload);

    const serverResponse: ServerResponse<{ url: string; documentVersion: number }> = response.data;
    if (expectedVersion && serverResponse?.data?.documentVersion < expectedVersion) {
      return Promise.reject('Pdf version does not match');
    }

    return serverResponse;
  } catch (err: any) {
    if (err?.response) {
      return Promise.reject(
        `Pdf download request failed with status: ${err.response?.status} and message: ${JSON.stringify(
          err.response?.data,
        )}`,
      );
    } else {
      return Promise.reject(`Pdf download request failed with message: ${err.message}`);
    }
  }
};
