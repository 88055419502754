import type { FC } from 'react';
import React from 'react';
import { tableRowClasses } from '@components/Table/components/classes';

import { ColumnsSkeleton } from './ColumnsSkeleton';

interface RowsSkeletonProps {
  length?: number;
}

export const RowsSkeleton: FC<RowsSkeletonProps> = ({ length = 1 }) => {
  return (
    <>
      {new Array(length).fill(0).map((_, idx) => (
        <tr className={tableRowClasses()} key={`table-row-skeleton-${idx}`}>
          <ColumnsSkeleton length={4} />
        </tr>
      ))}
    </>
  );
};
