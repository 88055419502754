import { DATAPOINT, HEADING } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import { SummaryDatapointId } from '@root/@types/types';

export const endorsementSummaryDatapoints: SelectMenuItem<SummaryDatapointId | 'endorsement-data'>[] = [
  {
    label: 'Endorsement data points',
    type: HEADING,
    id: 'endorsement-data',
    order: 0,
    children: [
      { label: 'Type', id: SummaryDatapointId.EndorsementType, order: 0, type: DATAPOINT },
      {
        label: 'Unique Market Reference',
        id: SummaryDatapointId.UniqueMarketReference,
        order: 1,
        type: DATAPOINT,
      },
      { label: 'Endorsement Reference', id: SummaryDatapointId.EndorsementReference, order: 2, type: DATAPOINT },
      { label: 'Effective Date', id: SummaryDatapointId.EffectiveDate, order: 2, type: DATAPOINT },
      { label: 'Expiry Date', id: SummaryDatapointId.ExpiryDate, order: 3, type: DATAPOINT },
      { label: 'Reason', id: SummaryDatapointId.EndorsementReason, order: 4, type: DATAPOINT },
      { label: 'Premium Change', id: SummaryDatapointId.PremiumChange, order: 5, type: DATAPOINT },
      {
        label: 'Premium Amount',
        id: SummaryDatapointId.PremiumAmount,
        order: 6,
        type: DATAPOINT,
      },
      {
        label: 'Premium Currency',
        id: SummaryDatapointId.PremiumCurrency,
        order: 7,
        type: DATAPOINT,
      },
      { label: 'Policyholder', id: SummaryDatapointId.Policyholders, order: 8, type: DATAPOINT },
      { label: 'Original Insured', id: SummaryDatapointId.OriginalInsured, order: 9, type: DATAPOINT },
    ],
  },
  {
    label: 'Endorsement metadata',
    type: HEADING,
    order: 1,
    id: 'endorsement-data',
    children: [
      {
        label: 'Date Created At',
        id: SummaryDatapointId.DateCreatedAt,
        order: 0,
        type: DATAPOINT,
      },
      { label: 'Time Created At', id: SummaryDatapointId.TimeCreatedAt, order: 1, type: DATAPOINT },
      { label: 'Date Updated At', id: SummaryDatapointId.DateUpdatedAt, order: 2, type: DATAPOINT },
      { label: 'Time Updated At', id: SummaryDatapointId.TimeUpdatedAt, order: 3, type: DATAPOINT },
    ],
  },
];
