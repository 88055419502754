import type { MouseEventHandler } from 'react';
import { useRef } from 'react';
import Stick from 'react-stick';
import type { StickPropsT } from 'react-stick/lib/types';
import { useClickAway } from 'react-use';
import Tooltip from '@components/Tooltip';
import { CONTEXT_MENU_Z_INDEX } from '@constants/z-indices';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { sortBy } from 'lodash-es';

import Icon from '../Icon';

import type { ContextOption } from './types';

interface ContextMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  options: ContextOption[];
  disabled?: boolean;
  dataTestId?: string;
  position?: StickPropsT['position'];
  dropdownClassNames?: string;
}

export const ContextMenu = ({
  isOpen,
  onClose,
  onOpen,
  options,
  disabled,
  dataTestId,
  position = 'top right',
  dropdownClassNames,
}: ContextMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    onClose?.();
  });

  const handleOpen: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    return onOpen?.();
  };

  return (
    <Stick
      node={
        <Menu as="div" className={clsx('relative inline-block text-left', CONTEXT_MENU_Z_INDEX)} ref={ref}>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
            show={isOpen}
          >
            <Menu.Items
              className={clsx(
                'absolute w-48 origin-top-right divide-y divide-info-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                dropdownClassNames,
              )}
            >
              {sortBy(options, 'order').map(({ icon, onClick, title, isDisabled, disabledMessage = '' }) => (
                <Menu.Item key={title} data-testid={`menu-item:${title}`}>
                  {({ active }) =>
                    isDisabled ? (
                      <Tooltip disabled={!disabledMessage} content={disabledMessage}>
                        <button
                          className={clsx(
                            'group flex w-full cursor-not-allowed items-center gap-2 rounded-md px-2 py-2 text-sm !text-info-400',
                          )}
                        >
                          <Icon name={icon} /> {title}
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          onClick?.();
                          onClose?.();
                        }}
                        className={clsx(
                          active && 'bg-primary-200',
                          'text-grey-400 group flex w-full items-center gap-2 rounded-md px-2 py-2 text-sm',
                        )}
                      >
                        <Icon name={icon} /> {title}
                      </button>
                    )
                  }
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      }
      position={position}
      autoFlipHorizontally
      autoFlipVertically
    >
      <div
        className={
          'inline-flex h-5 w-5 cursor-pointer justify-center rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
        }
        onClick={disabled ? undefined : isOpen ? onClose : handleOpen}
      >
        {!disabled && <Icon className="cursor-pointer" name="dots-vertical" data-testid={dataTestId} />}
      </div>
    </Stick>
  );
};
