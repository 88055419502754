import type { FC } from 'react';
import { Fragment, useMemo, useState } from 'react';
import Button from '@components/Button';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { useEntityStore, useSchemaStore } from '@ContractBuilder/store';
import { Dialog, Transition } from '@headlessui/react';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { ExclamationIcon, QuestionMarkCircleIcon, XIcon } from '@heroicons/react/outline';
import { BlockError, DocumentMode } from '@root/@types/types';
import { isNonNullish } from '@root/helpers';
import { getAllBlocks } from '@root/helpers/blocks';
import { useEffectOnce } from '@src/hooks';
import clsx from 'clsx';
import { isEmpty } from 'lodash-es';

import { InvalidInfo } from './InvalidInfo';
import { ValidInfo } from './ValidInfo';

interface ConfirmStatusModalProps {
  handleClose: () => void;
  onConfirm: (status: string) => Promise<void>;
  status: string;
}

const TITLE_MAP = {
  [DocumentMode.ENDORSEMENTS]: 'Ready to finalise endorsement?',
  [DocumentMode.SUBMISSIONS]: 'Ready to finalise contract?',
  [DocumentMode.TEMPLATES]: 'Ready to publish template?',
  [DocumentMode.BRANDING]: 'Ready to publish branding?',
  [DocumentMode.OTHER]: '',
};

const CONFIRM_BUTTON_TEXT_MAP = {
  [DocumentMode.ENDORSEMENTS]: 'Yes, finalise endorsement',
  [DocumentMode.SUBMISSIONS]: 'Yes, finalise contract',
  [DocumentMode.TEMPLATES]: 'Yes, publish template',
  [DocumentMode.BRANDING]: 'Yes, publish branding',
  [DocumentMode.OTHER]: '',
};

const ConfirmStatusModal: FC<ConfirmStatusModalProps> = ({ handleClose, onConfirm, status }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm(status);
    setIsLoading(false);
    handleClose();
  };

  const { submission } = useEntityStore(({ submission }) => ({ submission }));
  const { mode } = getDocumentMode();
  const { isValid, validate } = useSchemaStore(({ isValid, validate }) => ({ isValid, validate }));
  const isSubmission = mode === DocumentMode.SUBMISSIONS;

  useEffectOnce(() => {
    if (isSubmission) {
      validate();
    }
  });

  const errors = useMemo(() => {
    if (isSubmission) {
      const allBlocks = getAllBlocks(submission);
      const result = allBlocks.flatMap((block) => block.validationErrors);

      if (!isValid) {
        result.push(BlockError.DatapointEmpty);
      }

      return [...new Set(result)].filter(isNonNullish);
    }

    return [];
  }, [isSubmission, isValid, submission]);

  const isDocValid = isEmpty(errors);

  const config = {
    valid: {
      title: TITLE_MAP[mode],
      buttons: {
        cancel: true,
        confirm: {
          fn: handleConfirm,
          text: CONFIRM_BUTTON_TEXT_MAP[mode],
        },
      },
    },
    invalid: {
      title: 'There are issues with the contract',
      buttons: {
        cancel: false,
        confirm: {
          fn: handleClose,
          text: 'OK',
        },
      },
    },
  }[isDocValid ? 'valid' : 'invalid'];

  const IconComponent = isDocValid ? QuestionMarkCircleIcon : ExclamationIcon;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX)} onClose={handleClose}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block max-w-[600px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:align-middle">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-info-400 hover:text-info-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                  onClick={handleClose}
                >
                  <span className="sr-only">Cancel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex sm:items-start">
                <div
                  className={clsx(
                    'mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    isDocValid ? 'bg-success-50' : 'bg-yellow-50',
                  )}
                >
                  <IconComponent
                    className={clsx('h-6 w-6', isDocValid ? 'text-success-600' : 'text-yellow-600')}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-info-900">
                    {config.title}
                  </Dialog.Title>
                  <div className="mt-3">{isDocValid ? <ValidInfo /> : <InvalidInfo errors={errors} />}</div>
                </div>
              </div>
              <div className="mt-8 items-center justify-end gap-x-2.5 sm:mt-8 sm:flex">
                {config.buttons.cancel && (
                  <Button onClick={handleClose} className="h-9" size="sm" kind="secondary">
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={config.buttons.confirm.fn}
                  className="h-9"
                  kind="primary"
                  loading={isLoading}
                  size="sm"
                >
                  {config.buttons.confirm.text}
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmStatusModal;
