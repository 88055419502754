import { useCallback, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import type { CreateEntityFormState } from '@features/create-entity-modal/types';
import { createTemplate, uploadMrcExtractionDocument } from '@src/mutations';
import { getMrcExtractionDocumentPresignedUrl } from '@src/queries';
import Nav from '@src/routes';
import { isSubmissionPath, isTemplatePath } from '@utils/app-paths';

interface UseCreateEntityArgs {
  onClose: () => void;
}

const isFile = (data: unknown): data is File => data instanceof File;

export const useCreateEntity = ({ onClose }: UseCreateEntityArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isTemplate = isTemplatePath();
  const isSubmission = isSubmissionPath();

  const handleOpenEntity = useCallback(
    (id: string) => {
      const payload = { id };
      const newPath = isTemplate ? Nav.TemplateContract : Nav.SubmissionForm;
      const nextRoute = generatePath(newPath, payload);

      return navigate(nextRoute);
    },
    [isTemplate, navigate],
  );

  const handleCreate: SubmitHandler<CreateEntityFormState> = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        const {
          //
          branding,
          branding_id,
          copy_from_submission,
          copy_from_template,
          class_of_business,
          file,
          name,
          team,
        } = data;

        const { id } = await createTemplate(isTemplate)({
          branding,
          branding_id,
          class_of_business,
          copy_from_submission,
          copy_from_template,
          name,
          status: 'DRAFT',
          teams: team,
        });

        if (isSubmission && isFile(file)) {
          const response = await getMrcExtractionDocumentPresignedUrl(id, file.name);

          await uploadMrcExtractionDocument(response.data.data.url, {
            ...response.data.data.fields,
            file,
          });
        }

        onClose();

        return handleOpenEntity(id);
      } catch (_error) {
        // Swallow the error for now
      } finally {
        setIsLoading(false);
      }
    },
    [handleOpenEntity, isSubmission, isTemplate, onClose],
  );

  return {
    isLoading,
    onSubmit: handleCreate,
  };
};
