import type { ResourceBlock } from '@root/@types/base';
import type { BlockVisibilityConfig } from '@root/functions/validation';

export const getExistingVariationsVisibilityConfig = ({
  variations = [],
  visibility,
}: Pick<ResourceBlock, 'variations' | 'visibility'>): BlockVisibilityConfig['variations'] => {
  if (!visibility?.variations) return [];
  const existingVariants = new Set(variations.map(({ id }) => id));
  return visibility.variations?.filter((item) => item && existingVariants.has(item.id));
};
