import type { FC } from 'react';
import { leftBorderClasses } from '@ContractBuilder/modules/block/classes';

interface InteractiveBorderProps {
  isEditing: boolean;
  variant: 'header' | 'footer';
  editable?: boolean;
}

export const InteractiveBorder: FC<InteractiveBorderProps> = ({ isEditing, variant, editable }) => {
  const variantClasses = {
    header: ['!top-0'],
    footer: ['!bottom-0'],
  }[variant];

  return (
    <div
      className={leftBorderClasses({
        editing: isEditing,
        editable,
        className: [...variantClasses],
      })}
    />
  );
};
