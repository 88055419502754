import { InformationCircleIcon } from '@heroicons/react/solid';
import { isSubmissionPath } from '@utils/app-paths';

export const TempContractBannerContent = () => (
  <>
    <InformationCircleIcon className="h-5 w-5 flex-shrink-0 text-blue-400" />
    <p className="text-sm font-medium text-blue-800">
      This temporary contract is for template testing only and will be deleted upon full integration.
    </p>
  </>
);

const EntraTempContractBanner = () => {
  const isSubmission = isSubmissionPath();

  if (!isSubmission) {
    return null;
  }

  return (
    <div className="flex h-10 items-center justify-center gap-3 bg-primary-50 py-3">
      <TempContractBannerContent />
    </div>
  );
};

const getTempContractBanner = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';
  if (isEntra) {
    return EntraTempContractBanner;
  }
  return () => null;
};

const TempContractBanner = getTempContractBanner();

export default TempContractBanner;
