import { DocumentContext } from '@ContractBuilder/utils';
import type { PdfDocumentType } from '@root/@types/types';

interface GetResourceNameAndDocumentType {
  documentType: PdfDocumentType;
  resourceName: 'templates' | 'submissions' | 'endorsements' | 'brandings';
}

export const getResourceNameAndDocumentType = (documentContext: DocumentContext): GetResourceNameAndDocumentType => {
  if (documentContext === DocumentContext.Endorsement) {
    return { resourceName: 'endorsements', documentType: 'endorsement-summary' };
  }

  if (documentContext === DocumentContext.Template) {
    return { resourceName: 'templates', documentType: 'template-document' };
  }

  if (documentContext === DocumentContext.Branding) {
    return { resourceName: 'brandings', documentType: 'branding-document' };
  }

  return { resourceName: 'submissions', documentType: 'submission-document' };
};
