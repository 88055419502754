import type { FC } from 'react';
import InputCalendar from '@components/InputCalendar';
import InputCurrency from '@components/InputCurrency';
import InputSelect from '@components/InputSelect';
import InputText from '@components/InputText';
import { Show } from '@components/Show';
import { EndorsementPremiumChange } from '@root/@types/types';

import {
  ENDORSEMENT_REASON_OPTIONS,
  ENDORSEMENT_TYPE_OPTIONS,
  ENDORSMENT_PREMIUM_CHANGE_OPTIONS,
} from '../../constants';
import { type FormState, isConditionalPremiumOption } from '../types';

const getConditionalPremiumChangeInputLabel = (premium_change?: EndorsementPremiumChange) => {
  let conditionalPremiumChangeInputLabel = '';

  switch (premium_change) {
    case EndorsementPremiumChange.ADDITION:
      conditionalPremiumChangeInputLabel = 'Additional premium to collect';
      break;
    case EndorsementPremiumChange.RETURN:
      conditionalPremiumChangeInputLabel = 'Return premium to provide';
      break;
  }

  return conditionalPremiumChangeInputLabel;
};

interface EndorsementFormProps {
  setFormValue: (name: keyof FormState, value?: string | object) => void;
  state: FormState;
  isReadOnly?: boolean;
}

export const EndorsementForm: FC<EndorsementFormProps> = ({ setFormValue, state, isReadOnly = false }) => {
  return (
    <>
      <InputSelect
        isReadOnly={isReadOnly}
        isRequired
        isSearchable={false}
        labelText="Type"
        onChange={(option) => setFormValue('type', option)}
        options={ENDORSEMENT_TYPE_OPTIONS}
        value={state.type}
      />
      <InputText
        name="Reference"
        isReadOnly={isReadOnly}
        labelText="Reference"
        isRequired
        value={state.reference}
        onChange={(event) => setFormValue('reference', event.target.value)}
      />
      <InputCalendar
        isReadOnly={isReadOnly}
        value={state.effective_date}
        labelText="Effective Date"
        isRequired
        onChange={(date) => setFormValue('effective_date', date)}
      />
      <InputCalendar
        isReadOnly={isReadOnly}
        value={state.expiry_date}
        labelText="Expiry Date"
        isRequired
        onChange={(date) => setFormValue('expiry_date', date)}
      />
      <InputSelect
        isReadOnly={isReadOnly}
        isRequired
        isSearchable
        labelText="Reason"
        onChange={(option) => setFormValue('reason', option)}
        options={ENDORSEMENT_REASON_OPTIONS}
        value={state.reason}
      />
      <InputSelect
        isReadOnly={isReadOnly}
        isSearchable={false}
        labelText="Premium Change"
        onChange={(option) => setFormValue('premium_change', option)}
        options={ENDORSMENT_PREMIUM_CHANGE_OPTIONS}
        value={state.premium_change}
      />
      <Show when={isConditionalPremiumOption(state.premium_change)}>
        <InputCurrency
          isReadOnly={isReadOnly}
          labelText={getConditionalPremiumChangeInputLabel(state.premium_change)}
          isRequired
          value={state.additional_premium ?? undefined}
          onChange={(value) => {
            setFormValue('additional_premium', value);
          }}
        />
      </Show>
    </>
  );
};
