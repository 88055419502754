import React from 'react';
import type { UIChildrenMappingProps } from '@root/@types/types';
import mapChildren from '@src/mapChildren';

import DescriptionText from '../../DescriptionText';
import ErrorText from '../../ErrorText';

interface SectionBodyProps extends UIChildrenMappingProps {
  isHidden: boolean;
  item: any;
  ownKey: any;
  descriptionText: string;
  className?: string;
}

const SectionBody: React.FC<SectionBodyProps> = ({
  ctx,
  item,
  onChange,
  ownKey,
  validationErrors,
  descriptionText,
  showQuestionId,
  showQuestionKey,
  isReadOnly = false,
  isDisabled,
  hideErrors,
}) => {
  const error =
    validationErrors &&
    validationErrors.find((err) => ownKey === err.instancePath.split('.').filter(Boolean).join('.'));

  return (
    <>
      {descriptionText && <DescriptionText className="mb-8 mt-4">{descriptionText}</DescriptionText>}
      <>
        {mapChildren({
          ctx,
          onChange,
          parentKey: ownKey,
          parentSchema: item,
          validationErrors,
          showQuestionId,
          showQuestionKey,
          isReadOnly,
          isDisabled,
          hideErrors,
        })}
      </>
      {error && error.keyword === 'total100' && <ErrorText className="mb-4">{error.message}</ErrorText>}
    </>
  );
};

export default SectionBody;
