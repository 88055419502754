import type {
  RestoreBlocksHiddenFromPreview,
  SummaryBlockCreate,
  SummaryBlockDelete,
  SummaryBlockHideFromPreview,
  SummaryBlockUpdate,
  SummarySectionDelete,
} from '@domain/schemas/endorsement-summary.schema';
import { getCurrentDocumentApiUrl } from '@helpers/getCurrentDocumentApiUrl';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

type Response =
  | {
      message: 'Server Error';
    }
  | { message: 'success'; id?: string };

type PatchEndorsementSummaryData =
  | SummaryBlockUpdate
  | SummaryBlockDelete
  | SummaryBlockCreate
  | SummaryBlockHideFromPreview
  | RestoreBlocksHiddenFromPreview
  | SummarySectionDelete;

type PatchEndorsementSummary = (data: PatchEndorsementSummaryData) => Promise<Response>;

export const patchEndorsementSummary: PatchEndorsementSummary = async (data) => {
  const url = getCurrentDocumentApiUrl(location.pathname);
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `${url}/summary`,
    data,
  });

  try {
    const response = await axios(networkPayload);
    return response.data;
  } catch {
    return { message: 'Server Error' };
  }
};
