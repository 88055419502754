import { useState } from 'react';
import { useQuery } from 'react-query';
import { Popover } from '@components/Popover';
import { Show } from '@components/Show';
import { BlockUsedWithinDetails } from '@ContractBuilder/modules/block/components/BlockUsedWithin';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { mdiPaperclip } from '@mdi/js';
import { Icon } from '@mdi/react';
import { fetchBlock } from '@src/queries';

const isUsedInMoreThanOneTemplate = (usedInTemplates?: number) => (usedInTemplates ?? 0) > 1;

const ItemUsedWithin = ({ item }: { item: LibraryBlockTypeForListPage }) => {
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const { data: block } = useQuery(['block', item.id], () => fetchBlock(item.id), {
    enabled: isPopoverOpen,
  });
  const isUsedInMoreThanOne = isUsedInMoreThanOneTemplate(item?.usedInTemplatesCount);

  return (
    <Show when={item?.usedInTemplatesCount !== undefined && item.usedInTemplatesCount > 0}>
      <Popover
        renderTrigger={({ onClick }) => (
          <span className="relative inline-flex items-center rounded-full bg-info-100 px-2.5 py-0.5 text-xs font-medium text-info-800">
            <button
              onClick={(e) => {
                setPopoverOpen(true);
                onClick(e);
              }}
              className="mr-2 inline-block max-w-[140px] truncate text-primary-600 hover:underline"
            >
              {`${item.usedInTemplatesCount} Template${isUsedInMoreThanOne ? 's' : ''}`}
            </button>
            <span className="relative">
              <Icon
                className="absolute left-[-7px] top-[-6px] inline-block rotate-45 text-primary-600"
                path={mdiPaperclip}
                size={0.5}
              />
            </span>
          </span>
        )}
      >
        <BlockUsedWithinDetails
          label={`BLOCK USED IN ${isUsedInMoreThanOne ? 'THESE TEMPLATES' : 'THIS TEMPLATE'}`}
          usedInTemplates={block?.data?.usedInTemplates}
        />
      </Popover>
    </Show>
  );
};

export default ItemUsedWithin;
