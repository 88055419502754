import type { FC, MouseEventHandler } from 'react';
import type { PopoverClickEventHandler } from '@components/Popover';
import { Show, ShowFirstMatching } from '@components/Show';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import {
  ContractBlockActions,
  EndorsementViewBlockActions,
  SummaryBlockActions,
} from '@ContractBuilder/modules/block-actions/components';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import type { ResourceBlock } from '@root/@types/base';
import { BLOCK_ACTIONS_HOVERED_Z_INDEX, BLOCK_ACTIONS_Z_INDEX } from '@root/src/constants/z-indices';
import { isEndorsementViewPath } from '@utils/app-paths';
import clsx from 'clsx';

interface BlockActionsProps {
  block: ResourceBlock;
  handleDelete: MouseEventHandler;
  handleEdit: MouseEventHandler<HTMLDivElement>;
  onCommentClick: MouseEventHandler;
  onShowComments: PopoverClickEventHandler;
}

export const BlockActions: FC<BlockActionsProps> = ({
  block,
  handleDelete,
  handleEdit,
  onCommentClick,
  onShowComments,
}) => {
  const {
    details: { isSummaryBlock },
  } = useBlockFlags();

  const isInEditView = useBlockEditFormStore(({ formValues }) => formValues?.id !== undefined);
  const isEndorsementView = isEndorsementViewPath();

  return (
    <div
      className={clsx(
        'absolute right-0 top-0 translate-x-full py-1',
        isInEditView && 'hidden',
        BLOCK_ACTIONS_Z_INDEX,
        BLOCK_ACTIONS_HOVERED_Z_INDEX,
      )}
    >
      <ShowFirstMatching>
        <Show when={isSummaryBlock && isEndorsementView}>
          <SummaryBlockActions block={block} handleDelete={handleDelete} handleEdit={handleEdit} />
        </Show>
        <Show when={isEndorsementView}>
          <EndorsementViewBlockActions block={block} />
        </Show>
        <Show when={true}>
          <ContractBlockActions
            block={block}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            onShowComments={onShowComments}
            onCommentClick={onCommentClick}
          />
        </Show>
      </ShowFirstMatching>
    </div>
  );
};
