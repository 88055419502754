import type { FC, HTMLAttributes } from 'react';
import useDetectedTruncation from '@hooks/useDetectTruncation';
import type { TippyProps } from '@tippyjs/react';
import { twMerge } from 'tailwind-merge';

import Tooltip from './Tooltip';

const baseClasses = 'truncate cursor-default';
interface TooltipOnTruncatedProps extends HTMLAttributes<HTMLSpanElement> {
  tooltipProps: TippyProps;
}

export const TooltipOnTruncated: FC<TooltipOnTruncatedProps> = ({
  tooltipProps,
  children,
  className,
  ...spanProps
}) => {
  const { isTruncated, ref } = useDetectedTruncation<HTMLSpanElement>();

  return (
    <Tooltip {...tooltipProps} disabled={!isTruncated}>
      <span ref={ref} className={twMerge(baseClasses, className)} {...spanProps}>
        {children}
      </span>
    </Tooltip>
  );
};

interface TooltipOnTruncatedCellProps extends HTMLAttributes<HTMLTableCellElement> {
  tooltipProps: TippyProps;
}

export const TooltipOnTruncatedCell: FC<TooltipOnTruncatedCellProps> = ({
  tooltipProps,
  className,
  children,
  ...cellProps
}) => {
  const { isTruncated, ref } = useDetectedTruncation<HTMLTableCellElement>();

  return (
    <Tooltip {...tooltipProps} disabled={!isTruncated}>
      <td ref={ref} className={twMerge(baseClasses, className)} {...cellProps}>
        {children}
      </td>
    </Tooltip>
  );
};
