import type { FC } from 'react';
import { toast } from 'react-toastify';
import { UnsavedChangesModal } from '@ContractBuilder/modules/block-edit/views/UnsavedChangesModal';
import { useEditorsMap } from '@ContractBuilder/modules/editors-map';

import { BrandingEditorKey, useBrandingStore } from '../store/branding.store';

interface BrandingDetailsUnsavedChangesProps {
  isOpen: boolean;
  toggleOpen: (open?: boolean) => void;
}

export const BrandingDetailsUnsavedChanges: FC<BrandingDetailsUnsavedChangesProps> = ({ isOpen, toggleOpen }) => {
  const { originalBranding, restore, saveChanges } = useBrandingStore((s) => s);

  const { getEditor } = useEditorsMap();

  const disregardChanges = () => {
    restore();
    const header = getEditor(BrandingEditorKey.Header) as any;
    const footer = getEditor(BrandingEditorKey.Footer) as any;

    // resetting only in the store does not affect editors
    header?.commands.setContent(originalBranding?.header_content ?? '');
    footer?.commands.setContent(originalBranding?.footer_content ?? '');
    toggleOpen(false);
  };

  const onSaveChanges = async () => {
    const result = await saveChanges();
    if (result.success) {
      toggleOpen(false);
    } else {
      toast.error(result.error || 'There was an error saving your branding. Please try again.');
    }
  };

  return (
    <UnsavedChangesModal
      isOpen={isOpen}
      onCancel={toggleOpen}
      onDisregardChanges={disregardChanges}
      onSaveChanges={onSaveChanges}
    />
  );
};
