import { ContractBuilderBaseLoading } from './ContractBuilderBaseLoading';

export const ContractBuilderFormViewLoading = () => {
  return (
    <ContractBuilderBaseLoading>
      <div className="mx-10 mt-12 flex min-h-screen grow items-start pb-24">
        <div className="h-full w-full max-w-3xl animate-pulse p-6">
          <div className="-mt-2 mb-4 h-[24px] w-[220px] rounded-full bg-info-200 pb-4" />
          <div className="mb-5 mt-8 h-[256px] rounded-lg bg-white p-6 pb-2 shadow" />
          <div className="mb-5 h-[78px] rounded-lg bg-white p-6 pb-2 shadow" />
          <div className="mb-5 h-[78px] rounded-lg bg-white p-6 pb-2 shadow" />
          <div className="mb-5 h-[320px] rounded-lg bg-white p-6 pb-2 shadow" />
          <div className="mb-5 h-[256px] rounded-lg bg-white p-6 pb-2 shadow" />
        </div>
        <div className="ml-6 mt-12 flex min-w-[472px] flex-col gap-y-4 rounded bg-white px-8 py-4 shadow">
          <div className="mt-4 flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-1/2 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-1/2 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-1/2 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-1/2 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-1/2 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-5 w-5 rounded-full bg-info-200" />
            <div className="h-4 w-3/4 rounded-md bg-info-200" />
            <div className="ml-auto h-[20px] w-[84px] rounded-full bg-info-200"></div>
          </div>
          <div className="mr-4 flex justify-between border-t border-info-300 pb-4 pt-6">
            <div className="h-[40px] w-[126px] rounded-md bg-info-200"></div>
          </div>
        </div>
      </div>
    </ContractBuilderBaseLoading>
  );
};
