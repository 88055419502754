import React from 'react';
import Button from '@components/Button';
import { Chip } from '@components/Chip';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { DEFAULT_CREATOR_PADDING } from '@ContractBuilder/modules/block-creator/constants';
import { useBlockCreator } from '@ContractBuilder/modules/block-creator/context';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { mdiCalendar, mdiLinkVariant, mdiMapMarker, mdiPaletteSwatchVariant } from '@mdi/js';
import { useLanguage } from '@src/language';
import type { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import pluralize from 'pluralize';

import type { LibraryBlockType, LibraryBlockTypeForListPage } from '../../../types';
import { BlockPreview } from '../views/BlockPreview';

interface LibraryBlockListItemProps {
  block: LibraryBlockTypeForListPage;
  onBlockInsert: () => void;
  onSummary?: (block: LibraryBlockType) => unknown;
  tableRow: Row<LibraryBlockTypeForListPage>;
}

const createMarkup = (html: string) => ({ __html: sanitizeHtml(html) });

const footerEntryClasses = 'flex items-center gap-1';

export const LibraryBlock = ({ block, tableRow, onBlockInsert, onSummary }: LibraryBlockListItemProps) => {
  const { blockLibraryId, setBlockLibraryId } = useBlockCreator();
  const {
    clause_type,
    created_at,
    linkedDatapoints,
    name,
    title,
    type,
    updated_at,
    usedInTemplates,
    variationsCount = 0,
  } = block;

  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const datapointsCount = linkedDatapoints?.length ?? 0;
  const usedInTemplatesCount = usedInTemplates?.length ?? 0;
  const isInPreview = blockLibraryId === block.id;

  const handlePreviewClick = () => setBlockLibraryId(isInPreview ? undefined : block.id);
  const typeName = getContent(type);

  const isClause = type === 'clause' && !!clause_type;

  return (
    <div className="flex h-fit max-h-full flex-col">
      <div
        className={clsx(
          'relative flex h-full max-h-[75px] w-full cursor-default flex-col justify-evenly py-4 hover:bg-primary-50',
          DEFAULT_CREATOR_PADDING,
          tableRow.depth > 0 && `!pl-12`,
        )}
        id={block.id}
      >
        <div className="flex w-full items-start justify-between gap-3">
          <Show when={tableRow.getCanExpand()}>
            <div className="flex-shrink-0 cursor-pointer">
              <Show when={tableRow.getIsExpanded()}>
                <ChevronDownIcon
                  onClick={tableRow.getToggleExpandedHandler()}
                  className="h-4 w-4 text-info-600 transition-colors focus:outline-none"
                />
              </Show>
              <Show when={!tableRow.getIsExpanded()}>
                <ChevronRightIcon
                  onClick={tableRow.getToggleExpandedHandler()}
                  className="h-4 w-4 text-info-600 transition-colors focus:outline-none"
                />
              </Show>
            </div>
          </Show>
          <div
            className="line-clamp-2 max-w-[33%] flex-shrink text-ellipsis text-sm font-semibold"
            dangerouslySetInnerHTML={createMarkup(name ?? title ?? block.id.split('-')[0])}
          />

          <div className="flex flex-grow items-center gap-2 whitespace-nowrap">
            <Chip data-testid={typeName} dot>
              {typeName}
            </Chip>
            <Show when={isClause}>
              <Chip data-testid={clause_type} dot>
                {clause_type}
              </Chip>
            </Show>
          </div>

          <div className="flex items-center gap-2">
            <Show when={!!onSummary}>
              <Button className="h-8 px-2" onClick={() => onSummary?.(block)}>
                Summary
              </Button>
            </Show>
            <Button className="h-8 px-2" onClick={handlePreviewClick}>
              {isInPreview ? 'Close' : 'Preview'}
            </Button>
            <Button className="h-8 px-2" kind="primary" onClick={onBlockInsert}>
              Insert
            </Button>
          </div>
        </div>

        <div id="block-library-footer" className="flex items-center gap-3 text-xs text-info-400">
          <div className={footerEntryClasses}>
            <IconMdi path={mdiCalendar} size={0.6} />
            Updated {dayjs(updated_at ?? created_at).format('DD MMM YYYY')}
          </div>
          <Show when={!!datapointsCount}>
            <div className={footerEntryClasses}>
              <IconMdi path={mdiMapMarker} size={0.6} />
              {datapointsCount} Data {pluralize('point', datapointsCount)}
            </div>
          </Show>
          <Show when={!!variationsCount}>
            <div className={footerEntryClasses}>
              <IconMdi path={mdiPaletteSwatchVariant} size={0.6} /> {variationsCount}&nbsp;
              {pluralize('Variation', variationsCount)}
            </div>
          </Show>
          <Show when={!!usedInTemplatesCount}>
            <div className={footerEntryClasses}>
              <IconMdi path={mdiLinkVariant} size={0.6} /> Used in {usedInTemplatesCount}&nbsp;
              {pluralize('template', usedInTemplatesCount)}
            </div>
          </Show>
        </div>
        <div className="absolute bottom-0 left-5 right-5 h-[1px] bg-info-200" />
      </div>
      <Show when={isInPreview}>
        <div className="h-full overflow-y-auto">
          <BlockPreview id={blockLibraryId!} />
        </div>
      </Show>
    </div>
  );
};
