import type { ResolvedSchemaTree } from '@root/helpers/schema';
import type { ConfigParams } from '@root/helpers/schema/repeaters/types';
import { isContentRepeater } from '@root/helpers/schema/resolveSchemaTreeFromForm';

export const getDataType = (item: ResolvedSchemaTree): ConfigParams['data-type'] => {
  if (isContentRepeater(item.type)) {
    return 'content-repeater-group';
  }

  return 'section-repeater';
};

export const getDataTypeFromComponent = (type: string | undefined): ConfigParams['data-type'] => {
  if (isContentRepeater(type)) {
    return 'content-repeater-group';
  }

  return 'section-repeater';
};
