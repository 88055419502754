import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import { InputField } from '@components/InputField';
import InputSelect from '@components/InputSelect';
import { Show } from '@components/Show';
import { RadioGroup } from '@headlessui/react';
import { useFeatureFlags } from '@src/hooks';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

import { useBrandingOptions } from '../../hooks';
import type { StepProps } from '../../types';

const checkboxDotClasses = cva('mt-0.5 h-4 w-4 rounded-full', {
  variants: {
    isChecked: {
      true: 'border-[5px] border-blue-600',
      false: 'border border-info-300',
    },
  },
});

const radioOptionClasses = cva('transition flex cursor-pointer p-4 gap-3', {
  variants: {
    isChecked: {
      true: 'bg-blue-100 border-blue-200',
      false: 'border-info-200',
    },
  },
});

const descriptionTextClasses = cva('text-sm leading-5 font-normal', {
  variants: {
    isChecked: {
      true: 'text-blue-700',
      false: 'text-info-500',
    },
  },
});

export const Step3: FC<StepProps> = ({ control, formValues }) => {
  const hasDataExtractionFeature = useFeatureFlags()('MrcExtraction');
  const { brandingFieldName, data, isLoading } = useBrandingOptions();

  return (
    <div className="flex flex-col gap-6 pb-4">
      <Controller
        control={control}
        name={brandingFieldName}
        render={({ field }) => (
          <InputSelect
            {...field}
            isClearable
            isDisabled={field.disabled}
            isLoading={isLoading}
            labelText="Submission branding"
            options={data}
            placeholder="Select branding"
            value={formValues?.[brandingFieldName]}
          />
        )}
      />
      <Show when={hasDataExtractionFeature}>
        <Controller
          control={control}
          name="dataType"
          render={({ field }) => (
            <InputField labelText="Select data type">
              <RadioGroup {...field} aria-label="Select data type" className="rounded-lg shadow-sm transition">
                <RadioGroup.Option value="blank">
                  {({ checked: isChecked }) => (
                    <div className={clsx(radioOptionClasses({ isChecked }), 'rounded-t-lg border-x border-t')}>
                      <div className={checkboxDotClasses({ isChecked })} />
                      <div className="flex flex-col gap-1">
                        <span className="text-sm font-medium leading-5 text-gray-900">Create a new submission</span>
                        <span className={descriptionTextClasses({ isChecked })}>
                          A blank contract ready to populate
                        </span>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>

                <div className={clsx('h-[1px] w-full', field.value ? 'bg-blue-200' : 'bg-info-200')} />

                <RadioGroup.Option value="pdf">
                  {({ checked: isChecked }) => (
                    <div className={clsx(radioOptionClasses({ isChecked }), 'rounded-b-lg border-x border-b')}>
                      <div className={checkboxDotClasses({ isChecked })} />
                      <div className="flex flex-col gap-1">
                        <span className="text-sm font-medium leading-5 text-gray-900">
                          Extract data from an existing MRC
                        </span>
                        <span className={descriptionTextClasses({ isChecked })}>Populate the contract with data</span>
                      </div>
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            </InputField>
          )}
        />
      </Show>
    </div>
  );
};
