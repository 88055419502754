import React from 'react';
import { Show } from '@components/Show';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import type { HeaderGroup } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import clsx from 'clsx';

const defaultHeadingCellClassName = 'w-42 truncate px-3 py-3.5 text-left text-sm font-normal text-info-500';

export const TableHeader = <TData,>({ tableHeaderGroups }: { tableHeaderGroups: HeaderGroup<TData>[] }) => (
  <thead className="sticky top-0 z-10 bg-info-50">
    {tableHeaderGroups.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((header) => (
          <>
            <Show when={header.column.getCanSort()}>
              <th
                key={header.id}
                colSpan={header.colSpan}
                scope="col"
                className={clsx(
                  defaultHeadingCellClassName,
                  '!px-0 !py-0',
                  header.column.columnDef?.meta?.headingCellClassName,
                )}
              >
                <a
                  href="#"
                  className={clsx(
                    'group inline-flex w-full px-3 py-3.5',
                    header.column.columnDef.meta?.headingLinkClassName,
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <span className="truncate">{flexRender(header.column.columnDef.header, header.getContext())}</span>
                  <span
                    className={clsx(
                      header.column.getIsSorted()
                        ? 'ml-2 flex-none rounded bg-info-200 text-info-900 group-hover:bg-info-300'
                        : 'invisible ml-2 flex-none rounded bg-info-200 text-info-400 group-hover:visible group-focus:visible',
                    )}
                  >
                    {header.column.getIsSorted() && header.column.getNextSortingOrder() === 'asc' && (
                      <ChevronDownIcon className="h-5 w-5" />
                    )}
                    {header.column.getIsSorted() && header.column.getNextSortingOrder() === 'desc' && (
                      <ChevronUpIcon className="h-5 w-5" />
                    )}
                  </span>
                </a>
              </th>
            </Show>
            <Show when={!header.column.getCanSort()}>
              <th
                key={header.column.columnDef.id}
                scope="col"
                className={clsx(defaultHeadingCellClassName, header.column.columnDef?.meta?.headingCellClassName)}
              >
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            </Show>
          </>
        ))}
      </tr>
    ))}
  </thead>
);
