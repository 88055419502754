import React from 'react';
import { useEntityStore } from '@ContractBuilder/store';
import type { EndorsementData, EntityData } from '@ContractBuilder/types';
import { isEndorsementData } from '@ContractBuilder/types';
import { isSummarySection } from '@root/helpers';

import { RestoreEndorsementTemplateBanner } from '../views/RestoreEndorsementTemplateBanner';

const hasSummarySections = (entity?: EntityData | EndorsementData) =>
  isEndorsementData(entity) && entity.summary.sections.some((section) => isSummarySection(section));

export const RestoreEndorsementTemplateBannerController = () => {
  const { isLoading, restoreEndorsementSummaryTemplate, submission, status } = useEntityStore(
    ({ isLoading, restoreEndorsementSummaryTemplate, submission }) => ({
      isLoading,
      restoreEndorsementSummaryTemplate,
      submission,
      status: submission?.status,
    }),
  );

  if (hasSummarySections(submission) || status !== 'DRAFT') {
    return null;
  }

  return <RestoreEndorsementTemplateBanner isLoading={isLoading} onRestore={restoreEndorsementSummaryTemplate} />;
};
