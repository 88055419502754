import { useBlockActions } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { isEndorsementData } from '@ContractBuilder/types';
import { get, isEmpty } from 'lodash-es';

import { NoChangesBanner } from '../views/NoChangesBanner';
import { RestoreChangesBanner } from '../views/RestoreChangesBanner';

export const EndorsementChangesBannerController = () => {
  const { isLoading, submission } = useEntityStore(({ isLoading, submission }) => ({ isLoading, submission }));
  const { restoreAllBlocksHiddenFromPreview } = useBlockActions();

  if (!isEndorsementData(submission)) {
    return null;
  }

  const excludedBlockIds = get(submission, 'summary.blocksExcludedFromPreview', [] as string[]);

  if (isEmpty(excludedBlockIds)) {
    return <NoChangesBanner />;
  }

  return (
    <RestoreChangesBanner
      isLoading={isLoading}
      onRestore={restoreAllBlocksHiddenFromPreview}
      status={submission?.status}
    />
  );
};
