import type { FC } from 'react';
import { TooltipedIcon } from '@components/TooltipedIcon';
import { useBlockActions } from '@ContractBuilder/modules/block-edit';
import { useEntityStore } from '@ContractBuilder/store';
import { mdiEyeOffOutline } from '@mdi/js';
import type { ResourceBlock } from '@root/@types/base';
import { isExcludedFromEndorsementPreview } from '@root/helpers/blocks';

import { iconClasses } from '../classes';
import { getId } from '../utils';

import { Container } from './Container';

interface EndorsementViewBlockActionsProps {
  block: ResourceBlock;
}

export const EndorsementViewBlockActions: FC<EndorsementViewBlockActionsProps> = ({ block }) => {
  const { name } = block;

  const submission = useEntityStore(({ submission }) => submission);
  const isHiddenFromPDFPreview = isExcludedFromEndorsementPreview(block, submission);

  const { hideBlockFromPreview } = useBlockActions();
  const isDisabled = isExcludedFromEndorsementPreview(block);

  const handleHideBlockFromPreview = async () => {
    await hideBlockFromPreview({ blockId: block.id });
  };

  if (isHiddenFromPDFPreview) {
    return null;
  }

  return (
    <Container>
      <TooltipedIcon
        size={0.9}
        className={iconClasses({ isDisabled })}
        data-testid={getId('hide-change', name)}
        path={mdiEyeOffOutline}
        onClick={handleHideBlockFromPreview}
        content="Hide change"
      />
    </Container>
  );
};
