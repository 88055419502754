import type { FC, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

import { blockViewBodyClasses } from '../classes';

interface BlockBodyProps {
  className?: string;
  disableMentionHighlight?: boolean;
  id?: string;
  isEditing?: boolean;
  isHighlighted?: boolean;
  isInCreateMode?: boolean;
  isLockedFromEditing?: boolean;
  isPDFRender?: boolean;
  isVisible?: boolean;
  name?: string;
  order?: number;
  showEndorsementDiff?: boolean;
}

export const BlockBody: FC<PropsWithChildren<BlockBodyProps>> = ({
  children,
  className,
  disableMentionHighlight = false,
  id,
  isEditing,
  isHighlighted,
  isInCreateMode,
  isLockedFromEditing,
  isPDFRender,
  isVisible,
  name,
  order,
  showEndorsementDiff,
}) => {
  return (
    <div
      className={twMerge(
        blockViewBodyClasses({
          pdf: isPDFRender,
          editing: isEditing,
          highlighted: isHighlighted,
          disabled: isInCreateMode,
          conditionallyNotVisible: !isVisible && !showEndorsementDiff && !isPDFRender,
          lockedFromEditing: isLockedFromEditing,
          disableMentionHighlight,
          className,
        }),
      )}
      data-testid={`block:${name}`}
      data-order={order}
      data-editing={isEditing}
      id={id}
    >
      {children}
    </div>
  );
};
