import type { FC } from 'react';
import IconMdi from '@components/IconMdi';
import { mdiPencil } from '@mdi/js';
import { cva } from 'class-variance-authority';

interface EditorBadgeProps {
  isEditing: boolean;
  editable: boolean;
  variant: 'header' | 'footer';
  onClick?: () => void;
}

const editorBadgeClasses = cva(
  [
    'absolute',
    'left-0',
    'z-50',
    'text-xs',
    'font-medium',
    'uppercase',
    'flex',
    'items-center',
    'gap-1',
    'text-info-500',
  ],
  {
    variants: {
      isEditing: {
        true: ['text-primary-500'],
        false: ['group-hover:text-primary-500'],
      },
      variant: {
        header: ['top-[-25px]'],
        footer: ['bottom-[-25px]'],
      },
      editable: {
        true: [],
        false: ['pointer-events-none group-hover:text-info-500'],
      },
    },
    compoundVariants: [
      {
        editable: true,
        isEditing: false,
        class: 'cursor-pointer',
      },
    ],
  },
);

export const EditorBadge: FC<EditorBadgeProps> = ({ isEditing, variant, onClick, editable }) => {
  return (
    <div className={editorBadgeClasses({ isEditing, variant, editable })} onClick={onClick}>
      {variant} <IconMdi path={mdiPencil} size={0.5} />
    </div>
  );
};
