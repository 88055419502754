import type { ChangeEvent, FC, ReactNode } from 'react';
import { AnimateHeight } from '@components/AnimateHeight';
import Button from '@components/Button';
import InputCheckbox from '@components/InputCheckbox';
import type { PopoverProps } from '@components/Popover';
import { Popover } from '@components/Popover';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import type { BlockType, ClauseType } from '@root/@types/types';
import { useLanguage } from '@src/language';
import type { ColumnFilter, Table } from '@tanstack/react-table';
import { BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING } from '@User/Blocks/constants';

interface SearchFiltersDropdownProps {
  filters: ColumnFilter[];
  table: Table<LibraryBlockTypeForListPage>;
  renderTrigger: PopoverProps['renderTrigger'];
  isFetching: boolean;
}

const FilterTitle = ({ children }: { children: ReactNode }) => (
  <p className=" mb-0 mt-3 font-semibold uppercase leading-[18px] text-info-500 first:mt-1">{children}</p>
);

export const SearchFiltersDropdown: FC<SearchFiltersDropdownProps> = ({
  filters: currentFiltersState,
  renderTrigger,
  isFetching,
  table,
}) => {
  const { getContent } = useLanguage({ prefix: 'naming.mrc.block.layout' });

  const handleBlockTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = 'type';
    const isChecked = event.target.checked;
    const name = BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === event.target.name)?.label ?? '';
    const blockTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === type);
    const currentFilter = currentFiltersState?.find(({ id }) => id === type) ?? { id: type, value: [] };
    blockTypeColumn?.setFilterValue(
      isChecked
        ? [...(currentFilter.value as string[]), name]
        : (currentFilter.value as string[]).filter((i) => i !== name),
    );
    if (!isChecked && name === 'Clause') {
      const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'clause_type');
      clauseTypeColumn?.setFilterValue([]);
    }
  };

  const handleClauseTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const type = 'clause_type';
    const isChecked = event.target.checked;
    const name = event.target.name ?? '';
    const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === type);
    const currentFilter = currentFiltersState?.find(({ id }) => id === type) ?? { id: type, value: [] };
    clauseTypeColumn?.setFilterValue(
      isChecked
        ? [...(currentFilter.value as string[]), name]
        : (currentFilter.value as string[]).filter((i) => i !== name),
    );
  };

  const handleResetFiltersClick = (onClose: () => void) => () => {
    onClose();
    const blockTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'type');
    blockTypeColumn?.setFilterValue([]);
    const clauseTypeColumn = table.getAllColumns().find(({ id }: { id: string }) => id === 'clause_type');
    clauseTypeColumn?.setFilterValue([]);
  };

  const getBlockTypeInputProps = (value: BlockType) => {
    const label = BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === value)?.label ?? '';
    return {
      isChecked: (currentFiltersState?.find(({ id }) => id === 'type')?.value as string[])?.includes(label),
      name: value,
      onChange: handleBlockTypeChange,
    };
  };

  const getClauseTypeInputProps = (value: ClauseType) => {
    return {
      isChecked: (currentFiltersState?.find(({ id }) => id === 'clause_type')?.value as string[])?.includes(value),
      name: value,
      onChange: handleClauseTypeChange,
    };
  };

  const clauseProps = getBlockTypeInputProps('clause');

  return (
    <Popover
      containerClassName="relative flex flex-col bg-white grow w-[270px] rounded-lg"
      isMinimal
      middleware={[]}
      offset={2}
      renderTrigger={renderTrigger}
      shouldRenderInsidePortal
      wrapperClassName="ring-1 ring-black ring-opacity-5 bg-white shadow-lg w-[270px] flex rounded-lg"
    >
      {({ onClose }) => (
        <>
          <div className="flex flex-col gap-1.5 rounded-lg rounded-b-none bg-white p-4 text-sm">
            <FilterTitle>Block Type</FilterTitle>
            <InputCheckbox {...getBlockTypeInputProps('generic')} labelText={getContent('generic')} />
            <InputCheckbox
              {...getBlockTypeInputProps('mrc-heading')}
              id="generic"
              labelText={getContent('mrc-heading')}
            />
            <InputCheckbox {...clauseProps} labelText={getContent('clause')} />
            <AnimateHeight isVisible={clauseProps.isChecked}>
              <div className="ml-4 flex flex-col gap-1.5">
                <InputCheckbox {...getClauseTypeInputProps('Condition')} labelText="Condition" />
                <InputCheckbox {...getClauseTypeInputProps('Endorsement')} labelText="Endorsement" />
                <InputCheckbox {...getClauseTypeInputProps('Exclusion')} labelText="Exclusion" />
                <InputCheckbox {...getClauseTypeInputProps('Standard Warranties')} labelText="Standard Warranties" />
                <InputCheckbox {...getClauseTypeInputProps('Express Warranties')} labelText="Express Warranties" />
                <InputCheckbox {...getClauseTypeInputProps('Wording')} labelText="Wording" />
              </div>
            </AnimateHeight>
          </div>
          <div className="flex justify-end gap-4 rounded-lg rounded-t-none bg-info-50 p-4">
            <Button
              className="h-8 px-2"
              kind="secondary"
              onClick={handleResetFiltersClick(onClose)}
              isDisabled={isFetching}
              size="sm"
            >
              Reset
            </Button>
          </div>
        </>
      )}
    </Popover>
  );
};
