import { Fragment, useState } from 'react';
import DropdownChevron from '@components/DropdownChevron';
import IconMdi from '@components/IconMdi';
import { PillBadge } from '@ContractBuilder/components/PillBadge';
import { mdiPlusCircleOutline } from '@mdi/js';
import { doesIncludeSubstring } from '@root/helpers';
import { indentationClasses } from '@WysiwygEditor/components/DatapointInserter/classes';
import { sectionAnimationProps } from '@WysiwygEditor/components/DatapointInserter/constants';
import { RenderItemInner } from '@WysiwygEditor/components/InsertMenu/RenderItemInner';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { HEADING } from './constants';

const ID_PREFIX = 'insert-menu-expanded-list-item-';

const titleClasses = cva(
  [
    //
    'cursor-pointer',
    'h-9',
    'py-2',
    'pr-4',
    'leading-tight',
    'truncate',
    'text-gray-900',
    'bg-gray-50',
    'hover:bg-primary-50',
    'border-b',
    'border-gray-200',
    'flex',
    'items-center',
    'group',
    'text-sm',
    'overflow-x-hidden',
    'w-full',
  ],
  {
    variants: {
      level: {
        0: ['font-bold'],
      },
    },
  },
);

export type SelectMenuItem<TId = any, PayloadType = any> = {
  id: TId;
  type: string;
  order: number;
  label: string;
  badgeLabel?: string;
  disableInsert?: boolean;
  children?: SelectMenuItem<TId>[];
  replacementClasses?: string;
  payload?: PayloadType;
};

export type TreeViewProps = {
  items: SelectMenuItem[];
  searchText?: string;
  level?: number;
  disableMotion?: boolean;
  expandFirstLevel?: boolean;
  showAllChildren?: boolean;
  maxTextWidth?: number;
  onItemClick?: (item: SelectMenuItem) => void;
  getAdditionalItemProps?: (item: SelectMenuItem) => Record<string, any>;
  disableEntries: (item: SelectMenuItem) => boolean;
};

const doesIncludeSubstringRecursively = (item: SelectMenuItem, searchText: string): boolean => {
  if (doesIncludeSubstring(item.label, searchText)) return true;
  if (item.children) return item.children.some((child) => doesIncludeSubstringRecursively(child, searchText));
  return false;
};

export const TreeView = (props: TreeViewProps) => {
  const {
    items,
    searchText = '',
    level = 0,
    disableMotion,
    expandFirstLevel = false,
    showAllChildren = false,
    maxTextWidth = 380,
    onItemClick,
    getAdditionalItemProps,
    disableEntries,
  } = props;
  const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>(
    expandFirstLevel ? items?.map((item) => item.id).reduce((a, v) => ({ ...a, [v]: true }), {}) ?? {} : {},
  );
  const isAlwaysOpen = disableMotion;

  const onExpandToggle = (id: string) => {
    setIsExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const entries = items.sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity));
  const filteredEntries = entries.filter(
    (entry) => showAllChildren || doesIncludeSubstringRecursively(entry, searchText),
  );

  return (
    <>
      {filteredEntries.map((entry) => {
        const shouldDisableEntry = disableEntries(entry);

        if (entry.children?.length) {
          const childElements = (
            <TreeView
              items={entry.children}
              onItemClick={onItemClick}
              searchText={searchText}
              level={level + 1}
              disableMotion={disableMotion}
              maxTextWidth={maxTextWidth}
              getAdditionalItemProps={getAdditionalItemProps}
              showAllChildren={showAllChildren || doesIncludeSubstring(entry.label, searchText)}
              disableEntries={disableEntries}
            />
          );

          if (entry.type === HEADING) {
            return (
              <div className="datapoints-inserter-expanded" key={entry.id}>
                <p className="border-b border-info-200 bg-info-50 px-3 py-2 text-xs font-semibold uppercase leading-[15px] text-info-500">
                  {entry.label}
                </p>
                {childElements}
              </div>
            );
          }

          const entryElement = (
            <Fragment key={entry.label}>
              <button
                className={titleClasses({ level: level as any })}
                title={entry.label}
                onClick={() => onExpandToggle(entry.id)}
                data-testid="toggle-expanded"
              >
                <div
                  className={indentationClasses({
                    level: level as any,
                    className: ['flex', 'items-center', 'gap-2'],
                  })}
                  style={{ width: maxTextWidth, maxWidth: maxTextWidth }}
                >
                  {!isAlwaysOpen && (
                    <DropdownChevron
                      open={!isExpanded[entry.id]}
                      direction="right"
                      className="inline-flex shrink-0 text-info-600"
                    />
                  )}
                  <h4
                    data-testid={entry.label}
                    className={clsx('text-nowrap overflow-hidden truncate', entry.badgeLabel ? '' : 'max-w-[480px]')}
                  >
                    {entry.label}
                  </h4>

                  {entry.badgeLabel && (
                    <div className="text-nowrap ml-auto flex gap-1">
                      {onItemClick && (
                        <PillBadge
                          content={entry.badgeLabel}
                          className={clsx(
                            '!text-xs font-normal',
                            shouldDisableEntry ? ' bg-slate-200 text-slate-400' : '',
                          )}
                        />
                      )}
                      {!entry.disableInsert && onItemClick && (
                        <IconMdi
                          data-testid="insert-section-repeater-button"
                          path={mdiPlusCircleOutline}
                          className={clsx(
                            'micro-interactions',
                            shouldDisableEntry
                              ? 'pointer-events-none text-info-400'
                              : 'text-info-500 hover:text-primary-500',
                          )}
                          onClick={shouldDisableEntry ? undefined : () => onItemClick?.(entry)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </button>
              <>
                {disableMotion || isAlwaysOpen ? (
                  childElements
                ) : (
                  <AnimatePresence>
                    {isExpanded[entry.id] && <motion.div {...sectionAnimationProps}>{childElements}</motion.div>}
                  </AnimatePresence>
                )}
              </>
            </Fragment>
          );

          if (level > 0) return entryElement;

          return (
            <div className="datapoints-inserter-expanded" key={entry.id}>
              {entryElement}
            </div>
          );
        }

        return (
          <Fragment key={entry.label}>
            <RenderItemInner
              id={`${ID_PREFIX}${entry.id}`}
              onClick={!entry.disableInsert ? () => onItemClick?.(entry) : undefined}
              value={entry.id}
              label={entry.label}
              level={level}
              data-testid={entry.label}
              replacementClasses={entry.replacementClasses}
              isConditional={false}
              showPlusSign={!entry.disableInsert && !!onItemClick}
              {...getAdditionalItemProps?.(entry)}
            />
          </Fragment>
        );
      })}
    </>
  );
};
