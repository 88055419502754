import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useUserStore } from '@Auth/store';
import { BlockEditProvider } from '@ContractBuilder/modules/block-edit';
import { BlockEditModalController } from '@ContractBuilder/modules/block-edit-modal';
import { EditorsMapProvider } from '@ContractBuilder/modules/editors-map';
import { useEntityStore } from '@ContractBuilder/store';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { groupByParent } from '@helpers/groupBlocksByParent';
import { ListTypesToggle } from '@pages/User/components/ListTypesToggle';
import { getBlocksTableColumns } from '@pages/User/helpers/columns/getBlocksTableColumns';
import type { Action } from '@root/@types/types';
import { useEffectOnce } from '@src/hooks';
import { fetchBlocks } from '@src/queries';

import withMenuLayout from '../../layout/withMenuLayout';

import { ReactTable } from './components';

interface ModalState {
  blockId?: string;
  mode: 'create' | 'edit';
}

export const Blocks: React.FC = () => {
  const [isGroupByParent, setIsGroupByParent] = useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const { resetState } = useEntityStore(({ resetState }) => ({ resetState }));
  const { user } = useUserStore(({ user }) => ({ user }));

  const { data, status } = useQuery(['blocks', lastUpdatedAt], () => fetchBlocks(''), {
    refetchOnWindowFocus: false,
  });

  const [modalState, setModalState] = useState<ModalState | null>(null);

  const handleCreateBlockClick = () => {
    setModalState({ mode: 'create' });
  };

  const handleEditBlockClick = (blockId: string) => async () => {
    try {
      setModalState({ mode: 'edit', blockId });
    } catch {
      /* empty */
    }
  };
  const handleClose = () => setModalState(null);

  useEffectOnce(() => {
    resetState();
  });

  const columns = getBlocksTableColumns({ handleEditBlockClick, user });

  const actions: Action[] = [
    {
      key: 'create_block',
      label: 'New Block',
      onClick: handleCreateBlockClick,
    },
  ];

  const dataGroupedByParents = useMemo(() => groupByParent(data), [data]);

  return (
    <EditorsMapProvider>
      <ReactTable
        actions={actions}
        columns={columns}
        columnVisibility={{
          id: false,
          title: false,
          deleted_at: false,
        }}
        enableExpanding={isGroupByParent}
        entitiesLabel="blocks"
        filteredData={data as LibraryBlockTypeForListPage[]}
        expandableData={dataGroupedByParents ?? []}
        initialFilters={[{ id: 'deleted_at', value: false }]}
        initialSorting={[{ id: 'created_at', desc: true }]}
        status={status}
        renderCustomElements={() => (
          <ListTypesToggle isGroupByParent={isGroupByParent} setIsGroupByParent={setIsGroupByParent} />
        )}
      />
      <BlockEditProvider>
        <BlockEditModalController
          blockId={modalState?.blockId}
          open={!!modalState}
          handleClose={handleClose}
          onAfterSubmit={() => setLastUpdatedAt(new Date().toISOString())}
        />
      </BlockEditProvider>
    </EditorsMapProvider>
  );
};

export const UserBlocksPage = withMenuLayout(Blocks);
