import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { groupBy } from 'lodash-es';

export const groupByParent = (blocksForListPage: LibraryBlockTypeForListPage[] = []): LibraryBlockTypeForListPage[] => {
  if (!Array.isArray(blocksForListPage)) {
    return [];
  }
  const childBlocks = groupBy(
    blocksForListPage.filter((block) => !!block.parentBlockId),
    'parentBlockId',
  );
  const rootBlocks = blocksForListPage.filter((block) => !block.parentBlockId);

  return rootBlocks.map((block) => ({
    ...block,
    subRows: childBlocks[block.id] ?? [],
  })) as unknown as LibraryBlockTypeForListPage[];
};
