import { memo } from 'react';
import { useBlockFlags } from '@ContractBuilder/modules/block/context/context';
import { BlockContentDeleted } from '@ContractBuilder/modules/block-content/views/BlockContentDeleted';
import { InlineBlockEditController } from '@ContractBuilder/modules/inline-block-edit';
import { VariationsSelectorLite } from '@ContractBuilder/modules/variations-tabs/controller/VariationsSelectorLite';
import { useVariationsTabsStore } from '@ContractBuilder/modules/variations-tabs/store';
import { ctxIsPDF } from '@ContractBuilder/rules/block/is-ctx';
import { useEffectOnce } from '@src/hooks';
import { isEndorsementViewPath } from '@utils/app-paths';

import { BlockContent } from '../views/BlockContent';

const BlockContentControllerInner = () => {
  const {
    block,
    isHighlighted,
    context,
    details: {
      isVisible,
      isEditing,
      isEditable,
      variations: { count },
    },
  } = useBlockFlags();

  const isPDFRender = ctxIsPDF(context);

  const isEndorsementView = isEndorsementViewPath();
  const { setBlockSelectedVariationIdx } = useVariationsTabsStore(({ setBlockSelectedVariationIdx }) => ({
    setBlockSelectedVariationIdx,
  }));

  useEffectOnce(() => {
    if (block.variations?.length) {
      setBlockSelectedVariationIdx(block);
    }
  });

  if (isEditing) {
    return <InlineBlockEditController />;
  }

  if (!isEndorsementView && !isPDFRender && isVisible && count) {
    return <VariationsSelectorLite block={block} />;
  }

  if ((isEndorsementView || isPDFRender) && block?.isDeleted) {
    return <BlockContentDeleted block={block} isHighlighted={isHighlighted} isPdfRender={isPDFRender} />;
  }

  return (
    <BlockContent
      block={block}
      isEditable={isEditable}
      isEditing={isEditing}
      isHighlighted={isHighlighted}
      isInCreateMode={isEditing && !block?.id}
      isPdfRender={isPDFRender}
      isVisible={isVisible}
      showEndorsementDiff={isEndorsementView}
    />
  );
};

export const BlockContentController = memo(BlockContentControllerInner);
