import EntraSupportIconButton from './EntraSupportIconButton';

const getSupportIconButton = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return EntraSupportIconButton;
  }

  return () => {
    return null;
  };
};

const SupportIconButton = getSupportIconButton();
export default SupportIconButton;
