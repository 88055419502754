import { cva } from 'class-variance-authority';

export const paginationItemContainerClasses = cva(
  ['relative inline-flex cursor-pointer items-center border px-4 py-2 text-sm font-medium'],
  {
    variants: {
      isCurrentPage: {
        true: 'z-10 border-indigo-500 bg-indigo-50 text-indigo-600',
        false: 'border-info-300 bg-white text-info-500 hover:bg-info-50',
      },
    },
  },
);

export const pageNavigationButtonClasses = cva(
  [
    'relative',
    'inline-flex items-center',
    'cursor-pointer',
    'text-sm font-medium',
    'border border-info-300',
    'bg-white hover:bg-info-50',
    'px-2 py-2',
    'text-info-500',
  ],
  {
    variants: {
      isNext: {
        true: 'rounded-r-md',
      },
      isPrevious: {
        true: 'rounded-l-md',
      },
    },
    defaultVariants: {
      isNext: false,
      isPrevious: false,
    },
  },
);

export const tableRowClasses = cva('border-t border-info-200', {
  variants: {
    hasInteractiveRows: {
      true: 'cursor-pointer transition-colors hover:bg-primary-50',
      false: '',
    },
  },
  defaultVariants: {
    hasInteractiveRows: false,
  },
});

export const tableHeaderClasses = cva(
  'px-4 py-2.5 text-left text-xs font-medium uppercase leading-4 tracking-wider text-info-500',
);

export const tableCellClasses = cva('p-4 text-sm font-normal leading-5 text-info-900');
