import type { MouseEventHandler } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useLatest, useWindowSize } from 'react-use';
import { useEffectOnce } from '@src/hooks';
import { debounce } from 'lodash-es';

export enum PanelPlacement {
  Left = 'left',
  Right = 'right',
}

interface UseResizeableContainerArgs {
  debounceMs?: number;
  documentPadding?: number;
  initialWidth: number;
  onResized?: (newWidth: number) => void;
  /** Defines the max distance between the container and the other edge of the screen. **/
  maxWidthOffset?: number;
  minWidth?: number;
  placement: PanelPlacement;
}

const isMouseRightClickEvent = (event: Parameters<MouseEventHandler>[0]) => event.button === 2;

const MIN_WIDTH_PX = 320;
const DOCUMENT_PADDING_X_PX = 8 * 2 + 11;

export const useResizeableContainer = ({
  debounceMs = 200,
  documentPadding = DOCUMENT_PADDING_X_PX,
  initialWidth,
  maxWidthOffset = 0,
  minWidth = MIN_WIDTH_PX,
  onResized,
  placement,
}: UseResizeableContainerArgs) => {
  const { width: windowWidth } = useWindowSize();
  const [width, setWidth] = useState(initialWidth);
  const [pageWidth, setPageWidth] = useState(initialWidth - documentPadding);

  const latestPlacement = useLatest(placement);
  const latestWindowWidth = useLatest(windowWidth);
  const latestMaxWidthOffset = useLatest(maxWidthOffset);

  const mouseDownRef = useRef<boolean>(false);

  const debouncedChangeHandler = debounce((newValue: number) => {
    const parsedWidth = newValue - documentPadding;
    setPageWidth(parsedWidth);
    onResized?.(newValue);
  }, debounceMs);

  useEffect(() => {
    if (maxWidthOffset > 0 && windowWidth - maxWidthOffset < width) {
      const newWidth = windowWidth - maxWidthOffset;
      setWidth(newWidth);
      debouncedChangeHandler(newWidth);
    }
    // eslint-disable-next-line -- We only want to react to maxWidthOffset changes
  }, [maxWidthOffset]);

  const handleMouseDown: MouseEventHandler = (event) => {
    if (isMouseRightClickEvent(event)) {
      return;
    }

    mouseDownRef.current = true;
    event.preventDefault();
  };

  const handleMouseUp = () => {
    mouseDownRef.current = false;
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (mouseDownRef.current) {
      const currentWindowWidth = latestWindowWidth.current;
      const isLeftPlacement = latestPlacement.current === PanelPlacement.Left;

      const xCoordinates = isLeftPlacement ? event.pageX : currentWindowWidth - event.pageX;

      const newValue = Math.min(
        Math.max(xCoordinates, minWidth),
        currentWindowWidth - latestMaxWidthOffset.current - 48,
      );

      setWidth(newValue);
      debouncedChangeHandler(newValue);
    }
  };

  useEffectOnce(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
  });

  return {
    onMouseDown: handleMouseDown,
    pageWidth,
    width,
  };
};
