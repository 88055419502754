import { useNavigate } from 'react-router-dom';
import { authProvider } from '@helpers/auth/authProvider';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import Nav from '@src/routes';

export const CognitoLogout = () => {
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      Sentry.setUser(null);
      await authProvider.logout();
    } finally {
      navigate(Nav.Login);
    }
  };

  useEffectOnce(() => {
    signOut();
  });

  return (
    <div className="overlay">
      <div className="loading-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
