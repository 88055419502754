import type { FC } from 'react';
import { useMemo } from 'react';
import { selectTriggerSize } from '@components/classes/select';
import Icon from '@components/Icon';
import { InputField } from '@components/InputField';
import InputReadOnly from '@components/InputReadOnly';
import { InputSelectMenuContainer, inputSelectStyles } from '@components/InputSelect';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import type { BlockVisibilityValueType, ElementSize, SelectMenuItemResult } from '@root/@types/types';
import sharedClasses from '@utils/shared-classes';
import { getSelectMenuItemsNoClauses } from '@WysiwygEditor/components/Controls/utils/datapoint-options';
import clsx from 'clsx';
import type { ControllerStateAndHelpers, DownshiftState, StateChangeOptions } from 'downshift';
import Downshift from 'downshift';

import type { VisibilityConfigPath } from '../types';

interface BlockVisibilityFieldSelectorProps {
  idx: number;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  label?: string;
  name: string;
  onChange: (value: BlockVisibilityValueType | undefined, name: VisibilityConfigPath) => void;
  placeholder?: string;
  size?: ElementSize;
  value?: SelectMenuItemResult | null;
}

const downshiftStateReducer = (
  _state: DownshiftState<SelectMenuItemResult>,
  changes: StateChangeOptions<SelectMenuItemResult>,
) => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return { ...changes, inputValue: '' };

    case Downshift.stateChangeTypes.clickButton:
    case Downshift.stateChangeTypes.changeInput:
      return { ...changes, highlightedIndex: 0 };

    default:
      return changes;
  }
};

export const BlockVisibilityFieldSelector: FC<BlockVisibilityFieldSelectorProps> = ({
  name,
  idx,
  isReadOnly,
  isDisabled,
  label,
  placeholder = 'Select a field',
  onChange,
  size = 'md',
  value,
}) => {
  const onSelect = (
    selectedItem: SelectMenuItemResult | null,
    _stateAndHelpers: ControllerStateAndHelpers<SelectMenuItemResult>,
  ) => {
    return onChange(selectedItem?.id, name as VisibilityConfigPath);
  };

  const selectMenuItems = useMemo(getSelectMenuItemsNoClauses, []);

  return (
    <InputField aria-label={name} labelText={label} name={name}>
      {isReadOnly && <InputReadOnly name={name} value={value?.title || ''} />}
      {!isReadOnly && (
        <Downshift<SelectMenuItemResult>
          stateReducer={downshiftStateReducer}
          selectedItem={value}
          itemToString={(item) => item?.title ?? ''}
          onSelect={onSelect}
        >
          {({ getItemProps, getMenuProps, getToggleButtonProps, isOpen, getInputProps }) => {
            return (
              <div className="relative flex">
                <button
                  disabled={isDisabled}
                  data-testid="datapoint-condition"
                  {...getToggleButtonProps()}
                  type="button"
                  className={clsx(
                    'm-auto flex flex-1 justify-between rounded-md border border-info-300 px-3 py-2.5 text-sm text-info-900 shadow-sm ',
                    selectTriggerSize({ size }),
                    isDisabled && sharedClasses.disabled,
                  )}
                  aria-label={`Select field #${idx + 1}`}
                >
                  {value?.label ? value.label : <span className="text-info-400">{placeholder}</span>}
                  {!isOpen && <Icon name="chevron-down" className="ml-2 self-center fill-current text-info-800" />}
                  {isOpen && <Icon name="chevron-up" className="ml-2 self-center fill-current text-primary-700" />}
                </button>

                {isOpen && (
                  <InputSelectMenuContainer className={clsx(inputSelectStyles.menuOuter, 'overflow-y-auto')}>
                    <div data-testid="downshift-list">
                      <SelectMenu
                        isSearchable
                        items={selectMenuItems}
                        getAdditionalMenuProps={getMenuProps}
                        getAdditionalItemProps={(item: SelectMenuItem) => getItemProps({ item })}
                        getAdditionalInputProps={getInputProps}
                      />
                    </div>
                  </InputSelectMenuContainer>
                )}
              </div>
            );
          }}
        </Downshift>
      )}
    </InputField>
  );
};
