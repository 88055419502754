import { Fragment } from 'react';
import { POPOVER_Z_INDEX } from '@constants/z-indices';
import { EndorsementsButtonController } from '@ContractBuilder/contract-builder-header/modules/endorsements-button';
import { RenewalsButtonController } from '@ContractBuilder/contract-builder-header/modules/renewals-button';
import { useEntityStore } from '@ContractBuilder/store';
import { Listbox, Transition } from '@headlessui/react';
import { canChange } from '@helpers/canChange';
import { getDocumentMode } from '@helpers/getDocumentMode';
import { PaperClipIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { DocumentMode } from '@root/@types/types';
import { useFeatureFlags } from '@src/hooks';
import clsx from 'clsx';

export const LinkedDocsController = () => {
  const submission = useEntityStore(({ submission }) => submission);
  const getFeatureFlagState = useFeatureFlags();

  const hasEndorsementsFeature = getFeatureFlagState('Endorsements');

  const documentMode = getDocumentMode();
  const isEnabled =
    !canChange(submission?.status, undefined, 'status-change') && documentMode.mode === DocumentMode.SUBMISSIONS;

  if (!submission || !hasEndorsementsFeature || !isEnabled) {
    return null;
  }

  return (
    <Listbox>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">List of linked docs</Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-info-600 rounded-md border border-info-300 shadow-sm">
              <div className="relative z-0 inline-flex divide-x divide-info-300 rounded-md shadow-sm">
                <div
                  className={clsx(
                    'relative inline-flex items-center rounded-l-md border border-transparent bg-white py-1 pl-3 pr-4 text-info-700 shadow-sm',
                  )}
                >
                  <PaperClipIcon className="h-5 w-5" />
                  <p className="ml-2.5 text-sm font-medium">Linked docs</p>
                </div>
                <Listbox.Button
                  className={clsx(
                    'relative inline-flex h-9 items-center rounded-l-none rounded-r-md p-2 py-1 text-sm font-medium text-info-700 hover:bg-info-100 focus:z-10',
                  )}
                  data-testid="linked-docs-button"
                >
                  <span className="sr-only">Linked docs</span>
                  <ChevronDownIcon className="h-5 w-5 text-info-400" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  'absolute',
                  'right-0',
                  'mt-2',
                  'w-[600px]',
                  'origin-top-right',
                  'divide-y',
                  'divide-info-200',
                  'overflow-hidden',
                  'rounded-md',
                  'bg-white',
                  'shadow-xl',
                  'ring-1',
                  'ring-black',
                  'ring-opacity-5',
                  'focus:outline-none',
                  POPOVER_Z_INDEX,
                )}
              >
                <EndorsementsButtonController submission={submission} />
                <RenewalsButtonController submission={submission} />
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
