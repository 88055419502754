import type { FC } from 'react';
import { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import Button from '@components/Button';
import InputText from '@components/InputText';
import Modal from '@components/Modal';
import { useEntityStore } from '@ContractBuilder/store';
import { createRenewal } from '@mutations/createRenewal';
import { isEmptyValue } from '@root/helpers';
import Nav from '@src/routes';
import clsx from 'clsx';

interface CreateRenewalModalControllerProps {
  handleClose: () => void;
}

export const CreateRenewalModalController: FC<CreateRenewalModalControllerProps> = ({ handleClose }) => {
  const submission = useEntityStore(({ submission }) => submission);

  const [name, setName] = useState(submission?.name ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const submissionId = useParams().id;

  const handleCreateRenewal = async () => {
    setIsLoading(true);

    try {
      const { id: renewalId } = await createRenewal({ name, renewFromSubmissionId: submissionId as string });
      const renewalContractPath = generatePath(Nav.SubmissionContract, {
        id: renewalId,
      });

      return window.open(renewalContractPath, '_self');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      className="w-[32rem]"
      onClose={handleClose}
      open
      shouldShowCloseIcon={false}
      title="Create a renewal contract"
      titleClassName="pt-4 text-lg leading-6 font-medium !justify-center"
    >
      <div className={clsx('flex', 'flex-col', 'items-center justify-center', 'gap-10', 'px-8 pb-6 pt-0')}>
        <p className="text-sm font-normal leading-5 text-gray-500">
          Please fill in the following information to setup a renewal
        </p>
        <InputText
          isRequired
          labelText="Renewal name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="flex w-full justify-between gap-4">
          <Button isDisabled={isLoading} onClick={handleClose} className="h-[2.375rem] w-full">
            Cancel
          </Button>
          <Button
            onClick={handleCreateRenewal}
            className="h-[2.375rem] w-full"
            kind="primary"
            isDisabled={isEmptyValue(name)}
            loading={isLoading}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
