import type { FC } from 'react';
import { SelectMenu } from '@components/SelectMenu/SelectMenu';
import { Show } from '@components/Show';
import { BlockConfigurationPanelController } from '@ContractBuilder/modules/block-configuration-panel';
import { useBlockEditFieldProps } from '@ContractBuilder/modules/block-edit/hooks/use-block-edit-field-props';
import { INLINE_BLOCK_EDITOR_NAME } from '@ContractBuilder/modules/editors-map';
import { useVariationsTabs } from '@ContractBuilder/modules/variations-tabs/context/context';
import type { ToolMode } from '@ContractBuilder/store/types';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import { mdiDelete, mdiRestore } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useBlockEditFormStore } from '@root/src/containers/ContractBuilder/modules/block-edit';
import { ReactRenderer } from '@tiptap/react';
import { getContractDatapointControls } from '@WysiwygEditor/components/Controls/utils/datapoint-controls';
import { getSelectMenuItemsForContracts } from '@WysiwygEditor/components/Controls/utils/datapoint-options';
import { getCurrentContentId } from '@WysiwygEditor/components/Editor/extensions/helpers';
import type { DatapointInserterRenderFactory } from '@WysiwygEditor/components/Editor/types';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash-es';
import { twMerge } from 'tailwind-merge';

import Button from '../../../../../components/Button';
import { sectionClasses } from '../../../components/SectionView/Section/classes';
import { WysiwygEditor } from '../../../components/Wysiwyg';
import { BlockUsedWithin } from '../../block-edit/views/BlockUsedInTemplatesModal';
import { PreventBlockArchiveModal } from '../../block-edit/views/PreventBlockArchiveModal';
import { ModalCenterColumn, ModalSideColumn, SideColumnInnerContainer } from '../components/Containers';

interface OneModalViewProps {
  handleCancelFromPreventBlockArchiveModal: () => void;
  isPreventBlockArchiveModalOpen: boolean;
  mode: ToolMode;
  onBlockRestoreOrArchive: () => void;
  onCancel: () => void;
  onSubmit: () => Promise<void>;
}

const BlockEditModalView: FC<OneModalViewProps> = ({
  handleCancelFromPreventBlockArchiveModal,
  isPreventBlockArchiveModalOpen,
  mode,
  onBlockRestoreOrArchive,
  onCancel,
  onSubmit,
}) => {
  const isEdit = mode === 'edit';

  const { formValues: state } = useBlockEditFormStore(({ formValues }) => ({
    formValues,
  }));
  const { isBlockUsedInTemplatesModalOpen } = useUIStore(({ isBlockUsedInTemplatesModalOpen }) => ({
    isBlockUsedInTemplatesModalOpen,
  }));

  const { currentVariationIdx, variationsCount } = useVariationsTabs();
  const hasVariations = variationsCount > 0;

  const { name: titleFieldAccessor, onChange: handleTitleChange } = useBlockEditFieldProps(
    hasVariations,
    currentVariationIdx,
    'title',
  );
  const { name: contentFieldAccessor, onChange: handleContentChange } = useBlockEditFieldProps(
    hasVariations,
    currentVariationIdx,
    'content',
  );

  const { deleted_at, type, name } = state ?? {};

  const hasContent = !isEmpty(get(state, contentFieldAccessor));
  const hasName = !!name;

  const shouldDisableSubmitButton = !hasContent || !hasName;

  const datapointInserterItems = getSelectMenuItemsForContracts();
  const getDatapointInserterRenderer: DatapointInserterRenderFactory = (
    props,
    items,
    handleInsertDatapoint,
    handleCancel,
  ) =>
    new ReactRenderer(SelectMenu, {
      props: {
        className: 'max-h-[364px]',
        items,
        range: props.range,
        filterText: props.query,
        expandFirstLevel: false,
        isSearchable: false,
        maxTextWidth: 350,
        onClose: handleCancel,
        onItemClick: handleInsertDatapoint,
      },
      editor: props.editor,
    });

  return (
    <div className="page-template relative flex h-[708px] w-full border-t">
      <ModalCenterColumn>
        <div className={twMerge(sectionClasses.body)}>
          <Show when={isEdit}>
            <BlockUsedWithin
              message="This block will be updated on these templates"
              isOpen={isBlockUsedInTemplatesModalOpen}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
            <PreventBlockArchiveModal
              message="This block can not be removed, it is present in these templates:"
              isOpen={isPreventBlockArchiveModalOpen}
              onCancel={handleCancelFromPreventBlockArchiveModal}
            />
          </Show>
          <WysiwygEditor
            blockType={type}
            bodyClassName="mt-2"
            isLoading={false}
            hasEditorUnsavedChanges={true}
            className={`!h-[530px] !overflow-y-auto`}
            name={INLINE_BLOCK_EDITOR_NAME}
            title={get(state, titleFieldAccessor, '') as string}
            titleFieldName={titleFieldAccessor}
            content={get(state, contentFieldAccessor, '') as string}
            currentContentId={getCurrentContentId(state, currentVariationIdx)}
            onUpdate={handleContentChange}
            handleTitleChange={handleTitleChange}
            getDatapointControls={getContractDatapointControls}
            getDatapointInserterRenderer={getDatapointInserterRenderer}
            datapointInserterItems={datapointInserterItems}
          />
        </div>

        <div className="justify-right absolute bottom-0 right-0 flex w-full gap-3 border-t p-4">
          {isEdit && (
            <Button
              className="h-8"
              kind={state?.deleted_at ? 'green' : 'danger'}
              isDisabled={shouldDisableSubmitButton}
              onClick={onBlockRestoreOrArchive}
            >
              <Icon path={deleted_at ? mdiRestore : mdiDelete} size={0.8} />
              {state?.deleted_at ? 'Restore' : 'Archive'}
            </Button>
          )}
          <Button className="h-8" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="h-8" isDisabled={shouldDisableSubmitButton} kind="primary" onClick={onSubmit}>
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </div>
      </ModalCenterColumn>
      <ModalSideColumn className="flex h-full w-fit flex-col">
        <SideColumnInnerContainer className={clsx('h-full flex-grow overflow-y-auto')} noPadding>
          <BlockConfigurationPanelController onCancel={onCancel} onSubmit={onSubmit} shouldShowSections={false} />
        </SideColumnInnerContainer>
      </ModalSideColumn>
    </div>
  );
};

export default BlockEditModalView;
