import type { Images } from '@domain/schemas/images.schema';
import { imageUpdateStatusSchema } from '@domain/schemas/images.schema';
import { updateImage } from '@mutations/updateImage';
import { uploadImage } from '@mutations/uploadImage';
import { getImages } from '@queries/getImages';
import { getImagesPresignedUrl } from '@queries/getImagesPresignedUrl';
import { ApiPatchOperation } from '@root/@types/types';
import { promiseWithRetries } from '@root/helpers/promise';
import { get as lodashGet } from 'lodash-es';
import { create } from 'zustand';

interface ImagesStore {
  isLoading: boolean;
  images: Images[] | null;
  setLoading: (isLoading: boolean) => void;
  refetchImageList: () => Promise<void>;
  updateStatus: (id: string, status: Images['status']) => Promise<{ success: boolean; error?: string }>;
  uploadImage: (file: File) => Promise<{ success: true } | { success: false; error?: string }>;
  remove: (id: string) => Promise<{ success: boolean; error?: string }>;
}

const GENERIC_UPLOAD_ERROR = 'Error uploading image, please try again';

export const useImagesStore = create<ImagesStore>((set, get) => ({
  isLoading: false,
  images: null,
  setLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  refetchImageList: async () => {
    set({ isLoading: true });
    const images = await getImages();
    set({ images, isLoading: false });
  },
  updateStatus: async (id, status) => {
    const payload = imageUpdateStatusSchema.safeParse({ id, status });
    if (!payload.success) {
      return { success: false, error: payload.error.message };
    }
    await updateImage(payload.data, id);

    return { success: true };
  },
  remove: async (id) => {
    set({ isLoading: true });
    await updateImage({ operation: ApiPatchOperation.SoftDelete }, id);
    set({ isLoading: false });

    return { success: true };
  },
  uploadImage: async (file) => {
    set({ isLoading: true });
    const response = await getImagesPresignedUrl(file.name, file.size, file.type);

    if (!response?.id || !response?.url) {
      set({ isLoading: false });
      return { success: false, error: GENERIC_UPLOAD_ERROR };
    }

    try {
      await uploadImage(response.url, { file }, response.fields);
    } catch (error) {
      set({ isLoading: false });
      return { success: false, error: GENERIC_UPLOAD_ERROR };
    }

    await get().updateStatus(response.id, 'ready');

    try {
      await promiseWithRetries({
        fn: getImages,
        getIsFulfilled: (data) => data.some((img) => img.id === response.id),
        retries: 10,
        delay: 1000,
      });

      await get().refetchImageList();
    } catch (err) {
      const message = lodashGet(err, 'message', 'Unable to complete malware scanning on the image.');
      return { success: false, error: message };
    } finally {
      set({ isLoading: false });
    }

    set({ isLoading: false });
    return { success: true };
  },
}));
