import { isNonNullish, sortAlphabetically } from '@root/helpers';
import type { Table } from '@tanstack/react-table';

export const getColumnFilterOptions = (table: Table<any>, columnId: string): string[] =>
  Array.from(
    new Set(
      table
        .getCoreRowModel()
        .flatRows.map((row) => row.getValue(columnId))
        .filter(isNonNullish) as string[],
    ),
  ).sort(sortAlphabetically);
