import type { HTMLAttributes } from 'react';
import { Icon } from '@mdi/react';
import type { IconProps } from '@mdi/react/dist/IconProps';
import clsx from 'clsx';

export interface IconMdiProps extends IconProps {
  onClick?: HTMLAttributes<HTMLDivElement>['onClick'];
  centerVertical?: boolean;
}

const DEFAULT_MDI_ICON_SIZE = 0.8;

export default function IconMdi({
  size = DEFAULT_MDI_ICON_SIZE,
  onClick,
  centerVertical = true,
  ...rest
}: Readonly<IconMdiProps>) {
  return (
    <div onClick={onClick} aria-hidden="true" className={clsx(centerVertical && 'my-auto')}>
      <Icon size={size} {...rest} />
    </div>
  );
}
