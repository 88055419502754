import { DATAPOINT, DERIVED_CONTENT, PPTR_TEMPLATE, REPEATER } from '@components/SelectMenu/constants';
import { handleDatapointInsert } from '@components/SelectMenu/handleDatapointInsert';
import type { SelectMenu } from '@components/SelectMenu/SelectMenu';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import type MentionExtension from '@tiptap/extension-mention';
import type { ReactRenderer } from '@tiptap/react';
import { getRepeaterInsert } from '@WysiwygEditor/components/Controls/utils/handle-repeater-insert';
import tippy from 'tippy.js';

import { Mention } from '../extensions';
import type { UseEditorHookProps } from '../types';

interface UseMentionExtensionProps
  extends Pick<UseEditorHookProps, 'getDatapointInserterRenderer' | 'datapointInserterItems'> {}

type MentionExtensionReturnType = undefined | typeof MentionExtension;

export const useMentionExtension = ({
  getDatapointInserterRenderer,
  datapointInserterItems,
}: UseMentionExtensionProps): MentionExtensionReturnType => {
  let reactRenderer: ReactRenderer<typeof SelectMenu, any> | null = null;
  let popup: any;

  return Mention().configure({
    HTMLAttributes: {
      class: 'mention',
    },
    suggestion: {
      char: '{{',
      items: () => {},
      render: () => {
        if (!getDatapointInserterRenderer) return null;

        return {
          onStart: (props: any) => {
            const handleCancel = () => popup?.[0]?.hide();

            const handleItemClick = (item: SelectMenuItem) => {
              const queryStringRange = props.range;
              queryStringRange.to += reactRenderer?.props?.query?.length ?? 0;
              props.editor.commands.deleteRange(queryStringRange);

              if (item.type === DATAPOINT || item.type === DERIVED_CONTENT || item.type === PPTR_TEMPLATE) {
                return handleDatapointInsert(props.editor, handleCancel, item);
              }

              if (item.type === REPEATER) {
                const handler = getRepeaterInsert(props.editor, handleCancel);
                return handler(item);
              }

              return undefined;
            };

            const containsRepeater = (content: string, id: string): boolean => {
              return content.match(`data-parentid\\="${id}"`) != null;
            };

            const disableEntries = (item: SelectMenuItem): boolean => {
              if (item.type !== 'repeater') return false;
              return containsRepeater(props.editor.getHTML(), item.id);
            };

            reactRenderer = getDatapointInserterRenderer(
              {
                ...props,
                disableEntries,
              },
              datapointInserterItems ?? [],
              handleItemClick,
              handleCancel,
            );

            popup = tippy('body', {
              getReferenceClientRect: props.clientRect,
              content: reactRenderer.element,
              showOnCreate: true,
              interactive: true,
              trigger: 'manual',
              placement: 'bottom-start',
              theme: 'mention-tooltip',
              arrow: false,
              sticky: false,
            });
          },

          onUpdate(props: any) {
            reactRenderer?.updateProps({ ...props, items: datapointInserterItems, filterText: props.query });

            popup[0].setProps({
              getReferenceClientRect: props.clientRect,
            });
          },

          onKeyDown(props: any) {
            if (!reactRenderer || !reactRenderer.ref) return;

            //@ts-expect-error
            return !!reactRenderer?.ref.handleKeyDown(props.event);
          },
          onExit() {
            popup[0].destroy();
            reactRenderer?.destroy();
          },
        };
      },
    },
  });
};
