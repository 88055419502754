import type { FC } from 'react';
import { useState } from 'react';
import { ContextMenu, type ContextOption } from '@components/menu';
import type { SvgIconName } from '@components/Svg';
import { isNonNullish } from '@root/helpers';

export interface BrandingImageMenuProps {
  canRemoveImages?: boolean;
  handleImageRemove?: () => Promise<void>;
}

export const BrandingImageMenu: FC<BrandingImageMenuProps> = ({ canRemoveImages, handleImageRemove }) => {
  const [isOpen, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const canDelete = !!handleImageRemove;

  const options: ContextOption[] = [
    canRemoveImages
      ? {
          title: 'Remove image',
          onClick: handleImageRemove,
          icon: 'trashcan' as SvgIconName,
          order: 0,
          isDisabled: !canDelete,
        }
      : null,
  ].filter(isNonNullish);

  if (options.length === 0) {
    return null;
  }

  const hasSomeOptionEnabled = options.some((option) => !option.isDisabled);

  return (
    <ContextMenu
      position="top left"
      disabled={!hasSomeOptionEnabled}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      options={options}
      dropdownClassNames="right-0"
    />
  );
};
