import type { BrandingType } from '@root/@types/types';
import { create } from 'zustand';

export interface BrandingTemplateStore {
  search: string;
  selectedBrandingId?: string;
  brandingTemplates: BrandingType[];
  reset: () => void;
  setSearch: (search: string) => void;
  setSelectedBrandingId: (id?: string) => void;
  setBrandingTemplates: (brandingTemplates: BrandingType[]) => void;
  getFilteredBrandingTemplates: () => BrandingType[];
}

export const useBrandingTemplateStore = create<BrandingTemplateStore>((set, get) => ({
  search: '',
  setSearch: (search) => set({ search }),
  selectedBrandingId: undefined,
  setSelectedBrandingId: (id) => set({ selectedBrandingId: id }),
  setBrandingTemplates: (brandingTemplates) => set({ brandingTemplates }),
  brandingTemplates: [],
  reset: () => set({ search: '', selectedBrandingId: undefined, brandingTemplates: [] }),
  getFilteredBrandingTemplates: () => {
    const search = get().search;
    const templates = get().brandingTemplates;

    if (!search) {
      return templates;
    }

    return templates?.filter((b: BrandingType) => b.name.toLowerCase().includes(get().search.toLowerCase()));
  },
}));
