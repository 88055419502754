import { Route, Routes } from 'react-router-dom';
import { BrandingDetailsPage, BrandingPage } from '@features/branding-edit';

import withMenuLayout from '../../layout/withMenuLayout';

function BrandingRoutes() {
  return (
    <Routes>
      <Route index element={<BrandingPage />} />
      <Route path={':id'} element={<BrandingDetailsPage />} />
    </Routes>
  );
}

export const UserBrandingPage = withMenuLayout(BrandingRoutes);
