import type { UpdateEndorsementDataPayload } from '@root/@types/types';
import { EndorsementPremiumChange } from '@root/@types/types';
import { isEmptyValue } from '@root/helpers';
import dayjs from 'dayjs';
import { z } from 'zod';

import type { FormState } from './types';
import { isConditionalPremiumOption } from './types';

export const createEndorsementFormSchema = z
  .object({
    additional_premium: z.object({ amount: z.number(), currency: z.string() }).nullable().optional(),
    effective_date: z
      .string()
      .datetime({ offset: false, local: true })
      .refine((value) => dayjs(value).isValid(), { message: 'Invalid date format' }),
    expiry_date: z
      .string()
      .datetime({ offset: false, local: true })
      .refine((value) => dayjs(value).isValid(), { message: 'Invalid date format' }),
    premium_change: z.nativeEnum(EndorsementPremiumChange),
    reason: z.string(),
    reference: z.string().min(1, { message: 'Reference is required' }),
    type: z.string(),
  })
  .superRefine((data, ctx) => {
    const hasAdditionalPremium =
      !isEmptyValue(data.additional_premium?.amount) && !isEmptyValue(data.additional_premium?.currency);

    if (isConditionalPremiumOption(data.premium_change) && !hasAdditionalPremium) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Additional premium is required' });
    }
  });

export const isFormValid = (state: FormState): state is UpdateEndorsementDataPayload => {
  const result = createEndorsementFormSchema.safeParse(state);
  return result.success;
};
