const blocks = [
  {
    content: `<table><tbody><tr locked="true"><td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false" hidebottom="false" hideleft="false" borderlinestyle="normal"><p><strong>Unique Market Reference:</strong></p></td><td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false" hidebottom="false" hideleft="false" borderlinestyle="normal"><p><span data-type="mention" class="mention" data-id="risk_details.unique_market_reference.unique_market_reference">{{unique_market_reference</span></p></td></tr><tr locked="false"><td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false" hidebottom="false" hideleft="false" borderlinestyle="normal"><p><strong>Placing Broker Unique Identifier:</strong></p></td><td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false" hidebottom="false" hideleft="false" borderlinestyle="normal"><p><span data-type="mention" class="mention" data-id="risk_details.unique_market_reference.placing_broker_unique_identifier">{{placing_broker_unique_identifier</span> test</p></td></tr></tbody></table>`,
    title: 'Unique Market Reference',
  },
  {
    content: `<div data-type="content-repeater-group" data-parentid="risk_details.sections">
  <div data-type="content-repeater-group-child" data-parentid="risk_details.sections" data-order="0">
    <p data-type="repeater-heading"><strong></strong></p>
    <table>
      <tbody>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section 1</strong></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section Description:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.sections.0.section_description">{{section_description</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Placing Broker Section Reference:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"
                class="mention"
                data-id="risk_details.sections.0.placing_broker_contract_section_reference">{{placing_broker_contract_section_reference</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p></p>
  </div>
</div>
<div data-type="content-repeater-group" data-parentid="risk_details.sections">
  <div data-type="content-repeater-group-child" data-parentid="risk_details.sections" data-order="0">
    <p data-type="repeater-heading"><strong></strong></p>
    <table>
      <tbody>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section 2</strong></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section Description:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.sections.0.section_description">{{section_description</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Placing Broker Section Reference:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"
                class="mention"
                data-id="risk_details.sections.0.placing_broker_contract_section_reference">{{placing_broker_contract_section_reference</span>
            </p>
          </td>
        </tr>


      </tbody>
    </table>
    <p></p>
  </div>
</div>
<div data-type="content-repeater-group" data-parentid="risk_details.sections">
  <div data-type="content-repeater-group-child" data-parentid="risk_details.sections" data-order="0">
    <p data-type="repeater-heading"><strong></strong></p>
    <table>
      <tbody>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section 3</strong></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Section Description:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.sections.0.section_description">{{section_description</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Placing Broker Section Reference:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"
                class="mention"
                data-id="risk_details.sections.0.placing_broker_contract_section_reference">{{placing_broker_contract_section_reference</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p></p>
  </div>
</div>`,
    title: 'Sections',
  },
  {
    content: `<div data-type="section-repeater" data-parentid="risk_details.type_section">
  <div data-type="section-repeater-child" data-parentid="risk_details.type_section" data-order="0">
    <p data-type="repeater-heading"><strong></strong></p>
    <table>
      <tbody>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Contract Classification:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"  class="mention"
                data-id="risk_details.type_section.0.insurance_or_reinsurance">{{insurance_or_reinsurance</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Treaty or Facultative:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.type_section.0.treaty_or_facultative">{{treaty_or_facultative</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Proportional or Non-Proportional:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"
                class="mention"
                data-id="risk_details.type_section.0.proportional_or_non_proportional">{{proportional_or_non_proportional</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p></p>
  </div>
</div>`,
    title: 'Type',
  },
  {
    content: `<div data-type="section-repeater" data-parentid="risk_details.policyholder">
  <div data-type="section-repeater-child" data-parentid="risk_details.policyholder" data-order="0">
    <p data-type="repeater-heading"><strong></strong></p>
    <table>
      <tbody>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Name:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal"
            data-parentid="risk_details.policyholder.0.name" data-type="content-repeater-group">
            <p data-type="repeater-heading"></p>
            <p data-parentid="risk_details.policyholder.0.name" data-type="content-repeater-group-child"><span
                data-type="mention"  class="mention"
                data-id="risk_details.policyholder.0.name.0.name">{{name</span></p>
          </td>
        </tr>
        <tr>
        <td colspan="2" rowspan="1" >
        <p></p>
        <p>And its affiliated subsidiary, and associated companies and/or corporations and the Policyholder's interest
        in partnerships and joint ventures as now exists or may hereafter be constituted or acquired and any party
        or interest which the Insured is responsible to insure.</p>
        <p></p>
        </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Street No. and Street:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.policyholder.0.street">{{street</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>City:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.policyholder.0.city">{{city</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Postcode:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.policyholder.0.postcode">{{postcode</span></p>
          </td>
        </tr>
        <tr locked="true">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Country Sub-Division:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention"  class="mention"
                data-id="risk_details.policyholder.0.country_sub_division">{{country_sub_division</span></p>
          </td>
        </tr>
        <tr locked="false">
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><strong>Country:</strong></p>
          </td>
          <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
            hidebottom="false" hideleft="false" borderlinestyle="normal">
            <p><span data-type="mention" class="mention"
                data-id="risk_details.policyholder.0.country">{{country</span></p>
          </td>
        </tr>
      </tbody>
    </table>
    <p></p>
  </div>
</div>`,
    title: 'Policyholder',
  },
  {
    content: `<div>
  <div data-type="section-repeater" data-parentid="risk_details.period">
    <div data-type="section-repeater-child" data-parentid="risk_details.period" data-order="0">
      <p data-type="repeater-heading"><strong></strong></p>
      <table>
        <tbody>
          <tr locked="true">
            <td colspan="1" rowspan="1" style="width: 146px; " hidetop="false" hideright="false" hidebottom="false"
              hideleft="false" borderlinestyle="normal">
              <p><strong>From:</strong></p>
            </td>
            <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
              hidebottom="false" hideleft="false" borderlinestyle="normal">
              <p><span data-type="mention"  class="mention"
                  data-id="risk_details.period.0.period_matrix.from.date">{{date</span>
                  <span data-type="mention"
                  class="mention" data-id="risk_details.period.0.period_matrix.from.time" data-hidden="true">{{time</span>
                  <span data-type="mention" class="mention"
                  data-id="risk_details.period.0.period_matrix.details.date_inclusive_exclusive_indicator">{{date_inclusive_exclusive_indicator</span>
                <span data-type="mention" class="mention" data-id="risk_details.period.0.period_matrix.from.timezone" data-hidden="true">{{timezone</span>
              </p>
            </td>
          </tr>
          <tr locked="true">
            <td colspan="1" rowspan="1" style="width: 146px; " hidetop="false" hideright="false" hidebottom="false"
              hideleft="false" borderlinestyle="normal">
              <p><strong>To:</strong></p>
            </td>
            <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
              hidebottom="false" hideleft="false" borderlinestyle="normal">
              <p><span data-type="mention" class="mention"
                  data-id="risk_details.period.0.period_matrix.to.date">{{date</span> <span data-type="mention"
                   class="mention"
                  data-id="risk_details.period.0.period_matrix.to.time" data-hidden="true">{{time</span> <span
                  data-type="mention"
                  class="mention"
                  data-id="risk_details.period.0.period_matrix.details.date_inclusive_exclusive_indicator">{{date_inclusive_exclusive_indicator</span>
                <span data-type="mention"  class="mention"
                  data-id="risk_details.period.0.period_matrix.to.timezone" data-hidden="true">{{timezone</span>
              </p>
            </td>
          </tr>
          <tr locked="true" data-hidden="true">
            <td colspan="2" rowspan="1" style="width: 146px; " hidetop="false" hideright="false" hidebottom="false"
              hideleft="false" borderlinestyle="normal">
              <p></p>
              <p><span data-type="mention"
                 class="mention"
                  data-id="risk_details.period.0.period_matrix.details.local_standard_time_location"
                  data-hidden="true">{{local_standard_time_location</span></p>
              <p></p>
              <p><span data-type="mention"
                  class="mention" data-id="risk_details.period.0.period_matrix.details.cancellation_provisions"
                  data-hidden="true">{{cancellation_provisions</span></p>
            </td>
          </tr>
          <tr locked="true">
            <td colspan="1" rowspan="1" style="width: 146px; " hidetop="false" hideright="false" hidebottom="false"
              hideleft="false" borderlinestyle="normal">
              <p><strong>Cancellation Provisions:</strong></p>
            </td>
            <td colspan="1" rowspan="1" style="max-width: calc(640px - 220px); " hidetop="false" hideright="false"
              hidebottom="false" hideleft="false" borderlinestyle="normal">
              <p>
                <span data-type="mention" class="mention" data-id="risk_details.period.0.period_matrix.details.cancellation_provisions">{{cancellation_provisions</span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p></p>
    </div>
  </div>
  <p>Both days inclusive, local Standard time at the above address.</p>
</div>`,
    title: 'Period',
  },
];

const BlockSimplified = ({ title, content }: { title: string; content: string }) => (
  <div className="flex flex-col">
    <div
      key={title}
      className="page-A4-width pointer-events-auto relative flex cursor-text px-mrc-block-padding py-3 opacity-100 transition-colors duration-500"
    >
      <div className="min-w-mrc-block-title mr-mrc-block-grid-cell w-mrc-block-title break-words text-xs font-semibold uppercase leading-normal text-info-600">
        {title}
      </div>
      <div
        className="block-content mrc-heading-block relative w-full min-w-0 flex-auto"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  </div>
);

export const ContractContentPlaceholder = () => (
  <>
    <h2 className="section-heading !m-0 flex w-full gap-x-2 px-mrc-block-padding pb-1 pt-5 font-bold capitalize">
      <strong>Risk Details</strong>
      <div className="mt-0.5 flex h-fit items-center gap-1"></div>
    </h2>
    {blocks.map(({ content, title }) => (
      <BlockSimplified content={content} title={title} key={title} />
    ))}
  </>
);
