import type { FC } from 'react';
import { Controller } from 'react-hook-form';
import InputSelect from '@components/InputSelect';

import { useBrandingOptions } from '../../hooks';
import type { StepProps } from '../../types';

export const Step3: FC<StepProps> = ({ control, formValues }) => {
  const { brandingFieldName, data, isLoading } = useBrandingOptions();

  return (
    <Controller
      control={control}
      name={brandingFieldName}
      render={({ field }) => (
        <InputSelect
          {...field}
          isClearable
          isDisabled={field.disabled}
          isLoading={isLoading}
          labelText="Template branding"
          options={data}
          placeholder="Select branding"
          value={formValues?.[brandingFieldName]}
        />
      )}
    />
  );
};
