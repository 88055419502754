import { TempContractBannerContent } from '@ContractBuilder/contract-builder-header/components/TempContractBanner';

const EntraTempContractBanner = () => {
  return (
    <div className="flex items-center justify-center gap-3 rounded-md bg-primary-50 p-4">
      <TempContractBannerContent />
    </div>
  );
};

const getTempContractBanner = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';
  if (isEntra) {
    return EntraTempContractBanner;
  }
  return () => null;
};

const TempContractBanner = getTempContractBanner();

export default TempContractBanner;
