import { toast } from 'react-toastify';

type ImageProcessorResult = {
  src: string;
  alt: string;
  width: number;
  height: number;
  'data-logo-path': string;
};

export const imageProcessor = (src: string, path: string, name: string): Promise<ImageProcessorResult> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    // prevent images to be inserted at full size
    img.width = 100;
    img.src = src;

    img.onload = () => {
      const result: ImageProcessorResult = {
        src,
        alt: name,
        width: img.width,
        height: img.height,
        'data-logo-path': path,
      };
      resolve(result);
      img.remove();
    };

    img.onerror = () => {
      toast.error('There was a problem with handling your image');
      reject(new Error('Failed to load image'));
      img.remove();
    };
  });
};
