import { isBlocksPath, isBrandingPath, isEndorsementPath, isTemplatePath } from '@root/src/utils/app-paths';

export enum DocumentContext {
  Template = 'TEMPLATE',
  Blocks = 'BLOCKS',
  Library = 'LIBRARY',
  Submission = 'SUBMISSION',
  Endorsement = 'ENDORSEMENT',
  Branding = 'BRANDING',
  PDF = 'PDF',
}

export const getDocumentContext = (): DocumentContext => {
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();
  const isEndorsement = isEndorsementPath();
  const isBranding = isBrandingPath();
  const isPDFRender = typeof document === 'undefined';

  switch (true) {
    case isPDFRender:
      return DocumentContext.PDF;
    case isTemplate:
      return DocumentContext.Template;
    case isBlock:
      return DocumentContext.Blocks;
    case isEndorsement:
      return DocumentContext.Endorsement;
    case isBranding:
      return DocumentContext.Branding;
    default:
      return DocumentContext.Submission;
  }
};
