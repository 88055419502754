import { EndorsementPremiumChange } from '@root/@types/types';

import type { BlockVisibilityWarningMode } from './types';

export const ENDORSEMENT_TYPE_OPTIONS = ['Cancellation', 'Endorsement', 'Void from Inception'].map((value) => ({
  name: value,
  value,
})); // TODO: make these options an enum

export const ENDORSEMENT_REASON_OPTIONS = [
  'Product unsuitable or misunderstood',
  'Product too expensive',
  'Alternative product purchased',
  'Cover overlapped with pre-existing cover',
  'Product cancelled within cooling off period',
  'Product cancelled by underwriter',
  'Poor service or complaint',
  'Cover no longer required',
  'New Address',
  'Addition or Deletion of Sections',
  'Change to Annual Aggregate Limit',
  'Change to Building Sum Insured',
  'Change to Contents Sum Insured',
  'Other Reasons',
  'Not Known',
].map((value) => ({ name: value, value }));

export const ENDORSMENT_PREMIUM_CHANGE_OPTIONS = Object.values(EndorsementPremiumChange).map((value: string) => ({
  name: value,
  value,
}));

const VISIBILITY_PROCEED = 'Are you sure you want to proceed?';

export const VISIBILITY_WARN_INFO: Record<BlockVisibilityWarningMode, string> = {
  block: `Turning on the variants for this block will remove the conditional logic rules set for the block. ${VISIBILITY_PROCEED}`,
  variations: `Turning off the block variants will remove all existing conditional logic rules set for the variants. ${VISIBILITY_PROCEED}`,
};

export const VISIBILITY_WARN_TITLE: Record<BlockVisibilityWarningMode, string> = {
  block: 'Block Conditional Logic',
  variations: 'Remove Variant Conditional Logic ',
};
