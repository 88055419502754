import axios from 'axios';

interface Data {
  file: File;
}

export const uploadAttachmentDocument = async <T extends Data>(
  url: string,
  data: T,
  fields: Record<string, string>,
) => {
  const formData = new FormData();
  Object.entries(fields).map(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('file', data.file);

  await axios.post(url, formData);
};
