import { cva } from 'class-variance-authority';

export const layoutChildrenClasses = cva(['flex', 'h-screen', 'max-h-screen'], {
  variants: {
    padding: { true: ['py-6'], false: [] },
    overflow: {
      false: ['overflow-hidden'],
    },
  },
  defaultVariants: {
    padding: true,
  },
});
