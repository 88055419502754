import type { FC } from 'react';
import { ListEndorsementsButton } from '@ContractBuilder/contract-builder-header/modules/endorsements-button/views/ListEndorsementsButton';
import type { EntityData } from '@ContractBuilder/types';
import { useEndorsementData } from '@src/hooks';

interface EndorsementsButtonControllerProps {
  submission: EntityData;
}

export const EndorsementsButtonController: FC<EndorsementsButtonControllerProps> = ({ submission }) => {
  const { data, isFetching } = useEndorsementData({
    refetchOnWindowFocus: false,
  });

  return <ListEndorsementsButton data={data} isLoading={isFetching} submission={submission} />;
};
