import type { Row, RowData, RowModel, Table } from '@tanstack/react-table';
import { expandRows, getMemoOptions, memo } from '@tanstack/react-table';

export function getPaginationRowModel<TData extends RowData>(): (table: Table<TData>) => () => RowModel<TData> {
  return (table) =>
    memo(
      () => [
        table.getState().pagination,
        table.getPrePaginationRowModel(),
        table.options.paginateExpandedRows ? undefined : table.getState().expanded,
      ],
      (pagination, rowModel) => {
        if (!rowModel.rows.length) {
          return rowModel;
        }

        const { pageSize, pageIndex } = pagination;
        let { rows, flatRows, rowsById } = rowModel;
        const pageEnd = pageSize * pageIndex + pageSize;

        rows = rows.slice(0, pageEnd);

        let paginatedRowModel: RowModel<TData>;

        if (!table.options.paginateExpandedRows) {
          paginatedRowModel = expandRows({
            rows,
            flatRows,
            rowsById,
          });
        } else {
          paginatedRowModel = {
            rows,
            flatRows,
            rowsById,
          };
        }

        paginatedRowModel.flatRows = [];

        const handleRow = (row: Row<TData>) => {
          paginatedRowModel.flatRows.push(row);

          if (row.subRows.length) {
            row.subRows.forEach(handleRow);
          }
        };

        paginatedRowModel.rows.forEach(handleRow);

        return paginatedRowModel;
      },
      getMemoOptions(table.options, 'debugTable', 'getPaginationRowModel'),
    );
}
