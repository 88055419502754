import { ApiPatchOperation } from '@root/@types/types';
import { z } from 'zod';

import type { PK } from './database.schema';
import { baseDatabaseSchema, userSchema } from './database.schema';

export const imagesSchema = z.object({
  fileName: z.string().max(100),
  id: z.string().uuid(),
  name: z.string(),
  path: z.string().url(),
  size: z.number().int().positive(),
  status: z.enum(['ready', 'pending']),
  type: z.enum(['image/png', 'image/jpeg']),
  url: z.string().url().optional(),
  malware_scan_status: z
    .enum(['NO_THREATS_FOUND', 'THREATS_FOUND', 'UNSUPPORTED', 'ACCESS_DENIED', 'FAILED'])
    .optional(),
  created_by: userSchema,
  created_at: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: 'Invalid ISO 8601 date format',
  }),
});

/**
 * Payload schemas
 */
export const imageCreateSchema = imagesSchema.pick({ fileName: true, size: true, type: true });

export const imageUpdateStatusSchema = imagesSchema
  .pick({ status: true })
  .extend({ operation: z.literal(ApiPatchOperation.UpdateStatus).default(ApiPatchOperation.UpdateStatus) });

export const imageSoftDeleteSchema = z.object({
  operation: z.literal(ApiPatchOperation.SoftDelete).default(ApiPatchOperation.SoftDelete),
});

/**
 * Database schemas
 */
export const imagesDatabaseSchema = imagesSchema.merge(baseDatabaseSchema);

/**
 * Type definitions
 */
export type Images = z.infer<typeof imagesSchema>;
export type ImagesDb = z.infer<typeof imagesDatabaseSchema> & PK;
export type ImageUpdateStatus = z.infer<typeof imageUpdateStatusSchema>;
export type ImageSoftDelete = z.infer<typeof imageSoftDeleteSchema>;
export type ImageUpdate = ImageUpdateStatus | ImageSoftDelete;
