import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';
import type { QueryStatus } from 'react-query';
import { useMeasure } from 'react-use';
import LoadingOverlay from '@components/LoadingOverlay';
import { Show } from '@components/Show';
import { Pagination } from '@components/Table';
import { ActionsBar } from '@pages/User/components/ActionsBar';
import { FiltersBar } from '@pages/User/components/FiltersBar';
import { TableBody } from '@pages/User/components/TableBody';
import { TableHeader } from '@pages/User/components/TableHeader';
import { fuzzyFilter } from '@pages/User/helpers/filters/fuzzyFilter';
import type { Action, Filter } from '@root/@types/types';
import type { RankingInfo } from '@tanstack/match-sorter-utils';
import type {
  ColumnFilter,
  ColumnFiltersState,
  ExpandedState,
  FilterFn,
  RowData,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';

interface ReactTableProps {
  entitiesLabel: string;
  filteredData: any[];
  expandableData?: any[];
  status?: QueryStatus;
  actions?: Action[];
  columnVisibility?: VisibilityState;
  initialFilters?: ColumnFiltersState;
  initialSorting?: SortingState;
  enableColumnFilters?: boolean;
  enableExpanding?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  columns: any;
  pageSize?: number;
  tableClasses?: string;
  renderCustomElements?: () => ReactNode;
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line unused-imports/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    tooltip?: boolean;
    filter?: Partial<Filter> & {
      order?: number;
      isSingleSelection?: boolean;
    };
    dataCellClassName?: string;
    headingCellClassName?: string;
    headingLinkClassName?: string;
    isStandaloneDataCell?: boolean;
  }
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export const ReactTable = ({
  actions = [],
  columns,
  columnVisibility,
  entitiesLabel,
  filteredData,
  expandableData = [],
  initialFilters,
  initialSorting,
  enableColumnFilters = true,
  enableExpanding = false,
  isFetching = false,
  isLoading = false,
  pageSize = 12,
  status,
  tableClasses,
  renderCustomElements,
}: ReactTableProps) => {
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize,
  });
  const [sorting, setSorting] = useState<SortingState>(initialSorting ?? []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialFilters ?? []);
  const [globalFilter, setGlobalFilter] = useState('');

  const [filterRef, { height: filtersHeight = 0 }] = useMeasure<HTMLDivElement>();

  const data = useMemo(() => filteredData, [filteredData]);
  const dataExpandable = useMemo(() => expandableData, [expandableData]);

  const table = useReactTable({
    columns,
    data: enableExpanding ? dataExpandable : data,
    initialState: {
      columnVisibility,
    },
    state: {
      columnFilters,
      expanded,
      globalFilter,
      pagination,
      sorting,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    enableColumnFilters,
    enableExpanding,
    sortDescFirst: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    globalFilterFn: 'fuzzy',
    getColumnCanGlobalFilter: (column) => !['created_at', 'updated_at'].includes(column.id),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.subRows,
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    filterFromLeafRows: true,
    maxLeafRowFilterDepth: 1,
  });

  const [isFiltersBarVisible, setIsFiltersBarVisible] = useState(false);
  const toggleFiltersBarVisibility = () => setIsFiltersBarVisible(!isFiltersBarVisible);

  const handleClearAllFiltersClick = () => {
    const clearedFilters = columnFilters.reduce((clearedColumnFilters, currentColumnFilter) => {
      if (Array.isArray(currentColumnFilter.value)) {
        return clearedColumnFilters;
      }
      clearedColumnFilters.push({ ...currentColumnFilter, value: false });
      return clearedColumnFilters;
    }, [] as ColumnFilter[]);
    setColumnFilters(clearedFilters);
  };

  const handleGlobalSearchClick = (searchTerm: string) => setGlobalFilter(searchTerm);

  if (!['error', 'success'].includes(status ?? '') || isFetching) {
    return (
      <div className="overlay bg-transparent md:left-64">
        <div className="loading-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  const shouldShowClearFilters =
    table.getState().columnFilters.filter((i) => ((i.value as string[])?.length ?? 0) > 0 || i.value).length > 0;
  return (
    <div className={clsx('flex flex-col px-4 sm:px-6 lg:px-8', tableClasses)}>
      <LoadingOverlay active={isLoading} />
      <ActionsBar
        actions={actions}
        entitiesLabel={entitiesLabel}
        enableColumnFilters={enableColumnFilters}
        handleClearAllFiltersClick={handleClearAllFiltersClick}
        handleGlobalSearchClick={handleGlobalSearchClick}
        shouldShowClearFilters={shouldShowClearFilters}
        toggleFiltersBarVisibility={toggleFiltersBarVisibility}
        renderCustomElements={renderCustomElements}
      />
      <div ref={filterRef}>
        <Show when={isFiltersBarVisible}>
          <FiltersBar table={table} />
        </Show>
      </div>
      <div className={'mt-8 flex h-full'} style={{ height: `calc(100% - ${filtersHeight}px - 10em)` }}>
        <div className="-mx-4 -my-2 flex-1 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className={'inline-block h-full min-w-full py-2 align-middle md:px-6 lg:px-8'}>
            <div className="flex h-full flex-col justify-between overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="grow overflow-y-auto ">
                <table data-testid="data-table" className="w-full min-w-full table-fixed divide-y divide-info-300">
                  <TableHeader tableHeaderGroups={table.getHeaderGroups()} />
                  <TableBody rows={table.getRowModel().rows} />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        totalResults={table.getRowCount()}
        pageSize={pageSize}
        onPageChange={table.setPageIndex}
        page={pagination.pageIndex + 1}
        detachedFromTable
      />
    </div>
  );
};
