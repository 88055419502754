import { PDFPreviewDrawer } from '@ContractBuilder/modules/pdf-preview';

import { BrandingDetailsEditor } from './branding-editor/BrandingDetailsEditor';
import { BrandingDetailsLayout } from './BrandingDetailsLayout';

export const BrandingDetailsPage = () => {
  return (
    <>
      <BrandingDetailsLayout>
        <BrandingDetailsEditor />
      </BrandingDetailsLayout>
      <PDFPreviewDrawer />
    </>
  );
};
