import { DocumentMode } from '@root/@types/types';
import { getDocumentMode } from '@root/src/helpers/getDocumentMode';

const TEXT_MAP = {
  [DocumentMode.ENDORSEMENTS]:
    'By setting this endorsement to final it will become read-only, any further updates or changes will require a new endorsement.',
  [DocumentMode.SUBMISSIONS]:
    'By setting this contract to final it will become read-only and any further updates or changes will be made as endorsements.',
  [DocumentMode.TEMPLATES]:
    'By setting this template to published it will become available for users when creating new submissions.',
  [DocumentMode.BRANDING]: 'By setting this branding to published it will become available for users.',
  [DocumentMode.OTHER]: '',
};

export const ValidInfo = () => {
  const { mode } = getDocumentMode();

  const text = TEXT_MAP[mode];

  return <p className="text-sm font-normal leading-5 text-info-500">{text}</p>;
};
