import { useQuery } from 'react-query';

import type { DynamoDBLibraryBlock, UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';
import { fetchBlock } from '../queries';

interface BlockLibraryHook {
  data?: DynamoDBLibraryBlock;
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useBlockLibrary = (id: string, enabled = true): BlockLibraryHook => {
  const { data, status, isFetching, refetch } = useQuery(['library-block', id], () => fetchBlock(id), {
    refetchOnWindowFocus: true,
    enabled,
  });

  return {
    data: data?.data,
    status,
    isFetching,
    refetch,
  };
};
