import React, { useEffect, useRef } from 'react';
import { Show } from '@components/Show';
import { blockContentClasses, blockTitleClasses } from '@ContractBuilder/modules/block-content/constants/classes';
import { useEditorsMap } from '@ContractBuilder/modules/editors-map';
import { sanitizeHtml } from '@helpers/sanitizeHtml';
import type { BlockType } from '@root/@types/types';
import LoadingOverlay from '@root/src/components/LoadingOverlay';
import { useLanguage } from '@root/src/language';
import { useEffectOnce } from '@src/hooks';
import { Editor, WysiwygControls } from '@WysiwygEditor/components';
import { useEditor } from '@WysiwygEditor/components/Editor/hooks/use-editor';
import type { DatapointInserterRenderFactory } from '@WysiwygEditor/components/Editor/types';
import autosize from 'autosize';
import clsx from 'clsx';

import { baseBlockBodyClasses } from '../../modules/block/classes';
import { VariationsTabsView } from '../../modules/variations-tabs';
import { TOOLBAR_HEIGHT } from '../../settings';
import { useEntityStore } from '../../store';

import { textAreaClasses } from './classes';

export interface WysiwygEditorProps {
  blockType?: BlockType;
  bodyClassName?: string;
  className?: string;
  content?: string;
  currentContentId?: string;
  focusPosition?: 'all' | 'start' | 'end';
  handleTitleChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  hasEditorUnsavedChanges?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isValid?: boolean;
  name: string;
  onUpdate: (content: string) => void;
  placeholderText?: string;
  shouldShowControls?: boolean;
  shouldShowHelperText?: boolean;
  title?: string;
  titleFieldName?: string;
  toolbarControlsClassName?: string;
  variationTabsClassName?: string;
  wrapperClassName?: string;
  enableImageInsert?: boolean;
  getDatapointControls?: any;
  getDatapointInserterRenderer?: DatapointInserterRenderFactory;
  datapointInserterItems?: any[];
}

export const WysiwygEditor: React.FC<WysiwygEditorProps> = ({
  blockType = 'mrc-heading',
  bodyClassName,
  className,
  content = '',
  currentContentId,
  getDatapointControls,
  getDatapointInserterRenderer,
  datapointInserterItems,
  focusPosition = 'start',
  handleTitleChange,
  isDisabled = false,
  isLoading = false,
  name,
  onUpdate,
  placeholderText,
  shouldShowControls = true,
  title,
  titleFieldName = 'title',
  toolbarControlsClassName,
  variationTabsClassName,
  wrapperClassName,
  enableImageInsert,
}) => {
  const { deregisterEditor, registerEditor } = useEditorsMap();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const wysiwygRef = useRef<HTMLDivElement>(null);

  const submission = useEntityStore((state) => state.submission);

  const { getContent } = useLanguage({ prefix: 'naming.mrc.block' });

  const editor = useEditor({
    name,
    editorContent: { [name]: content },
    editable: true,
    documentType: '',
    onUpdate,
    placeholderText,
    submission,
    enableImageInsert,
    getDatapointInserterRenderer,
    datapointInserterItems,
  });

  useEffect(() => {
    if (editor && !isDisabled) {
      registerEditor(name, editor);
      // Account for editor to be fully mounted
      setTimeout(() => {
        // @ts-expect-error
        editor.commands.focus(focusPosition);
      }, 100);
    }

    return () => deregisterEditor(name);
    // eslint-disable-next-line -- We only want to react to `editor` changing (coming from `undefined` to initialized state)
  }, [editor]);

  useEffectOnce(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }

    if (wysiwygRef.current) {
      autosize(wysiwygRef.current);
    }
  });

  useEffect(() => {
    if (isDisabled && editor) {
      // @ts-expect-error
      editor.commands.blur();
    }
    // eslint-disable-next-line -- We only want to react to `isDisabled` changing
  }, [isDisabled]);

  useEffect(() => {
    if (editor) {
      // @ts-expect-error
      editor.commands.setContent(content);
    }
    // eslint-disable-next-line -- We only want to react to `contentFieldAccessor` changing
  }, [currentContentId]);

  const isTwoColLayout = ['mrc-heading'].includes(blockType);
  const contentClasses = blockContentClasses({ type: blockType, className });

  return (
    <div>
      <div className="w-full">
        <div className={clsx('relative rounded bg-white', wrapperClassName)}>
          <LoadingOverlay size="sm" active={isLoading} />
          <Show when={shouldShowControls}>
            <WysiwygControls
              isDisabled={isDisabled}
              className={clsx('relative z-50 items-center', TOOLBAR_HEIGHT, toolbarControlsClassName)}
              editor={editor}
              datapointsControl={getDatapointControls}
            />
          </Show>
          <VariationsTabsView className={variationTabsClassName} />
          <div className={clsx(baseBlockBodyClasses, bodyClassName)}>
            {isTwoColLayout && (
              <textarea
                name={titleFieldName}
                data-test-id="block-title"
                className={blockTitleClasses({
                  type: blockType,
                  className: [...textAreaClasses, 'placeholder:leading-3', className],
                })}
                disabled={isDisabled}
                onChange={handleTitleChange}
                value={title}
                placeholder={getContent('title')}
                ref={textareaRef}
              />
            )}
            {editor ? (
              <div className={contentClasses}>
                <Editor isDisabled={isDisabled} editor={editor} ref={wysiwygRef} />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }}
                className={clsx(contentClasses, 'flex flex-col')}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
