import { useEntityStore } from '@ContractBuilder/store';
import { isEndorsementData } from '@ContractBuilder/types';
import type { ResourceSection } from '@root/@types/base';
import { getSortedEntries } from '@root/helpers';
import { isEndorsementViewPath } from '@utils/app-paths';
import { get } from 'lodash-es';

export const useCurrentContractSections = (): ResourceSection[] => {
  const submission = useEntityStore(({ submission }) => submission);
  const isEndorsementView = isEndorsementViewPath();

  if (!submission) {
    return [];
  }

  if (isEndorsementView && isEndorsementData(submission)) {
    const sections = get(submission, 'summary.sections', []);
    const summaryLayout = get(submission, 'summary.layout', []);
    return getSortedEntries(summaryLayout, sections);
  }

  return get(submission, 'sections', [])
    .filter((section) => !section.deleted_at)
    .map((section) => ({
      ...section,
      blocks: section.blocks.filter((block) => !block.deleted_at),
    }));
};
