import type { FC } from 'react';
import { useState } from 'react';
import type { DropEvent, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import Button from '@components/Button';
import Modal from '@components/Modal';
import { useImagesStore } from '@features/branding-edit/store/images.store';

import { ImageUploadView } from './ImageUploadView';

const acceptOnlyUniqueFiles = (existing: File[], incoming: File[]) => {
  const fileNames = existing.map((file) => file.name);
  const onlyNewFiles = incoming.filter((file) => !fileNames.includes(file.name));

  return [...existing, ...onlyNewFiles].sort((a, b) => a.name.localeCompare(b.name));
};

export interface ImageUploadControllerProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const ImageUploadController: FC<ImageUploadControllerProps> = ({ handleClose }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const { refetchImageList, isLoading, uploadImage } = useImagesStore((s) => s);

  const handleFileChange = (newFiles: File[]) => {
    if (newFiles.length < 1) {
      return;
    }

    setFiles(acceptOnlyUniqueFiles(files, newFiles));
  };

  const handleFileRejection = (fileRejections: FileRejection[], _event: DropEvent) => {
    const errors = fileRejections
      .map(({ errors, file }) =>
        errors.map(
          ({ message, code }) =>
            `File rejected: ${file.name} - ${code === 'file-too-large' ? 'File is larger than 1MB' : message}
      `,
        ),
      )
      .flat();

    setErrors((existing) => [...existing, ...errors]);
  };

  const handleSubmit = async () => {
    setErrors([]);
    if (files.length > 0) {
      const file = files[0];
      const result = await uploadImage(file);

      if (!result.success) {
        handleClose();
        return toast.error(result.error);
      }

      return onSuccess();
    }
  };

  const onSuccess = () => {
    setFiles([]);
    setErrors([]);
    refetchImageList();
    toast.success(`Image uploaded successfully`);
    handleClose();
  };

  const handleCancel = () => {
    setFiles([]);
    setErrors([]);
    handleClose();
  };

  return (
    <Modal open className="max-w-[600px]" onClose={handleCancel} title="Upload image">
      <div className="flex flex-col">
        <ImageUploadView
          onChange={handleFileChange}
          onFileRejection={handleFileRejection}
          files={files}
          errors={errors}
        />
        <div className="inline-flex items-start justify-start gap-3 self-stretch">
          <div className="flex shrink grow basis-0 items-start justify-end gap-3">
            <Button kind="secondary" className="h-9 min-w-[80px]" isDisabled={isLoading} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              kind="primary"
              className="h-9 min-w-[80px]"
              isDisabled={files.length === 0}
              loading={isLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
