import React from 'react';
import IconMdi from '@components/IconMdi';
import type { InputTextProps } from '@components/InputText';
import InputText from '@components/InputText';
import { mdiClose, mdiMagnify } from '@mdi/js';

interface SearchBoxProps extends Omit<InputTextProps, 'onChange'> {
  onChange: (value: string) => void;
  searchText: string;
}

export const SearchBox = (props: SearchBoxProps) => {
  const { onChange, searchText, ...inputProps } = props;

  const inputRef = React.createRef<HTMLInputElement>();

  const handleFocusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <InputText
      {...inputProps}
      onChange={(event) => onChange(event.target.value)}
      placeholder="Search datapoints"
      data-testid="select-menu-search"
      ref={inputRef}
      value={searchText}
      inputClassName="px-8"
      className="p-2"
      startAdornment={
        <IconMdi
          path={mdiMagnify}
          onClick={handleFocusInput}
          className="absolute left-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
        />
      }
      endAdornment={
        <IconMdi
          path={mdiClose}
          onClick={() => onChange('')}
          className="absolute right-3 top-1/2 -translate-y-1/2 transform cursor-pointer text-info-500"
        />
      }
    />
  );
};
