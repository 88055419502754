import { CognitoAuthProvider } from './cognitoAuthProvider';
import { EntraAuthProvider } from './entraAuthProvider';
import type { IAuthProvider } from './types';

const getAuthProvider = (): IAuthProvider => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return new EntraAuthProvider();
  }

  return new CognitoAuthProvider();
};

const authProvider = getAuthProvider();
export { authProvider };
