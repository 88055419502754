import type { Branding } from '@domain/schemas/branding.schema';
import {
  brandingCreateSchema,
  brandingUpdateContentSchema,
  brandingUpdateNameSchema,
  brandingUpdateStatusSchema,
} from '@domain/schemas/branding.schema';
import { canChange } from '@helpers/canChange';
import { createBranding } from '@mutations/createBranding';
import { updateBranding } from '@mutations/updateBranding';
import { fetchBrandingDetails } from '@queries/fetchBrandingDetail';
import { isEqual } from 'lodash-es';
import { create } from 'zustand';

import { getAuthorship } from '../utils/get-authorship';

export enum BrandingEditorKey {
  Header = 'branding-header',
  Footer = 'branding-footer',
}

type ApiOperationSuccess = { success: true };
type ApiOperationFailure = { success: false; error: string };

interface BrandingStore {
  originalBranding: Partial<Branding> | null;
  branding: Partial<Branding> | null;
  isLoading: boolean;
  dateInfo: string | null;
  hasOperationsDisabled: boolean;
  form: {
    isDirty: boolean;
    editorKey: BrandingEditorKey | null;
  };
  reset: () => void;
  restore: () => void;
  setLoading: (isLoading: boolean) => void;
  setBranding: (branding: Partial<Branding>) => void;
  saveChanges: () => Promise<ApiOperationSuccess | ApiOperationFailure>;
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => void;
  handleChange: (key: keyof Branding) => (value: string) => void;
  saveNameChange: (name: string) => Promise<ApiOperationSuccess | ApiOperationFailure>;
  updateStatus: (status: Branding['status']) => Promise<ApiOperationSuccess | ApiOperationFailure>;
  duplicateBranding: (sourceId?: string) => Promise<(ApiOperationSuccess & { id: string }) | ApiOperationFailure>;
  refreshBranding: (id: string) => Promise<void>;
}

export const useBrandingStore = create<BrandingStore>((set, get) => ({
  originalBranding: null,
  branding: null,
  isLoading: false,
  hasOperationsDisabled: false,
  dateInfo: null,
  form: {
    editorKey: null,
    isDirty: false,
  },
  reset: () => {
    return set({
      dateInfo: null,
      isLoading: false,
      originalBranding: null,
      branding: null,
      hasOperationsDisabled: false,
      form: {
        editorKey: null,
        isDirty: false,
      },
    });
  },
  setBranding: (branding) => {
    const cleanedBranding = {
      ...branding,
      header_content: branding.header_content ?? '',
      footer_content: branding.footer_content ?? '',
    };

    set({
      hasOperationsDisabled: !canChange(branding.status),
      branding: cleanedBranding,
      originalBranding: cleanedBranding,
      dateInfo: getAuthorship(branding),
    });
  },
  setLoading: (isLoading: boolean) => {
    set({ isLoading });
  },
  setFormEditorKey: (editorKey: BrandingEditorKey | null) => {
    set((state) => ({ form: { ...state.form, editorKey } }));
  },
  handleChange: (key) => (value: string) => {
    const original = get().originalBranding;
    const branding = get().branding;
    const newBranding = { ...branding, [key]: value };
    const isDirty = !isEqual(original, newBranding);
    set((state) => ({
      branding: newBranding,
      form: { ...state.form, isDirty },
    }));
  },
  restore: () => {
    set((s) => ({ isLoading: false, form: { isDirty: false, editorKey: null }, branding: s.originalBranding }));
  },
  saveChanges: async () => {
    const hasOperationsDisabled = get().hasOperationsDisabled;
    if (hasOperationsDisabled) {
      return { success: false, error: 'Branding not editable. Change to Draft first.' };
    }

    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateContentSchema.safeParse(branding);
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);
    get().setBranding({
      ...branding,
      header_content: payload.data.header_content,
      footer_content: payload.data.footer_content,
      version: (branding?.version ?? 1) + 1,
    });
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });

    return { success: true };
  },
  duplicateBranding: async (sourceId) => {
    set({ isLoading: true });
    const branding = get().branding;

    const payload = brandingCreateSchema.safeParse({ ...branding, clone_from_id: sourceId });

    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }

    const { id } = await createBranding(payload.data);

    set({ isLoading: false, form: { isDirty: false, editorKey: null } });

    return { success: true, id };
  },
  saveNameChange: async (name) => {
    const hasOperationsDisabled = get().hasOperationsDisabled;

    if (hasOperationsDisabled) {
      return { success: false, error: 'Branding not editable. Change to Draft first.' };
    }
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateNameSchema.safeParse({ name: name });
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);
    set({ isLoading: false, form: { isDirty: false, editorKey: null } });
    get().setBranding({ ...branding, name });
    return { success: true };
  },
  updateStatus: async (status) => {
    set({ isLoading: true });
    const branding = get().branding;

    if (!branding) {
      return { success: false, error: 'Branding not found' };
    }

    const payload = brandingUpdateStatusSchema.safeParse({ status });
    if (!payload.success) {
      set({ isLoading: false });
      return { success: false, error: payload.error.message };
    }
    await updateBranding(payload.data, branding.id!);

    set({
      isLoading: false,
      form: { isDirty: false, editorKey: null },
      hasOperationsDisabled: !canChange(status),
    });
    get().setBranding({ ...branding, status });
    return { success: true };
  },
  refreshBranding: async (id: string) => {
    set({ isLoading: true });
    const refreshedBranding = await fetchBrandingDetails(id);

    get().setBranding(refreshedBranding);
    set({ isLoading: false, hasOperationsDisabled: !canChange(refreshedBranding.status) });
  },
}));
