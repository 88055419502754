import type { FC, PropsWithChildren } from 'react';
import { BLOCK_ACTIONS_HOVERED_Z_INDEX, BLOCK_ACTIONS_Z_INDEX } from '@constants/z-indices';
import clsx from 'clsx';

import { blockActionsClasses } from '../classes';

export const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={clsx(blockActionsClasses(), BLOCK_ACTIONS_Z_INDEX, BLOCK_ACTIONS_HOVERED_Z_INDEX)}>{children}</div>
  );
};
