/* eslint-disable react/jsx-key */
import React from 'react';
import type { Column, Row } from 'react-table';
import { useTable } from 'react-table';
import { Show, ShowFirstMatching } from '@components/Show';
import { tableCellClasses, tableHeaderClasses, tableRowClasses } from '@components/Table/components/classes';
import { RowsSkeleton } from '@components/Table/components/RowsSkeleton';
import { isEmptyValue, isNonNullish } from '@root/helpers';

interface TableProps<Item extends object> {
  columns: Array<Column<Item>>;
  data: Item[];
  emptyRowsLabel?: string;
  isLoading?: boolean;
  onRowClick?: (row: Item) => void;
  rowsSkeletonLength?: number;
}

export const Table = <Item extends object>({
  columns,
  data,
  emptyRowsLabel,
  isLoading,
  onRowClick,
  rowsSkeletonLength,
}: TableProps<Item>) => {
  const tableInstance = useTable<Item>({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
  const hasInteractiveRows = isNonNullish(onRowClick);

  const handleRowClick = (row: Row<Item>) => {
    if (!hasInteractiveRows) {
      return;
    }

    return onRowClick?.(row.original);
  };

  return (
    <table {...getTableProps()} className="w-full">
      <thead className="bg-gray-50">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={tableHeaderClasses()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        <ShowFirstMatching>
          <Show when={!isLoading && !isEmptyValue(emptyRowsLabel) && rows.length === 0}>
            <tr className={tableRowClasses({ className: 'p-3' })}>
              <td className="p-3 text-sm font-normal leading-5 text-info-400">{emptyRowsLabel}</td>
            </tr>
          </Show>
          <Show when={isLoading}>
            <RowsSkeleton length={rowsSkeletonLength} />
          </Show>
          <Show when={true}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={tableRowClasses({ hasInteractiveRows })}
                  onClick={() => handleRowClick(row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className={tableCellClasses()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </Show>
        </ShowFirstMatching>
      </tbody>
    </table>
  );
};
