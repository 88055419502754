import { SearchableInputSelect } from '@components/SearchableInputSelect';

import { useBrandingTemplateStore } from './branding-templates.store';

export const InputSelectBranding = () => {
  const { selectedBrandingId, setSelectedBrandingId, getFilteredBrandingTemplates } = useBrandingTemplateStore(
    (s) => s,
  );
  const filtered = getFilteredBrandingTemplates();

  return (
    <SearchableInputSelect
      name="branding"
      noOptionsLeftLabel="No branding found"
      onChange={setSelectedBrandingId}
      options={filtered.map(({ id, name }) => ({ value: id, name }))}
      placeholder="Search brandings"
      value={selectedBrandingId}
    />
  );
};
