import { type BrandingUpdate } from '@domain/schemas/branding.schema';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

export const updateBranding = async (data: BrandingUpdate, id: string): Promise<{ message: string }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: '/brandings/' + id,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
