import axios from 'axios';

import type { Tag } from '../../@types/types';
import { getRequestAuthPayload } from '../helpers/getRequestAuth';

export const createTag = async (data: Pick<Tag, 'label'>): Promise<Pick<Tag, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: 'tags-spa-al',
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
