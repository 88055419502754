import { CognitoLogout } from './CognitoLogout';
import { EntraLogout } from './EntraLogout';

const getLogout = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return EntraLogout;
  }

  return CognitoLogout;
};

const Logout = getLogout();
export default Logout;
