interface PromiseWithRetriesOptions<T> {
  delay?: number;
  fn: () => Promise<T>;
  retries?: number;
  getIsFulfilled: (data: T) => boolean;
}

export const promiseWithRetries = <T>(params: PromiseWithRetriesOptions<T>) => {
  const { delay = 1000, retries = 3, fn, getIsFulfilled } = params;

  return new Promise((resolve, reject) => {
    let attempt = 0;

    const tryRequest = () => {
      fn().then((data) => {
        if (getIsFulfilled(data)) {
          return resolve(data);
        }

        if (attempt < retries - 1) {
          attempt++;
          setTimeout(tryRequest, delay);
        } else {
          reject();
        }
      });
    };

    tryRequest();
  });
};
