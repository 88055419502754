import type { FC } from 'react';
import React, { useState } from 'react';
import Tooltip from '@components/Tooltip';
import clsx from 'clsx';

import { Show, ShowFirstMatching } from '../Show';

import { EditModeControls } from './EditModeControls';
import { WithEditIcon } from './WithEditIcon';

interface InlineEditableTextProps {
  disabled?: boolean;
  editMode?: boolean;
  onCancel: () => void;
  onClick: () => void;
  onConfirm: () => void;
  confirmDisabled?: boolean;
  editor: React.ReactNode;
  display: React.ReactNode;
  tooltipContent?: string;
  buttonDataTestId?: string;
  enableAppearingEffect?: boolean;
  withEditIcon?: boolean;
  className?: string;
}

export const InlineEditableText: FC<InlineEditableTextProps> = ({
  buttonDataTestId,
  className,
  confirmDisabled,
  disabled,
  display,
  editMode,
  editor,
  enableAppearingEffect,
  onCancel,
  onClick,
  onConfirm,
  tooltipContent,
  withEditIcon = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsLoading(true);
      await onConfirm();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ShowFirstMatching>
      <Show when={Boolean(editMode)}>
        <EditModeControls
          onCancel={onCancel}
          onConfirm={handleConfirm}
          confirmDisabled={confirmDisabled}
          isLoading={isLoading}
        >
          {editor}
        </EditModeControls>
      </Show>
      <Show when={Boolean(withEditIcon)}>
        <WithEditIcon
          onClick={onClick}
          enableAppearingEffect={enableAppearingEffect}
          disabled={disabled}
          tooltipContent={tooltipContent}
          data-testid={buttonDataTestId}
        >
          {display}
        </WithEditIcon>
      </Show>
      <Show when={Boolean(display)}>
        <Tooltip content={tooltipContent} disabled={tooltipContent === undefined}>
          <div className={clsx(disabled && 'cursor-pointer', 'group flex', className)}>
            <button onClick={disabled ? undefined : onClick} disabled={disabled} className="truncate">
              <span className="sr-only">Inline edit</span>
              {display}
            </button>
          </div>
        </Tooltip>
      </Show>
      <Show when={true}>
        <div className={clsx(disabled ? 'pointer-events-none' : 'cursor-pointer', 'group mx-2 flex w-4')}>
          <Tooltip content={tooltipContent} disabled={tooltipContent === undefined}>
            <div
              onClick={disabled ? undefined : onClick}
              className={clsx([
                'transform',
                '-translate-x-1/2',
                'hover:cursor-pointer',
                'transition-all',
                'duration-200',
                'ease-in-out',
                'text-xs',
                'rounded-full',
                'p-0.25',
                'bg-white',
                'shadow-xl',
              ])}
              aria-hidden="true"
            >
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.6367" cy="14.4297" r="13" fill="#FBBF24" stroke="white" />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.6367 14.4297C21.6367 18.848 18.055 22.4297 13.6367 22.4297C9.21844 22.4297 5.63672 18.848 5.63672 14.4297C5.63672 10.0114 9.21844 6.42969 13.6367 6.42969C18.055 6.42969 21.6367 10.0114 21.6367 14.4297ZM14.6367 10.4297C14.6367 10.982 14.189 11.4297 13.6367 11.4297C13.0844 11.4297 12.6367 10.982 12.6367 10.4297C12.6367 9.8774 13.0844 9.42969 13.6367 9.42969C14.189 9.42969 14.6367 9.8774 14.6367 10.4297ZM12.6367 13.4297C12.0844 13.4297 11.6367 13.8774 11.6367 14.4297C11.6367 14.982 12.0844 15.4297 12.6367 15.4297V18.4297C12.6367 18.982 13.0844 19.4297 13.6367 19.4297H14.6367C15.189 19.4297 15.6367 18.982 15.6367 18.4297C15.6367 17.8774 15.189 17.4297 14.6367 17.4297V14.4297C14.6367 13.8774 14.189 13.4297 13.6367 13.4297H12.6367Z"
                  fill="#FFFBEB"
                />
              </svg>
            </div>
          </Tooltip>
        </div>
      </Show>
    </ShowFirstMatching>
  );
};
