import { REPEATER } from '@components/SelectMenu/constants';
import type { SelectMenuItem } from '@components/SelectMenu/TreeView';
import { insertSectionRepeaterContent } from '@root/helpers/schema';
import { getDataTypeFromComponent } from '@WysiwygEditor/components/DatapointInserter/helpers';

export const getRepeaterInsert = (editor: any, onClose: () => void) => {
  const handleSectionRepeaterInsert = (item: SelectMenuItem) => {
    if (item.type === REPEATER) {
      const schemaItem = item.payload;
      const dataType = getDataTypeFromComponent(schemaItem['ui:component']);

      onClose();

      return insertSectionRepeaterContent(editor, schemaItem, {
        'data-type': dataType,
        'data-parentid': item.id,
      });
    }
  };

  return handleSectionRepeaterInsert;
};
