import { EntraContactSupportModal } from './EntraContactSupportModal';

const getContactSupportModal = () => {
  const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

  if (isEntra) {
    return EntraContactSupportModal;
  }

  return () => {
    return null;
  };
};

const ContactSupportModal = getContactSupportModal();
export default ContactSupportModal;
