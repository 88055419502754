import type { FC } from 'react';
import React from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { Banner } from '@features/endorsement-changes-banner';
import { ExclamationIcon } from '@heroicons/react/solid';
import { mdiSync } from '@mdi/js';

interface RestoreEndorsementTemplateBannerProps {
  isLoading: boolean;
  onRestore: () => void;
}

export const RestoreEndorsementTemplateBanner: FC<RestoreEndorsementTemplateBannerProps> = ({
  isLoading,
  onRestore,
}) => {
  return (
    <Banner className="!bg-amber-50 text-amber-900">
      <ExclamationIcon className="inline-flex h-[18px] w-[18px] items-center text-amber-400" />
      <p>Your endorsement contains no summary, would you like to restore the template?</p>
      <Button className="w-42 ml-auto h-8 text-gray-700" onClick={onRestore} loading={isLoading}>
        Restore Template
        <IconMdi path={mdiSync} />
      </Button>
    </Banner>
  );
};
