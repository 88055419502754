import type { FC } from 'react';
import clsx from 'clsx';

interface SectionTitleProps {
  title: string;
  areBlocksEmpty: boolean;
}

export const SectionTitle: FC<SectionTitleProps> = ({ title, areBlocksEmpty }) => {
  return (
    <span className={clsx('grow truncate font-bold text-info-800', areBlocksEmpty ? 'opacity-25' : '')} title={title}>
      {title}
    </span>
  );
};
