import type { ImageUpdate } from '@domain/schemas/images.schema';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

const IMAGES_URI = 'images';

export const updateImage = async (data: ImageUpdate, id: string): Promise<{ message: string }> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'patch',
    url: `/${IMAGES_URI}/` + id,
    data,
  });

  const response = await axios(networkPayload);
  return response.data;
};
