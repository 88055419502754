import type { FunctionComponent, HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';

interface CircleIconWrapperProps extends HTMLAttributes<SVGElement> {
  isActive?: boolean;
  icon: FunctionComponent<HTMLAttributes<SVGElement>>;
  onClick: () => void;
  wrapperClassName?: string;
}

const wrapperClasses = cva(
  [
    'inline-flex cursor-pointer items-center justify-center rounded-full p-1 text-info-600 transition-all hover:bg-info-100 focus:outline-none',
  ],
  {
    variants: {
      isActive: {
        true: 'hover:bg-primary-100',
        false: '',
      },
    },
    defaultVariants: {
      isActive: false,
    },
  },
);

const iconClasses = cva(
  [
    'inline-flex h-8 w-8 cursor-pointer rounded-full p-1 text-info-600 transition-colors hover:bg-info-100 focus:outline-none',
  ],
  {
    variants: {
      isActive: {
        true: 'bg-primary-100 hover:bg-primary-100',
        false: '',
      },
    },
    defaultVariants: {
      isActive: false,
    },
  },
);

export const CircleIconWrapper = forwardRef<HTMLDivElement, CircleIconWrapperProps>(function CircleIconWrapper(
  { isActive = false, icon: IconComponent, onClick, wrapperClassName, ...iconProps },
  ref,
) {
  return (
    <div className={wrapperClasses({ isActive, className: wrapperClassName })} onClick={onClick} ref={ref}>
      <IconComponent {...iconProps} className={iconClasses({ isActive })} />
    </div>
  );
});

export const CircleIconPlaceholder = () => (
  <div className={wrapperClasses({ className: 'pointer-events-none' })}>
    <div className={iconClasses()} />
  </div>
);
