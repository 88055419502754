import type { SvgIconName } from '@components/Svg';
import type { BaseUser, User } from '@root/@types/types';
import { UserRole } from '@root/@types/types';
import type { FeatureFlag } from '@root/helpers/feature-flags';
import { getUserRole } from '@root/helpers/permissions/utils';

import Nav, { config } from '../routes';

type Menu = {
  path: Nav;
  icon: SvgIconName;
  allowedRoles?: string[];
  featureFlag?: FeatureFlag;
};

type MenuNavigation = Pick<Menu, 'path' | 'icon'> & {
  name: string;
};

export const menu: Menu[] = [
  { path: Nav.Submissions, icon: 'submissions' },
  {
    path: Nav.Templates,
    icon: 'templates',
    allowedRoles: [UserRole.Admin, UserRole.Superadmin, UserRole.TemplateManager],
  },
  { path: Nav.Blocks, icon: 'blocks', allowedRoles: [UserRole.Admin, UserRole.Superadmin, UserRole.TemplateManager] },
  {
    path: Nav.Branding,
    icon: 'photo',
    featureFlag: 'Branding',
    allowedRoles: [UserRole.Admin, UserRole.Superadmin, UserRole.TemplateManager],
  },
  { path: Nav.Tags, icon: 'tags', allowedRoles: [UserRole.Admin, UserRole.Superadmin, UserRole.TemplateManager] },
  { path: Nav.Users, icon: 'users', allowedRoles: [UserRole.Superadmin], featureFlag: 'Users' },
];

export const createMenuForUser = (
  user: User | BaseUser | null,
  getFeatureFlagState: (featureFlag: FeatureFlag) => boolean | undefined,
): MenuNavigation[] => {
  if (!user) {
    return [];
  }

  const userRole = getUserRole(user);

  if (!userRole) {
    return [];
  }

  const filtered = menu.filter(({ allowedRoles, featureFlag }) => {
    if (featureFlag && !getFeatureFlagState(featureFlag)) {
      return false;
    }

    if (!allowedRoles) {
      return true;
    }

    return allowedRoles.includes(userRole);
  });

  return filtered.map(({ path, icon }) => ({ path, icon, name: config[path].title }));
};
