import type { ReactNode } from 'react';
import React, { Fragment, useRef } from 'react';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { MODAL_Z_INDEX } from '@constants/z-indices';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import type { UIComponentBase } from '../../@types/types';

interface ModalProps extends UIComponentBase {
  'data-testid'?: string;
  description?: string;
  modalClassName?: string;
  onClose: () => void;
  open: boolean;
  shouldShowCloseIcon?: boolean;
  title?: ReactNode;
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function Modal({
  'data-testid': dataTestId,
  children,
  className,
  description,
  modalClassName,
  onClose,
  open,
  shouldShowCloseIcon = true,
  title,
  titleClassName,
  descriptionClassName,
}: ModalProps) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={clsx('fixed inset-0 overflow-y-auto', MODAL_Z_INDEX, modalClassName)}
        data-testid={dataTestId}
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className={clsx('flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0')}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-info-500/75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={clsx(
                'inline-block transform rounded-lg bg-white px-4 pb-4 text-left align-bottom shadow-xl transition-all sm:p-6 sm:align-middle',
                className,
              )}
            >
              {(title || description) && (
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className={twMerge(
                        'flex justify-between text-center text-lg font-medium leading-6 text-info-800',
                        titleClassName,
                      )}
                    >
                      {title}
                      <Show when={shouldShowCloseIcon}>
                        <Tooltip content="Close modal">
                          <XIcon
                            className="ml-auto h-7 w-7 -translate-y-[3px] cursor-pointer rounded-md p-1 text-info-500 transition-colors hover:bg-info-100"
                            onClick={onClose}
                          />
                        </Tooltip>
                      </Show>
                    </Dialog.Title>
                    <div className={twMerge('mt-2', descriptionClassName)}>
                      <p className="text-sm text-info-500">{description}</p>
                    </div>
                  </div>
                </div>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
