import { cva } from 'class-variance-authority';

export const bannerContainerClasses = cva(
  ['bg-primary-50', 'p-2.5', 'flex', 'items-center', 'justify-center', 'text-center', 'w-full', 'gap-x-2.5', 'text-sm'],
  {
    variants: {
      isDisabled: {
        true: 'opacity-50 pointer-events-none',
        false: '',
      },
    },
  },
);
