import { useEffect, useMemo, useRef } from 'react';
import Placeholder from '@tiptap/extension-placeholder';
import type { Editor } from '@tiptap/react';
import { useEditor as tiptapUseEditorHook } from '@tiptap/react';
import clsx from 'clsx';
import { debounce } from 'lodash-es';
import { Fragment, Slice } from 'prosemirror-model';

import { prepareBaseEditorExtensions } from '../extensions/base-extensions';
import type { UseEditorHookProps } from '../types';

import { useImageExtension } from './use-image-extension';
import { useMentionExtension } from './use-mention-extension';

export const editorBaseClasses = 'wysiwyg prose prose-sm sm:prose lg:prose-lg focus:outline-none';

export const useEditor = ({
  documentType,
  name,
  editorContent = {},
  enableImageInsert = false,
  onUpdate,
  editable,
  unformattedPaste = false,
  placeholderText = 'Block content',
  getDatapointInserterRenderer,
  datapointInserterItems,
}: UseEditorHookProps): Editor | null => {
  const MentionExtension = useMentionExtension({ getDatapointInserterRenderer, datapointInserterItems });
  const ImageExtension = useImageExtension({ enableImageInsert });
  const extensions = useMemo(
    () =>
      prepareBaseEditorExtensions([
        Placeholder.configure({
          placeholder: placeholderText,
        }),
        MentionExtension,
        ImageExtension,
      ]),
    // eslint-disable-next-line -- Update extensions only on editor mount
    [name],
  );

  const userContent = editorContent[name] ?? '';
  const unformattedPasteRef = useRef(unformattedPaste);

  useEffect(() => {
    unformattedPasteRef.current = unformattedPaste;
  }, [unformattedPaste]);

  const handleEditorUpdate = debounce(async (content) => {
    await onUpdate(content);
    // previous 200ms causes fail in cypress tests (content is not updated in state)
  }, 10);

  const editor = tiptapUseEditorHook(
    {
      disablePasteRules: true, // disable Markdown when pasting
      disableInputRules: true, // disable Markdown when typing
      autofocus: true,
      onUpdate({ editor }: any) {
        handleEditorUpdate(editor.getHTML());
      },
      editorProps: {
        attributes: {
          class: clsx(editorBaseClasses, documentType.toLowerCase()),
        },
        handlePaste(view: any, event: any, _slice: unknown) {
          if (unformattedPasteRef.current) {
            const text = event?.clipboardData?.getData('text/plain');
            const textFragment = Fragment.from(view.state.schema.text(text));
            const textSlice = new Slice(textFragment, 0, 0);
            //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L542
            const singleNode =
              textSlice.openStart == 0 && textSlice.openEnd == 0 && textSlice.content.childCount == 1
                ? textSlice.content.firstChild
                : null;
            //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L566
            const tr = singleNode
              ? view.state.tr.replaceSelectionWith(singleNode, (view as any).shiftKey)
              : view.state.tr.replaceSelection(textSlice);
            view.dispatch(tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste'));
            return true;
          } else return false;
        },
      },
      extensions,
    },
    [],
  );

  // https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1548336990
  useEffect(() => {
    if (editor) {
      setTimeout(() => {
        (editor as any).commands.setContent(userContent);
      }, 1);
    }
    // eslint-disable-next-line -- Update content only on editor mount
  }, [editor]);

  if (editor) {
    (editor as any).setOptions({ editable });
  }

  return editor;
};
