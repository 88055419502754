import { getChildrenWithPath } from '@root/helpers/schema/getChildrenWithPath';
import { omit } from 'lodash-es';

import { createMention } from '../../editor';
import { isNonNullish } from '../../isNonNullish';
import type { ResolvedSchemaField, ResolvedSchemaTree } from '../types';

import { getHTMLAttributesList } from './get-html-attributes-list';
import type { ConfigParams } from './types';

type Item = ResolvedSchemaTree | ResolvedSchemaField;

const REPEATER_DATAPOINT = 'Repeater';
const REPEATER_TABLE = 'SectionRepeater';

const formatTitle = (value: string) => value?.replace(/[0-9]/g, '').trim();

export const getContentForRepeater = (item: Item, params: ConfigParams): string => {
  const getSortedChildren = (item: Item) => {
    const children = getChildrenWithPath(item, item.id);
    return children.sort((a, b) => (a.qid ?? Infinity) - (b.qid ?? Infinity));
  };

  const getDatapointsFrom = (item: Item): Item[] => {
    const children = getSortedChildren(item);
    if (children.length === 0) return [item];
    return children.map(getDatapointsFrom).flat();
  };

  const getRowForDatapoints = (item: Item): string => {
    const shouldIncludeAttributes = item['ui:component'] === REPEATER_DATAPOINT; // case of policyholder.name

    const attribsOuter = shouldIncludeAttributes
      ? ` data-type="content-repeater-group" data-parentid="${item.id}"`
      : '';

    const attribsInner = shouldIncludeAttributes
      ? ' ' +
        getHTMLAttributesList({
          'data-type': 'content-repeater-group-child',
          'data-parentid': item.id,
          'data-order': String(0),
        })
      : '';

    const leaves = getDatapointsFrom(item);

    return `
      <tr locked="true">
        <td><p><strong>${formatTitle(item.title)}:</strong></p></td>
        <td${attribsOuter}>
          <p${attribsInner}>${leaves.map((childItem: Item) => createMention(childItem.id)).join(' ')}</p>
        </td>
      </tr>
    `;
  };

  const getTableMarkup = (item: Item, params: ConfigParams): string => {
    const attributesList = getHTMLAttributesList(omit(params, ['data-type', 'displayType', 'getTitle']));

    const children = getSortedChildren(item);

    // table rows in purple and green boxes
    const rows = children.filter((child) => child['ui:component'] !== REPEATER_TABLE);

    const tableMarkup =
      rows.length > 0
        ? `
          <table>
            <tbody>
              ${rows
                .map((childItem: Item) => getRowForDatapoints(childItem))
                .filter(isNonNullish)
                .flat()
                .join('')}
            </tbody>
          </table>
        `
        : '';

    // green boxes nested in purple boxes
    const greenBoxes = children.filter((child) => child['ui:component'] === REPEATER_TABLE);
    const greenBoxesMarkup = greenBoxes
      .map(
        (greenBox) => `
          <div data-type="content-repeater-group" data-parentid="${greenBox.id}">
            ${getTableMarkup(greenBox, {
              'data-type': 'content-repeater-group',
              'data-parentid': greenBox.id,
              getTitle: () => undefined,
            })}
          </div>
        `,
      )
      .join(' ');

    const separator = rows.length > 0 && greenBoxes.length > 0 ? '<p></p>' : '';

    return `
      <div data-type="${params['data-type']}-child" data-order="0" ${attributesList}>
        <p data-type="repeater-heading"><strong></strong></p>
        ${tableMarkup}
        ${separator}
        ${greenBoxesMarkup}
        <p></p>
      </div>
    `;
  };

  // MAIN
  const getDatapointRepeater = (item: Item): string => {
    const attributesList = getHTMLAttributesList({
      'data-type': 'content-repeater-group-child',
      'data-parentid': item.id,
      'data-order': String(0),
    });

    const innerContent: string = getSortedChildren(item)
      .map((field) => createMention(field.id))
      .join(' ');

    return `
      <p ${attributesList}>${innerContent}</p>
    `;
  };

  const repeaterBody =
    item['ui:component'] === REPEATER_DATAPOINT ? getDatapointRepeater(item) : getTableMarkup(item, params);

  const attributesList = getHTMLAttributesList(omit(params, ['getTitle']));
  return `<div ${attributesList}>${repeaterBody}</div>`;
};
