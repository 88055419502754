import type { FC } from 'react';

interface EndorsementsCountBadgeProps {
  count: number | undefined;
}

export const EndorsementsCountBadge: FC<EndorsementsCountBadgeProps> = ({ count }) => {
  if (!count) {
    return null;
  }

  return (
    <div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-info-100 text-xs leading-4 text-gray-800">
      {count}
    </div>
  );
};
