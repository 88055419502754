import type { FC } from 'react';
import { Show } from '@components/Show';
import type { ResourceBlock } from '@root/@types/base';

interface BlockPermissionsSetProps {
  block: ResourceBlock;
}

const getPermissionPrefix = (can: boolean = true) => (can ? 'Can' : 'Cannot');
const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';

export const BlockPermissionsSet: FC<BlockPermissionsSetProps> = ({ block }) => {
  const { canDelete = true, canDeleteOnTemplate = true, canEdit = true, canEditOnTemplate = true } = block;

  return (
    <div className="flex flex-col gap-0.5 font-medium">
      <p className="mb-1 font-bold">Permissions Set</p>
      <Show when={!canEditOnTemplate && !isEntra}>
        <p>Super Admin edit only</p>
      </Show>
      <Show when={!isEntra}>
        <p>{getPermissionPrefix(canDeleteOnTemplate)} be removed from templates</p>
      </Show>
      <p>{getPermissionPrefix(canEdit)} be edited on submissions</p>
      <p>{getPermissionPrefix(canDelete)} be removed from submissions</p>
    </div>
  );
};
