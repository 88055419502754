import type { FC, ReactNode } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useBeforeUnload, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import Icon from '@components/Icon';
import { Show, ShowFirstMatching } from '@components/Show';
import { EditorToolbarController } from '@ContractBuilder/modules/editor-toolbar';
import { EditorsMapProvider } from '@ContractBuilder/modules/editors-map';
import { useHistoryBackTrap } from '@hooks/use-history-back-trap';
import clsx from 'clsx';

import { useBrandingStore } from '../store/branding.store';

import { BrandingDetailsDrawer } from './BrandingDetailsDrawer';
import { BrandingDetailsEditorBottomControls } from './BrandingDetailsEditorBottomControls';
import { BrandingDetailsHeader } from './BrandingDetailsHeader';
import { BrandingDetailsUnsavedChanges } from './BrandingDetailsUnsavedChanges';
import { BrandingLoadingSkeleton } from './BrandingLoadingSkeleton';

interface BrandingDetailsLayoutProps {
  children: ReactNode;
}

export const BrandingDetailsLayout: FC<BrandingDetailsLayoutProps> = ({ children }) => {
  const {
    form: { editorKey, isDirty },
    refreshBranding,
    isLoading,
    branding,
    setFormEditorKey,
  } = useBrandingStore((s) => s);

  const [openUnsavedChangesModal, toggleUnsavedChangesModal] = useToggle(false);

  const { id } = useParams();
  const isDraft = branding?.status === 'DRAFT';
  const isEditing = !!editorKey;
  const canNavigate = !isDirty && !isLoading;

  useEffect(() => {
    if (id) {
      refreshBranding(id);
    }
  }, [id, refreshBranding]);

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!canNavigate) {
        event.preventDefault();
        return;
      }
    },
    [canNavigate],
  );

  const handleTrap = useCallback(
    async (resume: () => void) => {
      if (!canNavigate) {
        return false;
      } else {
        resume();
        return true;
      }
    },

    [canNavigate],
  );
  useHistoryBackTrap(handleTrap);
  useBeforeUnload(handleUnload);

  const handleCancel = () => {
    switch (isDirty) {
      case true:
        toggleUnsavedChangesModal();
        break;
      case false:
        setFormEditorKey(null);
        break;
    }
  };

  const handleClickAway = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as Element).id?.startsWith('click-away-') && isEditing) {
      handleCancel();
    }
  };

  if (isLoading) {
    return <BrandingLoadingSkeleton />;
  }

  return (
    <div className="relative flex h-screen w-full flex-col" onClick={handleClickAway}>
      <BrandingDetailsHeader />
      <EditorsMapProvider>
        <div className="flex h-full bg-info-50">
          <div className="flex h-[calc(100vh-77px)] w-full flex-col">
            <Show when={isDraft}>
              <div className="relative w-full">
                <ShowFirstMatching>
                  <Show when={isEditing}>
                    <div className="min-h-[51px] bg-white">
                      <EditorToolbarController currentEditorKey={editorKey} type="branding" />
                    </div>
                  </Show>
                  <Show when={true}>
                    <div className="flex h-[51px] items-center gap-x-5 border-b bg-white fill-info-500 px-5 align-baseline text-info-500">
                      <Icon className="h-3.5 w-3.5 shrink-0" name="book" />
                      <p className="text-xs">Select either the header or footer to edit its content.</p>
                    </div>
                  </Show>
                </ShowFirstMatching>
              </div>
            </Show>
            <div
              id="click-away-y"
              className={clsx('micro-integrations z-0 flex-grow overflow-y-scroll pb-16', 'pt-36')}
            >
              <div id="click-away-x" className="flex flex-col items-center">
                {children}
              </div>
            </div>
            <Show when={isEditing}>
              <BrandingDetailsEditorBottomControls onCancel={handleCancel} />
            </Show>
          </div>
          <BrandingDetailsDrawer />
          <BrandingDetailsUnsavedChanges isOpen={openUnsavedChangesModal} toggleOpen={toggleUnsavedChangesModal} />
        </div>
      </EditorsMapProvider>
    </div>
  );
};
