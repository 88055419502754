import type { FC } from 'react';
import React from 'react';
import Button from '@components/Button';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { Banner } from '@features/endorsement-changes-banner/components';
import { mdiInformationSlabCircleOutline, mdiSync } from '@mdi/js';
import type { RootResourceStatus } from '@root/@types/types';

interface RestoreChangesBannerProps {
  isLoading: boolean;
  onRestore: () => Promise<void>;
  status?: RootResourceStatus;
}

export const RestoreChangesBanner: FC<RestoreChangesBannerProps> = ({ isLoading, onRestore, status }) => {
  return (
    <Banner>
      <IconMdi path={mdiInformationSlabCircleOutline} className="text-gray-700" size={0.8} />
      <p>You have hidden contract changes, which will not be included in the summary</p>
      <Show when={status === 'DRAFT'}>
        <Button className="w-42 ml-auto h-8 text-gray-700" onClick={onRestore} loading={isLoading}>
          Restore all changes
          <IconMdi path={mdiSync} />
        </Button>
      </Show>
    </Banner>
  );
};
