const Header = () => {
  return (
    <div className="flex animate-pulse items-center justify-between border-b border-info-200 px-5 pb-6 pl-2 pt-5">
      <div className="flex flex-col gap-y-2">
        <div className="flex gap-4">
          <div className="h-8 w-8 rounded-circle bg-info-100" />
          <div className="h-8 w-[220px] rounded-full bg-info-200" />
        </div>
      </div>
      <div className="flex items-center gap-x-2.5">
        <div className="h-8 w-8 rounded-circle bg-info-100" />
        <div className="h-9 w-36 rounded-md bg-info-100" />
      </div>
    </div>
  );
};

const Drawer = () => {
  return (
    <div className="flex h-full w-1/3 max-w-drawer flex-col gap-3 border-l bg-white px-3 py-6">
      <div className="h-3 w-3/4 rounded-full bg-info-200" />
      <div className="h-3 w-1/2 rounded-full bg-info-200" />
      <div className="h-3 w-3/4 rounded-full bg-info-200" />
      <div className="h-3 w-3/4 rounded-full bg-info-200" />
    </div>
  );
};

const Page = () => {
  return (
    <div className="micro-integrations z-0 flex-grow overflow-y-scroll pb-16 pt-36">
      <div className="flex flex-col items-center">
        <div
          className="branding page-A4-width relative flex flex-col justify-between border bg-white shadow-md"
          style={{ height: '297mm' }}
        >
          <div className="grid items-center gap-4 p-6">
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-full rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-full rounded-full bg-info-200" />
            <div className="h-3 w-1/2 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
            <div className="h-3 w-3/4 rounded-full bg-info-200" />
          </div>
        </div>
      </div>
    </div>
  );
};

const SubHeader = () => {
  return (
    <div className="flex h-[51px] animate-pulse items-center gap-4 border-b border-info-200 bg-white pl-2">
      <div className="h-4 w-4 rounded-circle bg-info-100" />
      <div className="h-4 w-[320px] rounded-full bg-info-200" />
    </div>
  );
};

export const BrandingLoadingSkeleton = () => {
  return (
    <div role="status" className="flex h-screen w-full flex-col bg-white" data-testid="loading-overlay">
      <Header />

      <div className="relative flex h-full w-full overflow-hidden overflow-y-hidden bg-info-50">
        <div className="relative flex h-screen w-full flex-col">
          <div className="flex h-full bg-info-50">
            <div className="flex h-[calc(100vh-77px)] w-full flex-col">
              <SubHeader />
              <Page />
            </div>
            <Drawer />
          </div>
        </div>
      </div>
      <span className="sr-only">Loading...</span>
    </div>
  );
};
