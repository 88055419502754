import { editorBaseClasses } from '@WysiwygEditor/components/Editor/hooks/use-editor';
import { cva } from 'class-variance-authority';
import clsx from 'clsx';

export const brandingDimClasses = ['opacity-50'];

export const editorClasses = cva(['overflow-clip', 'group', 'py-2', 'relative', 'shrink-0', 'min-h-[18px]'], {
  variants: {
    dim: {
      true: brandingDimClasses,
      false: [],
    },

    isEditing: {
      true: [],
    },
    type: {
      header: ['border-b'],
      footer: ['border-t'],
    },
    pdf: {
      true: ['max-h-[414px]'],
      false: ['max-h-[418px]'],
    },
  },

  defaultVariants: {
    dim: false,
    pdf: false,
  },
});

export const previewClasses = clsx('px-mrc-block-padding', 'min-w-full', 'h-full', editorBaseClasses);
