import type { ChangeEvent } from 'react';
import InputText from '@components/InputText';

import { useBrandingStore } from '../../store/branding.store';
import { InputSelectBranding } from '../branding-template-selector/InputSelectBranding';

export const CreateBrandingForm = () => {
  const { handleChange, branding } = useBrandingStore((s) => s);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange('name')(e.target.value);
  };

  return (
    <>
      <InputText
        autoFocus
        isRequired
        labelText="Branding name"
        placeholder="Branding name"
        value={branding?.name}
        onChange={onNameChange}
      />
      <div className="flex w-full flex-col gap-2">
        <div className="text-sm font-medium leading-tight text-gray-700">Duplicate existing branding</div>
        <InputSelectBranding />
      </div>
    </>
  );
};
