import type { EntityData } from '@ContractBuilder/types';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

const getUrl = (submissionId: string) => ['submissions-spa-al', submissionId, 'renewals'].join('/');

export const fetchRenewals = async (submissionId: string): Promise<EntityData[]> => {
  const params = new URLSearchParams();
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: getUrl(submissionId),
    params,
  });

  const response = await axios(networkPayload);
  return response.data;
};
