import type { MouseEventHandler } from 'react';
import type { TagShort } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';

const columnHelper = createColumnHelper<TagShort>();

export const getTagsTableColumns = ({
  handleEditTagClick,
}: {
  handleEditTagClick: (tag: TagShort) => MouseEventHandler<HTMLButtonElement> | undefined;
}) => [
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('label', {
    id: 'label',
    enableColumnFilter: false,
    header: 'Label',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: {
      tooltip: true,
    },
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: 'Created at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<TagShort, string>) =>
      formatDate(cellContext.row.original.created_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.display({
    id: 'edit',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<TagShort, unknown>) => (
      <button
        className="font-light text-primary-600 hover:text-primary-900"
        onClick={handleEditTagClick(cellContext.row.original)}
      >
        Edit
      </button>
    ),
    meta: {
      dataCellClassName:
        'relative w-20 truncate whitespace-nowrap py-4 pl-3 pr-4 text-right align-top text-sm font-medium sm:pr-6',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  }),
];
