interface PolicyholderItem {
  name: Array<{ name: string }>;
}

export const getAllPolicyholderNames = (parentSubmissionPolicyholderData: PolicyholderItem[] = []) => {
  return parentSubmissionPolicyholderData
    .flatMap((item) => item.name)
    .map((nameObj) => nameObj.name)
    .join(', ');
};

interface OriginalInsuredItem {
  original_insured: string;
}

export const getAllOriginalInsuredNames = (parentSubmissionOriginalInsuredData?: OriginalInsuredItem[]) => {
  if (!Array.isArray(parentSubmissionOriginalInsuredData)) {
    return undefined;
  }

  return parentSubmissionOriginalInsuredData.map((item) => item.original_insured).join(', ');
};
