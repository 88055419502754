import type { MouseEventHandler } from 'react';
import React from 'react';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline';
import ItemUsedWithin from '@pages/User/components/ItemUsedWithin';
import type { BaseUser } from '@root/@types/types';
import { formatDate } from '@root/helpers';
import { isSuperadmin } from '@root/helpers/permissions';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING } from '@User/Blocks/constants';
import clsx from 'clsx';

const columnHelper = createColumnHelper<LibraryBlockTypeForListPage>();

export const getBlocksTableColumns = ({
  handleEditBlockClick,
  user,
}: {
  handleEditBlockClick?: (blockId: string) => MouseEventHandler<HTMLButtonElement> | undefined;
  user?: BaseUser | null;
} = {}) => [
  columnHelper.display({
    id: 'toggleSubRows',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, unknown>) => {
      if (cellContext.row.getCanExpand()) {
        return (
          <div className="flex-shrink-0 cursor-pointer px-2 py-4" data-testid="toggleExpandChildrenBlocks">
            <Show when={cellContext.row.getIsExpanded()}>
              <ChevronDownIcon
                onClick={cellContext.row.getToggleExpandedHandler()}
                className="mx-2 h-4 w-4 text-info-600 transition-colors focus:outline-none"
              />
            </Show>
            <Show when={!cellContext.row.getIsExpanded()}>
              <ChevronRightIcon
                onClick={cellContext.row.getToggleExpandedHandler()}
                className="mx-2 h-4 w-4 text-info-600 transition-colors focus:outline-none"
              />
            </Show>
          </div>
        );
      }
      return null;
    },
    meta: {
      dataCellClassName:
        'relative !w-10 truncate whitespace-nowrap !pl-0 !pr-0 !py-0 text-right align-top text-sm font-medium',
      headingCellClassName: 'relative !w-10 py-3.5 !pl-0 !pr-0',
    },
  }),
  columnHelper.accessor('id', {
    id: 'id',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('name', {
    id: 'name',
    header: 'Name',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: 'arrIncludesSome',
    meta: { tooltip: true },
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, string>) => (
      <span className={`pl-${cellContext.row.depth * 4}`}>{cellContext.getValue()}</span>
    ),
  }),
  columnHelper.accessor('title', {
    id: 'title',
    filterFn: 'arrIncludesSome',
    enableColumnFilter: false,
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: 'Layout',
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, string>) =>
      BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === cellContext.row.original.type)?.label ?? '',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: (row, _, selectedFilters) => {
      if ((selectedFilters?.length ?? 0) === 0) {
        return true;
      }
      return selectedFilters.includes(
        BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.find((i) => i.value === row.original.type)?.label ?? '',
      );
    },
    meta: {
      filter: {
        options: BLOCK_LAYOUTS_VALUE_TO_LABEL_MAPPING.map(({ label }) => label),
      },
      tooltip: true,
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('clause_type', {
    id: 'clause_type',
    header: 'Clause type',
    sortingFn: 'text',
    sortUndefined: -1,
    filterFn: (row, _, filterValue) => {
      if (!filterValue?.length) {
        return true;
      }
      return row.original.type === 'clause' && filterValue.includes(row.original.clause_type);
    },
    meta: {
      filter: {
        label: 'Clause types',
        placeholder: 'All clause types',
      },
      tooltip: true,
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('usedInTemplates', {
    id: 'usedInTemplates',
    enableColumnFilter: false,
    enableSorting: false,
    header: 'Used within',
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, unknown>) => (
      <ItemUsedWithin item={cellContext.row.original} />
    ),
    meta: {
      dataCellClassName: 'group',
      headingCellClassName: 'w-32',
    },
  }),
  columnHelper.accessor('created_at', {
    id: 'created_at',
    header: 'Created at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, string>) =>
      formatDate(cellContext.row.original.created_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('updated_at', {
    id: 'updated_at',
    header: 'Updated at',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, string>) =>
      formatDate(cellContext.row.original.updated_at ?? '', 'DD MMM YYYY HH:mm'),
    sortingFn: 'datetime',
    sortUndefined: -1,
    meta: {
      headingCellClassName: 'w-40',
    },
  }),
  columnHelper.accessor('deleted_at', {
    id: 'deleted_at',
    enableSorting: true,
    filterFn: (row, _, shouldShowArchived) => (shouldShowArchived ? true : !row.original.deleted_at),
    meta: {
      filter: {
        label: 'Show archived',
        type: 'checkbox',
      },
    },
  }),
  columnHelper.display({
    id: 'edit',
    enableColumnFilter: false,
    cell: (cellContext: CellContext<LibraryBlockTypeForListPage, unknown>) => {
      const { canEditOnTemplate, id } = cellContext.row.original;
      const isUserSuperAdmin = user && isSuperadmin(user);

      const isDisabled = !canEditOnTemplate && !isUserSuperAdmin;
      return (
        <Tooltip content="This block can only by edited by a superadmin" disabled={!isDisabled}>
          <button
            className={clsx(
              'cursor-pointer truncate whitespace-nowrap py-4 pl-3 pr-2 text-right text-sm font-medium text-primary-600 shadow-none transition-colors hover:text-primary-900 disabled:cursor-not-allowed disabled:opacity-50',
            )}
            disabled={isDisabled}
            onClick={handleEditBlockClick?.call(null, id)}
          >
            Edit
          </button>
        </Tooltip>
      );
    },
    meta: {
      dataCellClassName:
        'relative !w-20 truncate whitespace-nowrap pl-3 pr-4 !py-0 text-right align-top text-sm font-medium',
      headingCellClassName: 'relative w-20 py-3.5 pl-3 pr-4 sm:pr-6',
    },
  }),
];
