import type { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

interface BannerProps {
  className?: string;
}

export const Banner: FC<PropsWithChildren<BannerProps>> = ({ children, className }) => {
  return (
    <div
      className={clsx(
        'flex items-center gap-3.5 rounded-sm bg-gray-100 py-5 pl-[62px] pr-4 text-xs font-normal text-gray-900 shadow-lg',
        className,
      )}
    >
      {children}
    </div>
  );
};
