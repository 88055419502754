import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import type { Images } from '@domain/schemas/images.schema';
import { mdiImageMove } from '@mdi/js';
import { bytesToHumanReadable } from '@root/src/utils/bytes-to-human-readable';

interface BrandingImageProps {
  image: Images;
  handleImageInsert?: () => void;
  contextMenu: ReactNode;
}

export const BrandingImage: FC<BrandingImageProps> = ({ contextMenu, image, handleImageInsert }) => {
  const size = useMemo(() => bytesToHumanReadable(image.size, true, 0), [image.size]);
  const title = image.fileName;

  return (
    <div
      className="micro-interactions group flex w-full  cursor-pointer items-center gap-2 py-1 pl-2 pr-4 first:pt-2 last:pb-2 hover:bg-primary-300/20"
      title={title}
    >
      <div className="relative flex-shrink-0">
        <img src={image.url} alt={image.fileName} className="h-[40px] w-[60px] border border-info-300 object-contain" />
        <Show when={!!handleImageInsert}>
          <div className="micro-interactions absolute inset-0 z-10 flex h-full w-full items-center justify-center rounded-sm opacity-0 group-hover:opacity-100">
            <button
              className="flex h-[40px] w-[60px] items-center justify-center gap-1 bg-white/90 py-2"
              onClick={handleImageInsert}
              title={'Insert image to editor'}
            >
              <IconMdi path={mdiImageMove} className="text-primary-500" />
            </button>
          </div>
        </Show>
      </div>
      <div className="flex w-3/5 flex-col gap-1">
        <span className="truncate font-medium leading-tight">{title}</span>
        <span className="leading-tight text-info-500">{size}</span>
      </div>
      <div className="w-1/12">
        <div data-testid={`image-list-menu:${image.id}`}>{contextMenu}</div>
      </div>
    </div>
  );
};
