import { createEndorsementSlice } from '@ContractBuilder/store/endorsement.slice.store';
import { getDocumentMode, isEndorsement } from '@helpers/getDocumentMode';
import { patchEntity } from '@mutations/patchEntity';
import { fetchEntity } from '@queries/fetchEntity';
import type { IncludeFieldSet } from '@root/@types/api';
import type { ApplyEndorsementDataInput, UpdateResourceDataApiPayload } from '@root/@types/types';
import { ApiPatchOperation, DocumentMode } from '@root/@types/types';
import { reorderLayout } from '@root/helpers';
import { getResourcesListToFetch } from '@root/src/utils/get-resources-list-to-fetch';
import { isEqual } from 'lodash-es';
import { createWithEqualityFn } from 'zustand/traditional';

import { createAttachmentsSlice } from './attachments.slice.store';
import { createCommentsSlice } from './comments.slice.store';
import { createFormCollapsedSlice } from './form-collapsed.slice.store';
import { createHighlightSlice } from './highlight.slice.store';
import { createLibrarySlice } from './library.slice.store';
import { createModalsSlice } from './modals.slice.store';
import { createRevisionSlice } from './revision.slice.store';
import { createSectionSlice } from './sections.slice.store';
import type { MainStore } from './types';
import { updateFormValuesInForeignStore, updateSchemaInForeignStore } from './utils';

type GetOperationPayload<T> = T extends ApiPatchOperation.ApplyEndorsement
  ? ApplyEndorsementDataInput
  : UpdateResourceDataApiPayload;

export const useEntityStore = createWithEqualityFn<MainStore>(
  (set, get, ...rest) => ({
    ...createAttachmentsSlice(set, get, ...rest),
    ...createFormCollapsedSlice(set, get, ...rest),
    ...createHighlightSlice(set, get, ...rest),
    ...createModalsSlice(set, get, ...rest),
    ...createRevisionSlice(set, get, ...rest),
    ...createSectionSlice(set, get, ...rest),
    ...createCommentsSlice(set, get, ...rest),
    ...createLibrarySlice(set, get, ...rest),
    ...createEndorsementSlice(set, get, ...rest),
    isLoading: false,
    resetState: () => {
      set({
        attachments: [],
        submission: undefined,
        endorsementParent: undefined,
        sections: {},
        settings: { open: false },
        modal: { open: false },
      });
    },
    reloadSubmission: async (range, revisionId) => {
      const defaultFetchRange: IncludeFieldSet[] = getResourcesListToFetch(isEndorsement(location.pathname));

      const parsedRange = range ?? defaultFetchRange;

      set({ isLoading: true });
      const submission = await fetchEntity({ dataRange: parsedRange, revisionId });
      const parentId = submission?.parentId;

      if (parentId) {
        const response = await fetchEntity({
          dataRange: parsedRange,
          documentMode: DocumentMode.SUBMISSIONS,
        });

        set({ submission, endorsementParent: response });
      } else {
        set({ submission });
      }

      set({ loadingBlockId: undefined });

      get().reloadAttachments();

      updateSchemaInForeignStore(submission.data_items);
      updateFormValuesInForeignStore(submission.data_items);

      set({ isLoading: false });
      return submission;
    },
    createBlock: async (block, prevBlockOrder) => {
      const {
        blockLibraryId,
        clause_reference,
        clause_type,
        content,
        linkedDatapoints = [],
        name,
        repeaterIds,
        section_id,
        shouldInsertPageBreak,
        title,
        type,
        variations,
        canDelete = true,
        canEdit = true,
      } = block;
      const submission = get().submission;

      if (!submission) {
        return;
      }

      set({ isLoading: true });
      const result = await patchEntity({
        operation: ApiPatchOperation.CreateResourceBlock,
        blockLibraryId,
        canDelete,
        canEdit,
        clause_reference,
        clause_type,
        content,
        linkedDatapoints,
        name,
        prevBlockOrder,
        repeaterIds,
        section_id,
        shouldInsertPageBreak,
        title,
        type,
        variations,
      });

      if (result?.message === 'success') {
        await get().reloadSubmission();
        get().closeModal();
        get().highlightChangedBlock(result.blockId);
      }
      set({ isLoading: false });
    },

    updateBlock: async (block) => {
      const { id: blockId, ...blockPayload } = block;

      set({ loadingBlockId: blockId });
      const submission = get().submission;
      if (!submission) {
        return;
      }

      const newSection = submission.sections.find((section) => section.id === block.section_id);

      if (!newSection) {
        throw new Error(`Section "${block.section_id}" does not exist`);
      }

      set({ isLoading: true });

      const result = await patchEntity({
        operation: ApiPatchOperation.UpdateResourceBlock,
        blockId,
        block: blockPayload,
      });

      if (result?.message === 'success') {
        await get().reloadSubmission();
        get().closeModal();
        get().highlightChangedBlock(blockId);
      }

      set({ loadingBlockId: undefined, isLoading: false });
    },
    updateFormData: async (data_items: any) => {
      const submission = get().submission;

      if (!submission) {
        return;
      }

      set({ isLoading: true });
      const result = await patchEntity({
        operation: ApiPatchOperation.UpdateResourceData,
        data_items,
      });

      if (result?.message === 'success') {
        await get().reloadSubmission();
      }

      set({ isLoading: false });
    },
    updateResourceData: async (data, id) => {
      const submission = get().submission;
      const submissionId = id ?? submission?.id;

      if (!submissionId) {
        return;
      }

      const { mode, ids } = getDocumentMode(location.pathname);
      const isApplyEndorsement = mode === DocumentMode.ENDORSEMENTS && data?.status === 'FINAL';
      const operation = isApplyEndorsement ? ApiPatchOperation.ApplyEndorsement : ApiPatchOperation.UpdateResourceData;

      const payload = {
        operation,
        ...data,
      } as GetOperationPayload<typeof operation>;

      set({ isLoading: true });
      const result = await patchEntity(payload, id);

      if (submission) {
        await get().reloadSubmission();
      }

      set({ isLoading: false });

      if (isApplyEndorsement) {
        const win = window.open(`/submissions/${ids[0]}/contract`, '_self');
        win?.focus();
      }
      return result;
    },
    deleteBlock: async (blockId, shouldReloadSubmission = true) => {
      set({ loadingBlockId: blockId });
      const submission = get().submission;

      if (!submission) {
        return;
      }

      set({ isLoading: true });

      const result = await patchEntity({
        operation: ApiPatchOperation.DeleteResourceBlock,
        blockId,
      });

      if (result?.message === 'success' && shouldReloadSubmission) {
        await get().reloadSubmission();
      }

      set({ isLoading: false });
    },

    reorderBlock: async (result) => {
      const { destination, draggableId: blockId, source } = result;
      const submission = get().submission;
      const blockSection = submission?.sections.find((section) => section.blocks.some((item) => item.id === blockId));
      const blockBeingMoved = blockSection?.blocks.find((block) => block.id === blockId);

      if (!destination || !submission || !blockSection || !blockBeingMoved) {
        return;
      }

      const destinationSectionId = source.droppableId !== destination.droppableId ? destination.droppableId : undefined;
      const isMovingBetweenSections = !!destinationSectionId && destinationSectionId !== blockSection.id;

      const updatedSectionLayout = reorderLayout(
        blockSection.layout,
        blockId,
        isMovingBetweenSections ? -1 : destination.index,
      );

      // Optimistically update the UI immediately after drag'n'drop
      set({
        submission: {
          ...submission,
          sections: submission.sections.map((item) => {
            if (item.id === blockSection.id) {
              return {
                ...item,
                layout: updatedSectionLayout,
                blocks: isMovingBetweenSections ? item.blocks.filter((item) => item.id !== blockId) : item.blocks,
              };
            }

            // Note: Can only happen if destinationSectionId !== blockSection.id
            if (item.id === destinationSectionId) {
              return {
                ...item,
                layout: reorderLayout(item.layout, result.draggableId, destination.index),
                blocks: [...item.blocks, blockBeingMoved],
              };
            }

            return item;
          }),
        },
      });

      set({ isLoading: true });
      await patchEntity({
        blockId,
        reorder: { destinationIndex: destination.index, destinationSectionId },
        operation: ApiPatchOperation.ReorderResourceBlock,
      });

      await get().reloadSubmission();
      set({ isLoading: false });
    },
    changeDocumentBase: async (mode, sourceResourceId) => {
      const doc = get().submission;
      if (!doc) {
        return;
      }

      set({ isLoading: true });

      const operation =
        mode === DocumentMode.TEMPLATES ? ApiPatchOperation.ChangeBaseTemplate : ApiPatchOperation.ChangeBaseSubmission;

      const result = await patchEntity({ operation, sourceResourceId });

      if (result?.message === 'success') {
        await get().reloadSubmission();
      }
      set({ isLoading: false });
    },
  }),
  isEqual,
);
