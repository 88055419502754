import { useEntityStore } from '@ContractBuilder/store';

export const useBlockActions = () => {
  const {
    addBlockFromLibrary,
    createBlock,
    createBlockInLibrary,
    createSummaryBlock,
    deleteBlock,
    deleteSummaryBlock,
    hideBlockFromPreview,
    replaceBlockForBlockInLibrary,
    restoreAllBlocksHiddenFromPreview,
    updateBlock,
    updateBlockInLibrary,
    updateSummaryBlock,
  } = useEntityStore(
    ({
      addBlockFromLibrary,
      createBlock,
      createBlockInLibrary,
      createSummaryBlock,
      deleteBlock,
      deleteSummaryBlock,
      hideBlockFromPreview,
      replaceBlockForBlockInLibrary,
      restoreAllBlocksHiddenFromPreview,
      updateBlock,
      updateBlockInLibrary,
      updateSummaryBlock,
    }) => ({
      addBlockFromLibrary,
      createBlock,
      createBlockInLibrary,
      createSummaryBlock,
      deleteBlock,
      deleteSummaryBlock,
      hideBlockFromPreview,
      replaceBlockForBlockInLibrary,
      restoreAllBlocksHiddenFromPreview,
      updateBlock,
      updateBlockInLibrary,
      updateSummaryBlock,
    }),
  );

  return {
    addBlockFromLibrary,
    createBlock,
    createBlockInLibrary,
    createSummaryBlock,
    deleteBlock,
    deleteSummaryBlock,
    hideBlockFromPreview,
    replaceBlockForBlockInLibrary,
    restoreAllBlocksHiddenFromPreview,
    updateBlock,
    updateBlockInLibrary,
    updateSummaryBlock,
  };
};
