import type { LibraryBlockTypeForListPage } from '@ContractBuilder/types';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

type Params = { [key: string]: string };
const getParams = (searchParams: URLSearchParams, params?: Params) => (paramKey: string) =>
  params?.[paramKey] ?? searchParams.get(paramKey);

export const fetchBlocks = async (_authToken: string, params?: Params): Promise<LibraryBlockTypeForListPage[]> => {
  const { searchParams } = new URL(location.href);

  const getParamValue = getParams(searchParams, params);
  const page = getParamValue('page');
  const limit = getParamValue('limit');
  const sortBy = getParamValue('sortBy');
  const sortDirection = getParamValue('sortDirection');
  const filters = getParamValue('filters');
  const search = getParamValue('search') ?? '';

  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    params: { page, limit, sortBy, sortDirection, filters, search },
    url: '/blocks-spa-al',
  });

  const response = await axios(networkPayload);
  return response.data;
};
