import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import type { EntityData } from '@ContractBuilder/types';
import { fetchRenewals } from '@queries/fetchRenewals';

import type { UseQueryRefetchFn, UseQueryStatus } from '../../@types/types';

interface RenewalsDataHook {
  data: EntityData[] | undefined;
  status: UseQueryStatus;
  isFetching: boolean;
  refetch: UseQueryRefetchFn<any, any>;
}

export const useRenewalsData = (options: UseQueryOptions = {}): RenewalsDataHook => {
  const { id = '' } = useParams<{ id: string }>();
  const { data, status, isFetching, refetch } = useQuery(['renewals', id], () => fetchRenewals(id), {
    // @ts-expect-error
    refetchOnWindowFocus: false,
    ...options,
  });

  return {
    data: data as EntityData[],
    status,
    isFetching,
    refetch,
  };
};
