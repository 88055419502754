import { useEntityStore } from '@ContractBuilder/store';
import { ViewOriginalContractBanner } from '@features/renewals-banner/views/ViewOriginalContractBanner';
import { isNonNullish } from '@root/helpers';

import { ContractRenewedBanner } from '../views/ContractRenewedBanner';
import { RenewalsBannerLoading } from '../views/RenewalsBannerLoading';

export const RenewalsBannerController = () => {
  const submission = useEntityStore(({ submission }) => submission);

  if (!submission) {
    return <RenewalsBannerLoading />;
  }

  const { name, renewalParentId, renewals } = submission;
  const hasBeenRenewed = isNonNullish(renewalParentId);

  if (hasBeenRenewed) {
    return <ViewOriginalContractBanner name={name} parentId={renewalParentId} />;
  }

  const submissionRenewals = renewals ?? [];

  if (submissionRenewals.length > 0) {
    return <ContractRenewedBanner />;
  }

  return null;
};
