import React from 'react';
import { useQuery } from 'react-query';
import { useBrandingOptions } from '@features/create-entity-modal';
import type { Action } from '@root/@types/types';
import { SUBMISSION_STATUSES } from '@root/@types/types';
import withMenuLayout from '@root/src/layout/withMenuLayout';
import { useFeatureFlags, useModal } from '@src/hooks';
import { fetchSubmissions, fetchTags } from '@src/queries';

import { MODALS } from '../../constants';

import { getSubmissionsTableColumns } from './helpers/columns/getSubmissionsTableColumns';
import { ReactTable } from './components';

const isEntra = import.meta.env?.VITE_AUTH_METHOD === 'entra';
const initialFilters = isEntra
  ? []
  : [{ id: 'status', value: SUBMISSION_STATUSES.filter((status) => status !== 'ARCHIVED') }];

export const Submissions: React.FC = () => {
  const { showModal } = useModal();

  const {
    data = [],
    isLoading: isSubmissionsLoading,
    status,
  } = useQuery(['submissions'], fetchSubmissions, { refetchOnWindowFocus: false });
  const { data: tags = [], isLoading: isTagsLoading } = useQuery(['tags'], fetchTags, { refetchOnWindowFocus: false });

  const { brandingFieldName, data: brandingOptions = [] } = useBrandingOptions();

  const getFeatureFlagState = useFeatureFlags();
  const hasEndorsementsFeature = getFeatureFlagState('Endorsements') ?? false;

  const columns = getSubmissionsTableColumns({
    brandingFieldName,
    brandingOptions,
    disableStatus: isEntra,
    hasEndorsementsFeature,
    tags,
  });

  const actions: Action[] = [
    {
      key: 'add_submission',
      label: 'New Submission',
      onClick: () => showModal(MODALS.ADMIN_CREATE_TEMPLATE, { data }),
    },
  ];

  return (
    <ReactTable
      actions={actions}
      columns={columns}
      columnVisibility={{
        id: false,
        class_of_business: false,
        created_at: false,
        created_by: false,
        sourceResourceDisplayName: false,
        [brandingFieldName]: false,
      }}
      entitiesLabel="submissions"
      filteredData={data}
      initialFilters={initialFilters}
      initialSorting={[{ id: 'created_at', desc: true }]}
      status={status}
      isLoading={isSubmissionsLoading || isTagsLoading}
    />
  );
};

export const UserSubmissionsPage = withMenuLayout(Submissions);
