import type { EntityData } from '@ContractBuilder/types';
import type { BrandingTransformedDb } from '@domain/schemas/branding.schema';
import type { PdfDocumentType, PdfGeneratedDocumentName, Template } from '@root/@types/types';
import { v4 as uuidv4 } from 'uuid';

/**
 * @deprecated
 */
export const getS3PathFromParameters = (
  documentName: string,
  templateId?: string,
  submissionId?: string,
  endorsementId?: string,
): string => {
  let submissionOrTemplateId = '';

  if (templateId) {
    submissionOrTemplateId = templateId;
  }
  if (submissionId && !endorsementId) {
    submissionOrTemplateId = submissionId;
  }
  if (submissionId && endorsementId) {
    submissionOrTemplateId = submissionId;
  }

  let s3FileName = '';
  if (endorsementId) {
    s3FileName = `${submissionOrTemplateId}/${endorsementId}/${documentName}`;
  } else {
    s3FileName = `${submissionOrTemplateId}/${documentName}`;
  }

  return s3FileName;
};

export const createS3Path = ({
  category,
  documentName,
  pdfDocumentType,
  submissionOrTemplateId,
  endorsementId,
  brandingId,
}: {
  category: 'templates' | 'submissions' | 'endorsements' | 'brandings';
  documentName: string;
  pdfDocumentType: PdfDocumentType;
  submissionOrTemplateId?: string;
  endorsementId?: string;
  brandingId?: string;
}): string => {
  switch (category) {
    case 'brandings':
      return `${brandingId}/${documentName}`;
    case 'endorsements':
      return `${submissionOrTemplateId}/${endorsementId}/${pdfDocumentType}/${documentName}`;
    default:
      return `${submissionOrTemplateId}/${pdfDocumentType}/${documentName}`;
  }
};

export const getPdfDocumentName = (item: EntityData | Template | BrandingTransformedDb): PdfGeneratedDocumentName => {
  let version = Number(item?.version ?? 1) || 1;
  if (version > Number.MAX_SAFE_INTEGER) {
    version = Number.MAX_SAFE_INTEGER;
  }
  if (version < 1) {
    version = 1;
  }

  const documentType = 'document_type' in item ? item?.document_type : '';

  let documentName = '';
  switch (documentType) {
    case 'MRC':
    default: {
      documentName = item?.name ?? '';
      if ('class_of_business' in item) {
        documentName += item?.class_of_business ? ` ${item?.class_of_business}` : '';
      }
    }
  }

  const longName = `${documentName} - ${version}`;
  const shortName = `${uuidv4()}-v${version}`;

  return { shortName, longName };
};
