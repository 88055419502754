import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Link, useBeforeUnload } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import { TooltipedIcon } from '@components/TooltipedIcon';
import MODALS from '@constants/modals';
import { DocumentStatusUpdate } from '@ContractBuilder/components/Notifications';
import PublishingOptions from '@ContractBuilder/components/PublishingOptions';
import { CircleIconWrapper } from '@ContractBuilder/contract-builder-header/components/CircleIconWrapper';
import { useUIStore } from '@ContractBuilder/store/ui.store';
import type { Branding } from '@domain/schemas/branding.schema';
import { ArrowLeftIcon, DocumentSearchIcon } from '@heroicons/react/outline';
import { useHistoryBackTrap } from '@hooks/use-history-back-trap';
import { mdiHistory } from '@mdi/js';
import Nav from '@root/src/routes';
import sharedClasses from '@root/src/utils/shared-classes';
import clsx from 'clsx';

import { useBrandingStore } from '../store/branding.store';
import { getPublishingOptions } from '../utils/get-publishing-options';

import { BrandingEditableNameField } from './BrandingEditableNameField';

export const BrandingDetailsHeader: FC = () => {
  const {
    branding,
    isLoading,
    dateInfo,
    hasOperationsDisabled,
    form: { editorKey, isDirty },
    updateStatus,
    saveNameChange,
  } = useBrandingStore(
    ({ branding, isLoading, dateInfo, hasOperationsDisabled, form, updateStatus, saveNameChange }) => ({
      branding,
      isLoading,
      dateInfo,
      hasOperationsDisabled,
      form,
      updateStatus,
      saveNameChange,
    }),
  );

  const { togglePDFPreview, isPDFPreviewOpen } = useUIStore();

  const isEditing = !!editorKey;
  const canNavigate = !(isDirty || isEditing) && !isLoading;

  const handleNameChange = async (_field: string, value?: string) => {
    if (!value) return;
    const result = await saveNameChange(value);
    if (!result.success) {
      toast.error(result.error || 'Error saving branding name');
    }
  };

  const handleUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!canNavigate) {
        event.preventDefault();
        return;
      }
    },
    [canNavigate],
  );

  const handleTrap = useCallback(
    async (resume: () => void) => {
      if (!canNavigate) {
        return false;
      } else {
        resume();
        return true;
      }
    },

    [canNavigate],
  );

  useHistoryBackTrap(handleTrap);
  useBeforeUnload(handleUnload);

  const handlePreviewPdf = () => {
    togglePDFPreview();
  };

  const handleStatusUpdate = async (status: Branding['status']) => {
    const response = await updateStatus(status);
    if (response?.success) {
      toast(<DocumentStatusUpdate status={status} />, { type: 'success' });
    }
  };

  const publishingOptions = useMemo(() => getPublishingOptions(branding?.status), [branding?.status]);

  return (
    <div className="flex flex-col border-b bg-white px-5 py-5">
      <div className="flex w-full flex-nowrap items-center gap-3">
        <div className={clsx(!canNavigate && sharedClasses.disabledContainer)}>
          <Link to={Nav.Branding} className={clsx('group', !canNavigate && sharedClasses.disabled)}>
            <div
              title={'Back to Branding'}
              className={'mr-2 rounded-full border-2 border-info-600 p-1 group-hover:bg-gray-100'}
            >
              <ArrowLeftIcon className="h-4 w-4 text-info-600" strokeWidth="3" />
            </div>
          </Link>
        </div>
        <Show when={!!branding}>
          <div className="flex min-w-0 items-center gap-4 2xl:flex-nowrap">
            <BrandingEditableNameField
              value={branding?.name}
              hasOperationsDisabled={hasOperationsDisabled}
              onSave={handleNameChange}
            />
            <TooltipedIcon
              content={dateInfo}
              maxWidth={200}
              path={mdiHistory}
              className="cursor-default text-info-400 2xl:hidden"
              size={0.7}
            />
            <p className="hidden cursor-default whitespace-nowrap text-sm font-light text-info-400 2xl:block">
              {dateInfo}
            </p>
          </div>
        </Show>
        <div id="tools" className="ml-auto flex w-fit flex-row place-items-center gap-5">
          <Tooltip content="Preview PDF" disabled={false}>
            <CircleIconWrapper
              data-cypress="preview-pdf-button"
              icon={DocumentSearchIcon}
              isActive={isPDFPreviewOpen}
              onClick={handlePreviewPdf}
            />
          </Tooltip>
          <PublishingOptions
            modal={MODALS.CONFIRM_STATUS_FINAL_BRANDING}
            isDisabled={false}
            onStatusUpdate={handleStatusUpdate as any}
            publishingOptions={publishingOptions}
            status={branding?.status}
          />
        </div>
      </div>
    </div>
  );
};
