import { useMemo } from 'react';
import type { ResourceBlock } from '@root/@types/base';

import type { Section as SectionType } from '../../../../types';

interface UseBlocksArgs {
  pdfBlocks?: ResourceBlock[];
  section: SectionType;
}

interface UseBlocksReturns {
  blocks: ResourceBlock[];
}

export const useBlocks = ({ pdfBlocks, section }: UseBlocksArgs): UseBlocksReturns => {
  const { blocks, layout = [] } = section;

  const sorted = useMemo(() => {
    return Array.from(pdfBlocks ?? blocks).sort((a, b) => {
      const aIndex = layout.indexOf(a.id);
      const bIndex = layout.indexOf(b.id);

      // Move blocks which do not exist on a layout entity to the bottom of a section
      if (aIndex === -1) {
        return 1;
      }

      if (bIndex === -1) {
        return -1;
      }

      return aIndex - bIndex;
    });
  }, [pdfBlocks, blocks, layout]);

  return {
    blocks: sorted,
  };
};
