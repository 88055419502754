import type { Images } from '@domain/schemas/images.schema';
import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import axios from 'axios';

const IMAGES_URI = 'images';

export const getImages = async (): Promise<Images[]> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'get',
    url: `${import.meta.env.VITE_API_URL}/${IMAGES_URI}`,
  });

  const response = await axios(networkPayload);
  return response.data;
};
