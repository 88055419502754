import type { FC } from 'react';
import Button from '@components/Button';

import { useBrandingStore } from '../store/branding.store';

interface BrandingDetailsEditorBottomControlsProps {
  onCancel: () => void;
}

export const BrandingDetailsEditorBottomControls: FC<BrandingDetailsEditorBottomControlsProps> = ({ onCancel }) => {
  const {
    form: { isDirty },
    isLoading,
    saveChanges,
  } = useBrandingStore(({ form, isLoading, saveChanges }) => ({ form, isLoading, saveChanges }));

  return (
    <div className="w-full border-t bg-white py-3">
      <div className="ml-auto mr-4 flex w-fit gap-4">
        <Button
          kind="secondary"
          className="h-9 min-w-[80px]"
          isDisabled={isLoading}
          loading={isLoading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          kind="primary"
          className="h-9 min-w-[80px]"
          isDisabled={!isDirty}
          loading={isLoading}
          onClick={saveChanges}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
