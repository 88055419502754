import { getRequestAuthPayload } from '@helpers/getRequestAuth';
import type { CreateRenewalPayload, Submission } from '@root/@types/types';
import axios from 'axios';

export const createRenewal = async (data: CreateRenewalPayload): Promise<Pick<Submission, 'id'>> => {
  const networkPayload = await getRequestAuthPayload({
    method: 'post',
    url: `submissions-spa-al`,
    data,
  });

  const response = await axios(networkPayload);
  return response?.data;
};
