import type { FC } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { PUBLISHING_OPTIONS_DROPDOWN_Z_INDEX } from '@constants/z-indices';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import type { RootResourceStatus } from '@root/@types/types';
import { MODALS } from '@src/constants';
import { useModal } from '@src/hooks';
import sharedClasses from '@src/utils/shared-classes';
import clsx from 'clsx';

import type { PublishingOptionType, StatusType } from '../types';

type PublishingOptionsPropsType = {
  modal?: MODALS;
  status?: StatusType;
  isDisabled: boolean;
  isLoading?: boolean;
  onStatusUpdate: (status: RootResourceStatus) => Promise<void>;
  publishingOptions: PublishingOptionType[];
};

const STATUSES_TO_CONFIRM: StatusType[] = ['FINAL', 'PUBLISHED'];

const filterPublishingOptions = (publishingOptions: PublishingOptionType[], status?: string) => {
  if (status === 'ARCHIVED') {
    return publishingOptions.filter(({ id }) => id !== 'FINAL');
  }

  return publishingOptions;
};

const PublishingOptions: FC<PublishingOptionsPropsType> = ({
  status,
  isDisabled,
  isLoading = false,
  onStatusUpdate,
  publishingOptions,
  modal = MODALS.CONFIRM_STATUS_FINAL,
}) => {
  const [selectedPublishingOption, setSelectedPublishingOption] = useState(
    publishingOptions.find((option) => option.id === status) ?? publishingOptions[0],
  );
  const { showModal } = useModal();

  // eslint-disable-next-line -- We only care about `status` changes
  const filteredPublishingOptions = useMemo(() => filterPublishingOptions(publishingOptions, status), [status]);

  useEffect(() => {
    setSelectedPublishingOption(
      filteredPublishingOptions.find(({ id }) => id === status) ?? filteredPublishingOptions[0],
    );
    // eslint-disable-next-line -- We only care about `status` changes
  }, [status]);

  const handleStatusUpdate = useCallback((id: RootResourceStatus) => onStatusUpdate(id), [onStatusUpdate]);

  const handleVisibilityStatusUpdate = (publishingOption: PublishingOptionType) => {
    if (STATUSES_TO_CONFIRM.includes(publishingOption.id)) {
      showModal(modal, {
        onConfirm: () => handleStatusUpdate(publishingOption.id),
        status: publishingOption.title,
      });
      return;
    }

    setSelectedPublishingOption(publishingOption);
    onStatusUpdate(publishingOption.id);
  };

  return (
    <Listbox disabled={isDisabled} value={selectedPublishingOption} onChange={handleVisibilityStatusUpdate}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only" data-testid="PublishingOptions:Status">
            Change published status
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-primary-600 rounded-md shadow-sm">
              <div className="relative z-0 inline-flex divide-x divide-primary-600 rounded-md shadow-sm">
                <div
                  className={clsx(
                    isDisabled && sharedClasses.disabled,
                    'relative inline-flex items-center rounded-l-md border border-transparent bg-primary-500 py-1 pl-3 pr-4 text-white shadow-sm',
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  <p data-testid={selectedPublishingOption.title} className="ml-2.5 text-sm font-medium">
                    {selectedPublishingOption.title}
                  </p>
                </div>
                <Listbox.Button
                  aria-label="Change published status"
                  aria-busy={isLoading}
                  data-testid="PublishingOptions:Button"
                  className={clsx(
                    'relative inline-flex h-9 items-center rounded-l-none rounded-r-md bg-primary-500 p-2 py-1 text-sm font-medium text-white hover:bg-primary-600 focus:z-10 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-info-50',
                    isDisabled && sharedClasses.disabled,
                  )}
                >
                  <span className="sr-only">Change published status</span>
                  <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(
                  'absolute right-0 mt-2 w-72 origin-top-right divide-y divide-info-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                  PUBLISHING_OPTIONS_DROPDOWN_Z_INDEX,
                )}
              >
                {filteredPublishingOptions.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      clsx(
                        active ? 'bg-primary-500 text-white' : 'text-info-900',
                        'relative cursor-default select-none p-4 text-sm',
                      )
                    }
                    value={option}
                    data-testid={`PublishingOption:${option.title}`}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col" data-testid={`PublishingOption:${option.title}`}>
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-primary-500'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={clsx(active ? 'text-primary-200' : 'text-info-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default PublishingOptions;
