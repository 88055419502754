import { DbSectionSchema } from '@domain/schemas/contract.schema';
import { baseDatabaseSchema } from '@domain/schemas/database.schema';
import { ApiPatchOperation } from '@root/@types/payloads';
import { z } from 'zod';

import { DbSubmissionBlockSchema } from './block.schema';

export const summaryBlockSchema = DbSubmissionBlockSchema.pick({
  content: true,
  helperText: true,
  linkedDatapoints: true,
  name: true,
  section_id: true,
  shouldInsertPageBreak: true,
  title: true,
  type: true,
}).strict();

export const summaryBlockCreateSchema = summaryBlockSchema
  .extend({
    order: z.number().default(0).optional(),
    operation: z.literal(ApiPatchOperation.CreateSummaryBlock),
  })
  .strip();

export const summaryBlockUpdateSchema = summaryBlockSchema
  .omit({ section_id: true })
  .partial()
  .extend({ blockId: z.string(), operation: z.literal(ApiPatchOperation.UpdateSummaryBlock) })
  .strip();

export const summaryBlockDeleteSchema = z.object({
  blockId: z.string(),
  operation: z.literal(ApiPatchOperation.DeleteSummaryBlock),
});

export const summaryBlockHideFromPreviewSchema = z.object({
  blockId: z.string(),
  operation: z.literal(ApiPatchOperation.HideBlockFromPreview),
});

export const summaryBlockDatabaseSchema = summaryBlockSchema.merge(baseDatabaseSchema);

const summarySectionPositionSchema = z.enum(['start', 'end']);

export const summarySectionSchema = DbSectionSchema.pick({
  label: true,
  layout: true,
  should_reset_page_counter: true,
  should_hide_title_in_pdf: true,
}).extend({ position: summarySectionPositionSchema.default('end') });

export const summarySectionDatabaseSchema = summarySectionSchema.merge(baseDatabaseSchema);

export const summarySectionDeleteSchema = z.object({
  sectionId: z.string(),
  operation: z.literal(ApiPatchOperation.DeleteSummarySection),
});

export const restoreBlocksHiddenFromPreviewSchema = z.object({
  operation: z.literal(ApiPatchOperation.RestoreBlocksHiddenFromPreview),
});

export type SummaryBlock = z.infer<typeof summaryBlockSchema>;
export type SummaryBlockCreate = z.infer<typeof summaryBlockCreateSchema>;
export type SummaryBlockUpdate = z.infer<typeof summaryBlockUpdateSchema>;
export type SummaryBlockDelete = z.infer<typeof summaryBlockDeleteSchema>;
export type SummaryBlockDb = z.infer<typeof summaryBlockDatabaseSchema>;
export type SummaryBlockHideFromPreview = z.infer<typeof summaryBlockHideFromPreviewSchema>;
export type RestoreBlocksHiddenFromPreview = z.infer<typeof restoreBlocksHiddenFromPreviewSchema>;

export type SummarySection = z.infer<typeof summarySectionSchema>;
export type SummarySectionDb = z.infer<typeof summarySectionDatabaseSchema>;
export type SummarySectionDelete = z.infer<typeof summarySectionDeleteSchema>;
export type SummarySectionPosition = z.infer<typeof summarySectionPositionSchema>;
