import type { FC } from 'react';
import { useUserStore } from '@Auth/store';
import Form from '@components/Form';
import InputSelect from '@components/InputSelect';
import InputTextarea from '@components/InputTextarea';
import { Show } from '@components/Show';
import Tooltip from '@components/Tooltip';
import type { BlockEditFormState } from '@ContractBuilder/modules/block-edit';
import { useBlockEditFormStore } from '@ContractBuilder/modules/block-edit';
import { crossCheckBlockUserCtx } from '@ContractBuilder/rules/block/cross-check-block-user-ctx';
import { getDocumentContext } from '@ContractBuilder/utils/get-document-context';
import { getLockedBlockPermissionsMessage } from '@ContractBuilder/utils/get-locked-block-permissions-message';
import { mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import type { BlockType, LinkedDatapoint, LinkedDatapoints, UIInputValue, UISelectionType } from '@root/@types/types';
import { CLAUSE_TYPES } from '@root/helpers';
import { isSuperadmin } from '@root/helpers/permissions';
import Button from '@src/components/Button';
import InputText from '@src/components/InputText';
import LayoutOptions from '@src/components/Sidebar/BlockLayoutSelector/LayoutSelector';
import Toggle from '@src/components/Toggle';
import { MODALS } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import { useModal } from '@src/hooks/useModal';
import { useLanguage } from '@src/language';
import { isBlocksPath, isTemplatePath } from '@utils/app-paths';
import { get } from 'lodash-es';

import { useVariationsTabs } from '../../variations-tabs/context/context';
import { DrawerBlockName } from '../components/DrawerBlockName';
import { DrawerSection } from '../components/DrawerSection';
import { VariationToggle } from '../components/VariationToggle';

import { LinkedDatapointsConfig } from './LinkedDatapointsConfig';
import { LinkedDatapointsStatus } from './LinkedDatapointsStatus';

export interface BlockConfigurationPanelProps {
  onRequiredDatapointChange: (id: string, isRequired: boolean) => void;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
  sectionOptions?: UISelectionType[];
}

export const BlockConfigurationPanel: FC<BlockConfigurationPanelProps> = ({
  onRequiredDatapointChange,
  onSubmit,
  onCancel,
  sectionOptions,
}) => {
  const { showModal } = useModal();
  const isTemplate = isTemplatePath();
  const isBlock = isBlocksPath();

  const { currentBlock, formValues, setFormValues } = useBlockEditFormStore(
    ({ currentBlock, formValues, setFormValues }) => ({
      currentBlock,
      formValues,
      setFormValues,
    }),
  );
  const user = useUserStore((state) => state.user);

  const documentContext = getDocumentContext();
  const isSuperAdminUser = isSuperadmin(user);

  const { getContent } = useLanguage({ prefix: 'naming.mrc.rightSidebar' });
  const { variationsCount, currentVariationIdx } = useVariationsTabs();
  const hasDataPointConfig = useFeatureFlags()('DataPointConfig');

  const handleSelectChange = (value: UIInputValue) => {
    return setFormValues((prev) => ({ ...prev, section_id: value }));
  };

  const onDelete = () =>
    showModal(MODALS.CONFIRM_DELETE, {
      id: formValues?.id,
      title: formValues?.name,
      type: 'block',
      callback: onCancel,
    });

  const handleTypeChange = (value: BlockType) => {
    const isClause = value === 'clause';
    const clauseResetPayload = isClause ? {} : { clause_type: undefined, clause_reference: undefined };

    setFormValues((prev) => ({ ...prev, ...clauseResetPayload, type: value }));
  };

  const toggleField = (field: keyof BlockEditFormState, fallback?: boolean) => () => {
    setFormValues((prev) => {
      const currentValue = get(prev, field, fallback);
      return { ...prev, [field]: !currentValue };
    });
  };

  const hasVariations = !!variationsCount;
  const missingDatapoints: string[] = [];
  let linkedDatapoints = hasVariations
    ? formValues?.variations?.[currentVariationIdx]?.linkedDatapoints
    : formValues?.linkedDatapoints ?? [];
  if (!isTemplate && !isBlock) {
    const initialDatapoints = hasVariations
      ? currentBlock?.variations?.[currentVariationIdx]?.linkedDatapoints
      : currentBlock?.linkedDatapoints ?? [];
    linkedDatapoints =
      initialDatapoints?.reduce((prev, curr: LinkedDatapoint) => {
        const foundDatapoint = linkedDatapoints?.find((i) => i.id === curr.id);
        if (curr.isRequired) {
          if (!foundDatapoint) {
            missingDatapoints.push(curr.id);
          }
          prev.push({ ...curr });
        } else {
          if (foundDatapoint) {
            prev.push({ ...foundDatapoint });
          }
        }
        return [...prev];
      }, [] as LinkedDatapoints) ?? [];
  }

  const isAdminView = isBlock || isTemplate;
  const { canBeDeletedWithCurrentPermissions } = crossCheckBlockUserCtx(currentBlock, documentContext, user);

  const isCreate = !formValues?.id;
  const isLayoutDisabled = !isCreate && hasVariations;
  const canDeleteBlock =
    canBeDeletedWithCurrentPermissions && (isAdminView || formValues?.canDelete || isSuperAdminUser);
  const hasSectionOptions = sectionOptions && sectionOptions.length > 0;

  const linkedDatapointsCopy = getContent(`fields.datapoints.${hasVariations ? 'variations' : 'single'}.label`);

  const canDeleteOnTemplate = formValues?.canDeleteOnTemplate ?? true;
  const canEditOnTemplate = formValues?.canEditOnTemplate ?? true;
  const canEdit = formValues?.canEdit ?? true;
  const canDelete = formValues?.canDelete ?? true;
  const enableSectionChange = false;

  return (
    <div className="flex h-full w-full flex-col overflow-x-hidden border-l bg-white p-5">
      <Form onSubmit={onSubmit}>
        <DrawerBlockName className="-mx-7" />
        {hasSectionOptions && enableSectionChange && (
          <DrawerSection title={getContent('fields.section.label')}>
            <InputSelect
              isSearchable={false}
              labelText=""
              options={sectionOptions}
              onChange={handleSelectChange}
              value={formValues?.section_id}
            />
          </DrawerSection>
        )}

        {hasDataPointConfig && (
          <DrawerSection title={linkedDatapointsCopy}>
            {isAdminView ? (
              <LinkedDatapointsConfig
                data={linkedDatapoints}
                onChange={onRequiredDatapointChange}
                currentVariationIdx={currentVariationIdx}
              />
            ) : (
              <LinkedDatapointsStatus data={linkedDatapoints} missingDatapoints={missingDatapoints} />
            )}
          </DrawerSection>
        )}

        {isAdminView && (
          <DrawerSection title={getContent('fields.instructions.label')}>
            <InputTextarea
              placeholder={getContent('fields.instructions.placeholder')}
              name="helperText"
              onChange={(event) => setFormValues((prev) => ({ ...prev, helperText: event.target.value }))}
              value={formValues?.helperText}
            />
          </DrawerSection>
        )}

        <DrawerSection title={getContent('fields.settings.label')} className="text-sm">
          {isAdminView && <VariationToggle label={getContent('fields.settings.variations.label')} />}
          <Toggle
            labelText={getContent('fields.settings.pageBreak.label')}
            value={Boolean(formValues?.shouldInsertPageBreak)}
            onClick={toggleField('shouldInsertPageBreak')}
            data-testid="toggle-page-break"
          />
          <Show when={isAdminView && isSuperAdminUser}>
            <Toggle
              labelText={getContent('fields.settings.superAdminEdit.label')}
              value={!canEditOnTemplate}
              onClick={toggleField('canEditOnTemplate', canEditOnTemplate)}
              data-testid="toggle-can-edit-by-superadmin"
            />
          </Show>
        </DrawerSection>

        <Show when={isTemplate && isSuperAdminUser}>
          <DrawerSection title={getContent('fields.templatePermissions.label')} className="text-sm">
            <Toggle
              labelText={getContent('fields.templatePermissions.canDelete.label')}
              value={canDeleteOnTemplate}
              onClick={toggleField('canDeleteOnTemplate', canDeleteOnTemplate)}
              data-testid="toggle-can-delete-from-template"
            />
          </DrawerSection>
        </Show>

        {isAdminView && (
          <DrawerSection title={getContent('fields.submissionPermissions.label')} className="text-sm">
            <Toggle
              labelText={getContent('fields.submissionPermissions.canEdit.label')}
              value={canEdit}
              onClick={toggleField('canEdit', canEdit)}
              data-testid="toggle-can-edit"
            />
            <Toggle
              labelText={getContent('fields.submissionPermissions.canDelete.label')}
              value={canDelete}
              onClick={toggleField('canDelete', canDelete)}
              data-testid="toggle-can-delete"
            />
          </DrawerSection>
        )}

        <DrawerSection title={getContent('fields.layout.label')}>
          <LayoutOptions
            onChange={handleTypeChange}
            type={formValues?.type ?? 'mrc-heading'}
            isDisabled={isLayoutDisabled}
          />
        </DrawerSection>
        {formValues?.type === 'clause' && (
          <>
            <DrawerSection title={getContent('fields.clause.type.label')}>
              <InputSelect
                labelText=""
                data-testid="clause-type"
                options={CLAUSE_TYPES.map((label) => ({ name: label, value: label }))}
                name="clause_type"
                value={formValues?.clause_type}
                onChange={(value) => setFormValues((prev) => ({ ...prev, clause_type: value }))}
              />
            </DrawerSection>
            <DrawerSection title={getContent('fields.clause.reference.label')}>
              <InputText
                labelText=""
                placeholder="Clause reference"
                name="clause_reference"
                value={formValues?.clause_reference}
                onChange={(event) => setFormValues((prev) => ({ ...prev, clause_reference: event.target.value }))}
              />
            </DrawerSection>
          </>
        )}
        <Show when={!isBlock}>
          <DrawerSection title={getContent('fields.actions.label')}>
            {canDeleteBlock ? (
              <Button kind="ghost" className="h-8 w-fit -translate-x-2.5 px-2 shadow-none" onClick={onDelete}>
                <Icon path={mdiTrashCanOutline} size={0.7} />
                {getContent('removeButton')}
              </Button>
            ) : (
              <Tooltip content={getLockedBlockPermissionsMessage(true, false)}>
                <span>
                  <Button isDisabled kind="ghost" className="h-8 w-fit -translate-x-2.5 px-2 shadow-none">
                    <Icon path={mdiTrashCanOutline} size={0.7} />
                    {getContent('removeButton')}
                  </Button>
                </span>
              </Tooltip>
            )}
          </DrawerSection>
        </Show>
      </Form>
    </div>
  );
};
