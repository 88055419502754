import type { ReactNode } from 'react';
import React from 'react';
import IconMdi from '@components/IconMdi';
import { Show } from '@components/Show';
import { mdiChevronDown, mdiClose, mdiFilter } from '@mdi/js';
import { GlobalSearch } from '@pages/User/components/GlobalSearch';

type ActionBarProps = {
  actions: any[];
  enableColumnFilters: boolean;
  entitiesLabel: string;
  shouldShowClearFilters: boolean;
  handleGlobalSearchClick: (searchTerm: string) => void;
  handleClearAllFiltersClick: () => void;
  toggleFiltersBarVisibility: () => void;
  renderCustomElements?: () => ReactNode;
};

export const ActionsBar = ({
  actions,
  entitiesLabel,
  handleGlobalSearchClick,
  shouldShowClearFilters,
  handleClearAllFiltersClick,
  enableColumnFilters,
  toggleFiltersBarVisibility,
  renderCustomElements,
}: ActionBarProps) => (
  <div className="mt-6 flex items-center justify-between gap-x-2">
    <div className="flex flex-auto shrink-0 self-start sm:mb-4 lg:mb-0">
      {actions?.map((action) => (
        <div key={action.key} className="md:pl-0">
          <button
            disabled={action.disabled}
            aria-disabled={action.disabled}
            type="button"
            className="!xl-mr-auto inline-flex items-end justify-center rounded-md border border-transparent bg-primary-600 px-5 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto"
            onClick={action.onClick}
          >
            {action.label}
          </button>
        </div>
      ))}
    </div>
    {renderCustomElements?.()}
    <Show when={shouldShowClearFilters}>
      <button
        onClick={handleClearAllFiltersClick}
        className="mr-4 flex h-full items-center justify-between px-2 py-3"
        data-testid="clear-all-filters"
      >
        <div className="ml-auto flex items-center gap-1">
          <IconMdi path={mdiClose} className="text-gray-500" />
          <span className="text-sm font-medium leading-5 text-gray-700">Clear all</span>
        </div>
      </button>
    </Show>
    <Show when={enableColumnFilters}>
      <button
        className="flex w-32 items-center justify-between border border-info-200 bg-info-50 p-2 py-2.5 hover:bg-opacity-75 md:rounded-lg"
        onClick={toggleFiltersBarVisibility}
        data-testid="toggle-filters-bar-visibility"
      >
        <div className="ml-auto flex items-center gap-1">
          <IconMdi path={mdiFilter} className="text-gray-400" />
          <span className="text-sm font-medium leading-5 text-gray-700">Filters</span>
        </div>
        <IconMdi className="ml-4" path={mdiChevronDown} />
      </button>
    </Show>
    <div className="ml-6 w-96">
      <GlobalSearch entitiesLabel={entitiesLabel} onSearchClick={handleGlobalSearchClick} />
    </div>
  </div>
);
