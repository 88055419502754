import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import Tooltip from '@components/Tooltip';
import clsx from 'clsx';

const ListIndividuallyIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10H18V8H0V10ZM0 14H18V12H0V14ZM0 6H18V4H0V6ZM0 0V2H18V0H0Z" fill="#9CA3AF" />
  </svg>
);

const GroupByParentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.85754 7.92308L1 10L4.85754 12.0769M4.85754 7.92308L10 10.6923L15.1425 7.92308M4.85754 7.92308L1 5.84615L10 1L19 5.84615L15.1425 7.92308M4.85754 12.0769L1 14.1538L10 19L19 14.1538L15.1425 12.0769M4.85754 12.0769L10 14.8462L15.1425 12.0769M15.1425 7.92308L19 10L15.1425 12.0769"
      stroke="#9CA3AF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

type ListTypesToggleProps = {
  isGroupByParent: boolean;
  setIsGroupByParent: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

const sharedButtonClasses = 'border border-info-200 bg-info-50 hover:bg-opacity-75';
const activeButtonClasses = '!bg-gray-300';

export const ListTypesToggle = ({ isGroupByParent, setIsGroupByParent, className = '' }: ListTypesToggleProps) => (
  <div className="mr-5 flex items-center justify-between" data-testid="listTypesToggle">
    <Tooltip content="List individually" placement="bottom">
      <button
        data-testid="listIndividually"
        type="button"
        className={clsx(
          sharedButtonClasses,
          'rounded-l-lg py-2.5 pl-2.5 pr-2',
          !isGroupByParent && activeButtonClasses,
          className,
        )}
        onClick={() => setIsGroupByParent(false)}
      >
        <ListIndividuallyIcon />
      </button>
    </Tooltip>
    <Tooltip content="Group by parent" placement="bottom">
      <button
        data-testid="groupByParent"
        type="button"
        className={clsx(sharedButtonClasses, 'rounded-r-lg p-2.5', isGroupByParent && activeButtonClasses, className)}
        onClick={() => setIsGroupByParent(true)}
      >
        <GroupByParentIcon />
      </button>
    </Tooltip>
  </div>
);
