import type { FC } from 'react';
import type { EntityData } from '@ContractBuilder/types';
import { useRenewalsData } from '@src/hooks';

import { ListRenewalsButton } from '../views/ListRenewalsButton';

interface RenewalsButtonControllerProps {
  submission: EntityData;
}

export const RenewalsButtonController: FC<RenewalsButtonControllerProps> = ({ submission }) => {
  const { data, isFetching } = useRenewalsData({
    refetchOnWindowFocus: false,
  });

  return <ListRenewalsButton data={data} isLoading={isFetching} submission={submission} />;
};
