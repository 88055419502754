import { z } from 'zod';

import { ApiPatchOperation } from '../../@types/payloads';

import type { PK, SK } from './database.schema';
import { baseDatabaseSchema } from './database.schema';

export const brandingSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  status: z.enum(['DRAFT', 'PUBLISHED', 'ARCHIVED']).default('DRAFT'),
  documents: z
    .array(
      z
        .object({
          name: z.string(),
          subtype: z.string().optional(),
          type: z.enum(['template-document', 'submission-document', 'endorsement-document', 'endorsement-summary']),
          version: z.number(),
          path: z.string().optional(),
        })
        .strict(),
    )
    .optional(),
  header_content: z.string(),
  header_content_transformed: z.string().optional(),
  footer_content: z.string(),
  footer_content_transformed: z.string().optional(),
  version: z.number().optional(),
  document_version: z.number().optional(),
});

/**
 * Payload schemas
 */

export const brandingCreateSchema = z
  .object({
    name: z.string(),
    clone_from_id: z.string().optional(),
  })
  .strict();

export const brandingUpdateNameSchema = brandingSchema
  .pick({ name: true })
  .extend({ operation: z.literal(ApiPatchOperation.UpdateName).default(ApiPatchOperation.UpdateName) });

export const brandingUpdateContentSchema = brandingSchema
  .pick({ header_content: true, footer_content: true })
  .extend({ operation: z.literal(ApiPatchOperation.UpdateContent).default(ApiPatchOperation.UpdateContent) });

export const brandingUpdateStatusSchema = brandingSchema
  .pick({ status: true })
  .extend({ operation: z.literal(ApiPatchOperation.UpdateStatus).default(ApiPatchOperation.UpdateStatus) });

/**
 * Response schemas
 */
export const brandingTransformedResponseSchema = brandingSchema.extend({
  header_content_transformed: z.string(),
  footer_content_transformed: z.string(),
});

/**
 * Database schemas
 */
export const brandingDatabaseSchema = brandingSchema.merge(baseDatabaseSchema);

/**
 * Type definitions
 */
export type Branding = z.infer<typeof brandingSchema>;
export type BrandingCreate = z.infer<typeof brandingCreateSchema>;
export type BrandingUpdateName = z.infer<typeof brandingUpdateNameSchema>;
export type BrandingUpdateContent = z.infer<typeof brandingUpdateContentSchema>;
export type BrandingUpdateStatus = z.infer<typeof brandingUpdateStatusSchema>;
export type BrandingUpdate = BrandingUpdateName | BrandingUpdateContent | BrandingUpdateStatus;
export type BrandingTransformedResponse = z.infer<typeof brandingTransformedResponseSchema>;
export type BrandingTransformedDb = z.infer<typeof brandingTransformedResponseSchema> & PK & SK;
export type BrandingDb = z.infer<typeof brandingDatabaseSchema> & PK;
export type BrandingDbTransformed = z.infer<typeof brandingDatabaseSchema> &
  PK & { header_content_transformed: string; footer_content_transformed: string };
