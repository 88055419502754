import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authProvider } from '@helpers/auth';
import { useRemoveGlobalLoader } from '@hooks/useRemoveGlobalLoader';
import * as Sentry from '@sentry/browser';
import { useEffectOnce } from '@src/hooks';
import { isEmpty } from 'lodash-es';

import { useUserStore } from './store';

export const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { isLoggedIn, setUser } = useUserStore(({ isLoggedIn, setUser }) => ({
    isLoggedIn,
    setUser,
  }));

  const removeGlobalLoader = useRemoveGlobalLoader();

  useEffectOnce(() => {
    (async () => {
      try {
        const currentUserInfo = await authProvider.getUserInfo();
        const hasUserInfo = !isEmpty(currentUserInfo);

        setIsLoading(false);
        setUser(hasUserInfo ? currentUserInfo : null);

        if (hasUserInfo) {
          Sentry.setUser({
            id: currentUserInfo.username,
            email: currentUserInfo.email,
          });
        }
      } catch {
        setUser(null);
      } finally {
        setIsLoading(false);
      }
    })();
  });

  useEffect(() => {
    if (!isLoading) {
      removeGlobalLoader();
    }
  }, [isLoading, removeGlobalLoader]);

  if (isLoading) {
    return null;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to={`/login${location.search}`} state={{ from: location }} />;
};
